import React, {useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  TextField,
  Table,
  TableContainer,
  TableHead, TableRow, Paper, TableCell, TableBody
} from "@mui/material";
import ProductInfo from "./ProductInfo";
import {useState} from 'react';
import firebaseApp from '../../firebase/firebaseApp';
import {makeStyles} from '@mui/styles';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {getDateOrder} from '../orders/utilsOrders';
import {useSelector} from 'react-redux';
import ModalLoading from '../common/ModalLoading';

const useStyles = makeStyles((theme) => ({
  dialogRequerimiento: {
    '& .MuiPaper-root': {
      maxWidth: '900px'
    }
  },
  contentTitle: {
    display: 'flex',
    margin: '20px 0 18px',
    justifyContent: 'space-between'
  },
  colorRed: {
    color: 'red'
  }
}));

const functions = getFunctions(firebaseApp)

const ConfirmProduct = (props) => {
  const {email, name, companyName} = useSelector(state => state.user);
  const classes = useStyles();
  const [showConfirm, setShowConfirm] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState('');
  const [monthlyOrder, setMonthlyOrder] = useState([]);
  const {setShowCreateOrder, product} = props;
  
  useEffect(() => {
    const listMonthlyOrder = [];
    for (let i = 0; i < 12; i++) {
      const {nextDate, monthAndYear} = getDateOrder(i);
      listMonthlyOrder.push({'date': nextDate, 'quantity': '', 'state': 'enabled', 'label': monthAndYear})
    }
    setMonthlyOrder(listMonthlyOrder);
  }, [])
  
  useEffect(() => {
    const reduce = monthlyOrder.reduce((acc, current) => parseFloat(acc) + current?.quantity, 0);
    setTotalQuantity(reduce)
  }, [monthlyOrder])
  
  const changeMonthlyQuantity = (e, index) => {
    let {value} = e.target;
    if (value !== '') {
      value = parseFloat(value);
    }
    let listTemp = monthlyOrder;
    listTemp[index].quantity = value;
    setMonthlyOrder([...listTemp]);
  }

  const orderProductHandler = () => {
    setShowConfirm(true);
  }
  
  const confirmHandler = () => {
    if (totalQuantity > 0) {
      setProcessing(true);
      const createOrderFunction = httpsCallable(functions, 'createOrder');
      createOrderFunction({
        product: props.product,
        quantity: parseFloat(totalQuantity),
        monthlyOrder
      }).then(result => {
        console.log('se ingresó la orden');
        setProcessing(false);
        setShowConfirm(false);
        setShowCreateOrder(true);
       // sendMailingConfirm(props, totalQuantity);
        props.handleClose();
      });
    }
  }
  
  /** Mail de Solicitud de pedido */
  const sendMailingConfirm = (order, quantity) => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Su pedido fue registrado con éxito en nuestra plataforma.</p>
             <p>Procederemos a unificarlo con otros pedidos de la región y
             a continuación realizaremos las gestiones necesarias con nuestros
             proveedores en Asia para ofrecerle el precio más competitivo del mercado.</p>
             <p>Le recordamos que este proceso podría tomar de 4 a 8 semanas, sin embargo,
             lo mantendremos al tanto de los avances y le enviaremos un mensaje de
             confirmación una vez tengamos la mejor oferta disponible.</p>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p><strong>Detalle de la orden</strong></p>
             <ul>
              <li><strong>Tracking Code: </strong>${order.id}</li>
              <li><strong>Producto: </strong>${order.product.name}</li>
              <li><strong>Cantidad: </strong>${quantity} unidad(es)</li>
              <li><strong>Proveedor: </strong>${order.product.supplier.name}</li>
             </ul>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }
  
  const cancelHandler = () => {
    setShowConfirm(false);
  }
  
  return <Dialog open={props.open}>
    <DialogTitle>Información del Producto</DialogTitle>
    {props.product == null ?
      <p>Cargando...</p> :
      <>
        <DialogContent>
          <ProductInfo product={props.product}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancelar</Button>
          <Button onClick={orderProductHandler} variant='contained'>Ordenar</Button>
          <Dialog open={showConfirm} className={classes.dialogRequerimiento}>
            <DialogTitle>Requerimiento total</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Especifique los siguentes datos para confirmar su orden: <br/><br/>
              </DialogContentText>
              <div className={classes.contentTitle}>
                <DialogContentText>
                  <b>Cantidad Anual:</b> {totalQuantity} {product?.unit?.symbol}
                </DialogContentText>
              </div>
              <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{monthlyOrder[0]?.label}</TableCell>
                      <TableCell>{monthlyOrder[1]?.label}</TableCell>
                      <TableCell>{monthlyOrder[2]?.label}</TableCell>
                      <TableCell>{monthlyOrder[3]?.label}</TableCell>
                      <TableCell>{monthlyOrder[4]?.label}</TableCell>
                      <TableCell>{monthlyOrder[5]?.label}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 0)} value={monthlyOrder[0]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 1)} value={monthlyOrder[1]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 2)} value={monthlyOrder[2]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 3)} value={monthlyOrder[3]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 4)} value={monthlyOrder[4]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 5)} value={monthlyOrder[5]?.quantity}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper}>
                <Table sx={{minWidth: 650}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{monthlyOrder[6]?.label}</TableCell>
                      <TableCell>{monthlyOrder[7]?.label}</TableCell>
                      <TableCell>{monthlyOrder[8]?.label}</TableCell>
                      <TableCell>{monthlyOrder[9]?.label}</TableCell>
                      <TableCell>{monthlyOrder[10]?.label}</TableCell>
                      <TableCell>{monthlyOrder[11]?.label}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 6)} value={monthlyOrder[6]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 7)} value={monthlyOrder[7]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 8)} value={monthlyOrder[8]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 9)} value={monthlyOrder[9]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 10)} value={monthlyOrder[10]?.quantity}/>
                      </TableCell>
                      <TableCell>
                        <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder='0'
                                   onChange={(e) => changeMonthlyQuantity(e, 11)} value={monthlyOrder[11]?.quantity}/>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={cancelHandler}>Cancelar</Button>
              <Button onClick={confirmHandler} variant='contained'>Confirmar</Button>
            </DialogActions>
          </Dialog>
        </DialogActions>
        {processing &&
          <ModalLoading showModal={processing} closeModal={() => setProcessing(false)}/>
        }
      </>
    }
  </Dialog>
}

export default ConfirmProduct;
