import {Paper, Typography, Box} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#fff"
    },
    paperDark: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#eee"
    }    
}));

const GeneralInfo = ({product}) => {
    const {name, reference} = product
    const classes = useStyles();

    return <Box className={classes.paperDark}>
        <Typography variant="button">I. INFORMACIÓN GENERAL</Typography>
        <Paper className={classes.paper}>
            <Typography variant="subtitle2">Nombre:</Typography>
            <Typography variant="body2" gutterBottom>{name ?? "-"}</Typography>
            <Typography variant="subtitle2">Referencia:</Typography>
            <Typography variant="body2" gutterBottom>{reference ?? "-"}</Typography>
        </Paper>
    </Box>
}

export default GeneralInfo;