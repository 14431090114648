import {Card, CardContent, CardHeader, Grid} from "@mui/material";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles(theme => ({
    iframeContainer: {
        display:"flex",
        justifyContent: "center",
        marginBottom: theme.spacing(4)
    }
}));

const ListVideos = () => {
    const classes = useStyles();
    return <Card>
        <CardHeader title="Videos Corporativos" />
        <CardContent>
            <Grid container>
                <Grid item xs={12}>
                    <div className={classes.iframeContainer}>
                        <iframe title="video1" width="560" height="315" src="https://www.youtube.com/embed/T7Aj4eUw1rs?rel=0" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.iframeContainer}>
                        <iframe title="video2" width="560" height="315" src="https://www.youtube.com/embed/TwDO3v1ixQk?rel=0" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.iframeContainer}>
                        <iframe title="video3" width="560" height="315" src="https://www.youtube.com/embed/5CsAfhXbNS0?rel=0" frameBorder="0"
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen></iframe>
                    </div>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

export default ListVideos;
