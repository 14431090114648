import { Card, CardContent, CardHeader } from "@mui/material";
import {makeStyles} from '@mui/styles';
import { Fragment, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%"
    },
    card: {
        marginBottom: theme.spacing(4)
    },
    iframe: {
        border: 0
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    messageLoading: {
        position: "absolute",
        top: "50%",
        left: "50%",
        fontSize: "1.5rem",
        color: "#7e87ea"
    }
}));

const RevenueInfo = () => {
    const classes = useStyles();
    const [loaded1, setLoaded1] = useState(false);

    const onLoadIFrame1 = e => {
        setLoaded1(true);
    };

    return <Fragment>
        <Card className={classes.card}>
            <CardHeader title="Revenues by Industry" />
            <CardContent>
                {!loaded1 && (
                    <span className={classes.messageLoading}>Cargando...</span>
                )}
                <iframe title="Revenues by Industry" width="100%" height="900" onLoad={onLoadIFrame1} 
                    className={classes.iframe} src="https://datastudio.google.com/embed/reporting/2c4b81d5-dd31-4250-b9a6-7b63bfcbc61d/page/NvyiC" frameborder="0" allowfullscreen></iframe>
            </CardContent>
        </Card>
    </Fragment>
}

export default RevenueInfo;