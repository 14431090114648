import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton, LinearProgress,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import firebaseApp from '../../firebase/firebaseApp';
/*import {useSelector} from 'react-redux';*/
import pay from '../../img/pay_icon.svg'
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(() => ({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '60px',
    '& button': {
      marginBottom: '30px'
    }
  },
  icon: {
    height: '28px',
    marginRight: '12px'
  },
  button: {
    background: '#6200ea !important',
    padding: '16px 18px !important',
    borderRadius: '40px !important'
  }
}));

const functions = getFunctions(firebaseApp);

const ModalDocsBank = (props) => {
/*
  const {email, name, companyName} = useSelector(state => state.user);
*/
  const {className, variant, showDialog, setShowDialog, setCheckedValues,
    getPendingOrders, getSentOrders, idOrders, setIdOrders} = props;
  const classes = useStyles();
  const [showDialogThank, setShowDialogThank] = useState(false);
  const [emailsReferidos, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [processing, setProcessing] = useState(false);
  
  const handleAdd = () => {
    setEmails([...emailsReferidos, inputValue])
    setInputValue("");
  };
  
  const handleChange = (value) => {setInputValue(value);}
  
  const handleClose = () => {
    setShowDialog(false);
    setProcessing(false);
    setEmails([]);
    setIdOrders([]);
    setCheckedValues({});
  }
  
  useEffect(() => {
    if(showDialogThank){
      const timer = setTimeout(() => setShowDialogThank(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showDialogThank]);
  
  const handleSend = () => {
    setProcessing(true);
    const orderBody = {ids: idOrders};
    const updateOrderFunction = httpsCallable(functions, 'updatePendingOrder')
    updateOrderFunction(orderBody).then(result => {
      console.log('se actualizó la orden pendiente', result);
      setTimeout(() => {
        getPendingOrders();
        getSentOrders();
        handleClose();
    //    sendMailing();
      }, 2000);
    });
  }
  
/*  const sendMailing = () => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: emailsReferidos,
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Adjuntamos los documentos de compra de nuestro cliente.</p>
             <p>Somos ALAXS, el marketplace B2B de productos industriales entre nuestra región y Asia.</p>
             <p>Facilitamos la información de compra de nuestro cliente y los invitamos a revisar la misma de cara al proseguir con las gestiones ante nuestro proveedor en el Asia.</p>
             <p>Quedamos listos a responder sus preguntas y que nuestro cliente siga con su proceso de trading.</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    }).then(() => {
      sendMailingTracking();
    });
  }*/
  
/*  const sendMailingTracking = () => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Se han enviado los documentos de la orden al Banco.</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }*/
  
  return <div className={clsx(className, {[classes.fixed]: variant === 'fixed'})}>
    <Button variant="outlined" color="success" onClick={() => setShowDialog(true)} disabled={idOrders?.length === 0}>
      <img alt="shared" className={classes.icon} src={pay}/>
      <Typography sx={{fontSize: '14px'}}><strong> Docs Banco</strong></Typography>
    </Button>
    <Dialog open={showDialog}>
      <DialogTitle sx={{padding: '40px 30px'}}>
        {!processing ? 'Envía tus documentos de compra al banco': 'Enviando tus documentos al banco'}
        <IconButton
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{padding: '40px 30px 0'}}>
      {!processing ?
        <>
            <DialogContentText id="alert-dialog-description">
              Agrega los correos a los que deseas enviar
            </DialogContentText>
            <Paper
              elevation={0}
              component="form"
              sx={{p: '18px 4px', display: 'flex', alignItems: 'center', width: 400}}>
              <TextField
                fullWidth
                autoFocus
                id="name"
                label="Correo electrónico"
                type="email"
                size="small"
                value={inputValue}
                onChange={(e) => handleChange(e.target.value)}
              />
              <Button variant="outlined" onClick={() => handleAdd()}>Agregar</Button>
            </Paper>
            {emailsReferidos?.map((email, i) => (
              <FormGroup key={`fg-${i}`}>
                <FormControlLabel control={<Checkbox defaultChecked/>} label={email}/>
              </FormGroup>
            ))}
            {processing && <LinearProgress/>}
  
          <DialogActions sx={{padding: '20px 30px 40px'}}>
            <Button variant='contained' onClick={() => handleSend()} autoFocus>Enviar</Button>
          </DialogActions>
        </>
        : 
        <><LinearProgress/><br/><br/></>
      }
     
     </DialogContent>
    </Dialog>
    <Dialog open={showDialogThank}>
      <DialogTitle><br/>
        <IconButton>
          <CheckCircleOutlineOutlinedIcon color={'primary'}/>
        </IconButton>
        <IconButton
          onClick={() => setShowDialogThank(false)}
          sx={{position: 'absolute', right: 4, top: 8, color: (theme) => theme.palette.grey[500],}}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          El envío de correo fue exitoso.<br/>
        </DialogContentText><br/>
      </DialogContent>
    </Dialog>
  </div>
}

ModalDocsBank.defaultProps = {
  variant: '',
  className: ''
}

export default ModalDocsBank;
