import {Button, Menu, MenuItem, CircularProgress} from '@mui/material';
import Fade from '@mui/material/Fade';
import {Link} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getListPostThematic} from '../../store/post-actions';

const menuList = {
  thematic: false,
  locacion: false,
  date: false
}

const MenuThinktank = () => {
  const [isOpen, setIsOpen] = useState(menuList);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const listThematics = useSelector(state => state.post.listThematics)
  
  useEffect(() => {
    handleClose();
    dispatch(getListPostThematic());
  }, [dispatch])
  
  useEffect(() => {
    if(listThematics.length > 0){
      setIsLoading(false);
    }
  },[listThematics])
  
  const handleClick = (event, menu) => {
    const menuTemp = isOpen;
    menuTemp[menu] = event.currentTarget;
    setIsOpen({...menuTemp});
  };
  const handleClose = () => {
    setIsOpen({...menuList});
  };
  
  return(
    <div className="menu">
        <div>
          <Button onClick={(e) => handleClick(e, 'thematic')}>Temática {' '}
            {isLoading && <CircularProgress style={{width: '15px', height: '15px'}}/>}
          </Button>
          <Menu
            id="fade-menu"
            MenuListProps={{'aria-labelledby': 'fade-button',}}
            anchorEl={isOpen['thematic']}
            open={isOpen['thematic']}
            onClick={() => handleClose()}
            TransitionComponent={Fade}
          >
            {isLoading && <MenuItem>Cargando...</MenuItem>}
            {listThematics?.map(e => (
              <MenuItem component={Link} to={`/posts/tematica/${e.order}`}>{e.name}</MenuItem>
              ))
            }
          </Menu>
        </div>
        <div>
          <Button onClick={(e) => handleClick(e, 'locacion')}>Locación</Button>
          <Menu
            id="fade-menu"
            MenuListProps={{'aria-labelledby': 'fade-button',}}
            anchorEl={isOpen['locacion']}
            open={isOpen['locacion']}
            onClick={() => handleClose()}
            TransitionComponent={Fade}
          >
            <MenuItem component={Link} to={'/posts/locacion/1'}>Asia </MenuItem>
            <MenuItem component={Link} to={'/posts/locacion/2'}>África </MenuItem>
            <MenuItem component={Link} to={'/posts/locacion/3'}>LatAm</MenuItem>
            <MenuItem component={Link} to={'/posts/locacion/4'}>Europe </MenuItem>
          </Menu>
        </div>
        <div>
          <Button onClick={(e) => handleClick(e, 'date')}>Año</Button>
          <Menu
            id="fade-menu"
            MenuListProps={{'aria-labelledby': 'fade-button',}}
            anchorEl={isOpen['date']}
            open={isOpen['date']}
            onClick={() => handleClose()}
            TransitionComponent={Fade}
          >
            <MenuItem component={Link} to={'/posts/date/2022'}>2022 </MenuItem>
            <MenuItem component={Link} to={'/posts/date/2023'}>2023 </MenuItem>
          </Menu>
        </div>
      </div>
  )
}

export default MenuThinktank;
