import {
  TextField,
  FormControl,
  MenuItem,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Typography,
  Breadcrumbs, Paper, Button
} from "@mui/material";
import {Link} from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import SearchCatalog from '../components/common/SearchCatalog';

const useStyles = makeStyles((theme) => ({
  boxCheckbox:{
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px'
  },
  boxRow:{
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mgTb:{
    marginTop: theme.spacing(2),
  }
}));

const Target = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const [cantidad, setCantidad] = useState('Unidad');
  const [date, setDate] = useState('');
  
  
  const handleChangeAccordion = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const handleSelect = (e) => {
    setCantidad(e.target.value);
  };
  
  return <div style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Typography>trading </Typography>
      <Typography>requerimiento </Typography>
      <Link to="/buying">Nuevo</Link>
      <Typography>target</Typography>
    </Breadcrumbs><br/>
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <Typography>Aquí podrás buscar, validar y conseguir productos específicos no disponibles en nuestra sección <span style={{whiteSpace: 'nowrap'}}>“Compras por Catalogo”.</span> <br/>Estos productos pueden tener una o varias de estas características:</Typography>
            <Grid container>
              <Grid item xs={6}>
                <ul>
                  <li>No estandarizados</li>
                  <li>Suelen ser de gran valor</li>
                  <li>El volumen de pedido es reducido (pocas unidades)</li>
                </ul>
              </Grid>
              <Grid item xs={6}>
                <ul>
                  <li>Requieren detallar sus especificaciones técnicas</li>
                  <li>Se compran de manera puntual (no es una compra recurrente)</li>
                  <li>Son claves para la operatividad</li>
                </ul>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Mi información</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchCatalog/>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box className={classes.boxRow}>
                <TextField label="¿Cuántos?"/>
                <TextField  fullWidth
                            id="outlined-select-currency"
                            select
                            label="Medidas"
                            placeholder="Seleccione"
                            value={cantidad}
                            onChange={handleSelect}
                >
                  <MenuItem value={'Unidades'}>Unidades</MenuItem>
                  <MenuItem value={'Kilogramos'}>Kilogramos</MenuItem>
                  <MenuItem value={'Litros'}>Litros</MenuItem>
                  <MenuItem value={'Toneladas'}>Toneladas</MenuItem>
                </TextField>
              </Box>
              <FormControl fullWidth>
                <Typography>Adjuntar</Typography>
                <TextField type='file'/>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box fullWidth className={classes.boxRow}>
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    autoFocus
                    id="fecha"
                    label="¿Para cuándo?"
                    name="date"
                    type="date"
                    min={new Date()}
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </FormControl>
              </Box>
              <Box fullWidth className={classes.boxRow}>
                <Button color='primary' variant='contained'>Enviar</Button>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  </div>
}

export default Target;
