import {Fragment} from "react";
import {makeStyles} from "@mui/styles";
import {Button, Card, CardActions, CardContent, CardMedia, Grid, Typography} from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

const Menu = () => {
    const classes = useStyles();
    return <Fragment>
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardMedia className={classes.media} image="https://storage.googleapis.com/alaxs-0003.appspot.com/industria_small.jpg" title="Industrias"/>
                    <CardContent><Typography variant="h6">Industrias</Typography></CardContent>
                    <CardActions>
                        <Button startIcon={<ArrowRightAltIcon/>} variant="outlined" color="primary" component={Link} to="/catalog/industries">Ingresar</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardMedia className={classes.media} image="https://storage.googleapis.com/alaxs-0003.appspot.com/almacen_small.jpg" title="Productos"/>
                    <CardContent><Typography variant="h6">Partidas Arancelarias</Typography></CardContent>
                    <CardActions>
                        <Button startIcon={<ArrowRightAltIcon/>} variant="outlined" color="primary" component={Link} to="/catalog/customs">Ingresar</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardMedia className={classes.media} image="https://storage.googleapis.com/alaxs-0003.appspot.com/books_small.jpg" title="Oficiales"/>
                    <CardContent><Typography variant="h6">Oficiales</Typography></CardContent>
                    <CardActions>
                        <Button startIcon={<ArrowRightAltIcon/>} variant="outlined" color="primary" component={Link} to="/catalog/naics">Ingresar</Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>
                <Card>
                    <CardMedia className={classes.media} image="https://storage.googleapis.com/alaxs-0003.appspot.com/productos.png" title="Oficiales"/>
                    <CardContent><Typography variant="h6">Productos</Typography></CardContent>
                    <CardActions>
                        <Button startIcon={<ArrowRightAltIcon/>} variant="outlined" color="primary" component={Link} to="/catalog/products">Ingresar</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </Fragment>
}

export default Menu;
