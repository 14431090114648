import {Breadcrumbs, Container, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import {Link} from 'react-router-dom';
import {Box, IconButton, Stack} from '@mui/material';
import shoppingBar from '../img/shopping-bag.svg';
import ventaIcon from '../img/venta-icon.svg';
import remateIcon from '../img/remate-icon.svg';
import donacionIcon from '../img/donacion-icon.svg';
import Share from '../components/common/Share';

const useStyles = makeStyles((theme) => ({
  main: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    display: 'flex !important',
    flexFlow: 'column nowrap',
    minHeight: 'calc(100vh - 136px)'
  },
  buttonIcon: {
    borderRadius: '50%',
    backgroundColor: '#ff3036 !important',
    width: '86px',
    height: '86px',
    padding: 0,
    color: 'white !important',
    marginBottom: '14px !important',
    '& img': {
      height: '50px'
    }
  }
}));

const Marketplace = () => {
  const classes = useStyles();
  return <div style={{marginTop: '-24px'}} className={classes.main}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Typography>LATINOAMERICA </Typography>
      <Link to="/assets">Assets</Link>
      <Typography>Marketplace</Typography>
      </Breadcrumbs>
    <Container fixed className={classes.container}>
      <Grid container spacing={2} sx={{marginBottom: 'auto'}}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <Typography>Esta sección es para comprar o vender 'algo' puntual dentro del mercado latinoamericano.</Typography>
            <br/>
            <Typography>Condiciones ALAXS:</Typography>
            <ul>
              <li>Algo que nuestros clientes tengan, puedan tener o necesiten.</li>
              <li>Probablemente sea de 2do uso o almacenada desde hace un tiempo.</li>
              <li>La comisión de ALAXS va desde $9 hasta un maximo del 1% de la operación.</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Stack direction="row" spacing={8}>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/marketplace/purchase">
                  <img src={shoppingBar} alt='icon'/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Compra</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/marketplace/sale">
                  <img src={ventaIcon} alt='icon'/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Venta</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/marketplace/auction">
                  <img src={remateIcon} alt='icon'/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Remate</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/marketplace/donation">
                  <img src={donacionIcon} alt='icon'/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Donación</Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Share variant="fixed"/>
    </Container>
  </div>
}

export default Marketplace;
