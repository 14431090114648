import {makeStyles} from "@mui/styles";
import {Breadcrumbs, Typography} from "@mui/material";
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    subheader: {
        minHeight: '40px',
        backgroundColor: '#7e87ea',
        marginTop: '-25px',
        marginBottom: '25px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        textTransform: 'uppercase',
        width: '100%',
        fontSize: '0.875rem'
    },
    link: {
        color: "white",
        fontSize: "0.875rem"
    },
    title: {
        fontSize: "0.875rem !important"
    }

}));

const SubHeader = (props) => {
    const classes = useStyles();

    if (props.parentUrl && props.parentTitle)
    {
        return <div className={classes.subheader}><Breadcrumbs arial-label="breadcrumb">
            <Link className={classes.link} to={props.parentUrl}>{props.parentTitle}</Link>
            <Typography className={classes.title}>{props.title}</Typography>
        </Breadcrumbs></div>
    }
    else
    {
        return <div className={classes.subheader}>{props.title}</div>;
    }

}

export default SubHeader;
