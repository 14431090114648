import {
    Dialog,
    DialogActions,
    DialogTitle,
    TextField,
    DialogContent, DialogContentText, Button, LinearProgress
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import DatePicker from '@mui/lab/DatePicker';
import {useState, Fragment} from 'react';
import CityInput from "../common/CityInput";
import {getDate} from "../../util/util";
import firebaseApp from "../../firebase/firebaseApp";
import Confirm from "../common/Confirm";
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    progress: {
        marginTop: theme.spacing(2)
    }
}));

const functions = getFunctions(firebaseApp)

const EditEvent = (props) => {
    const classes = useStyles();
    const [eventName, setEventName] = useState(props.eventInfo.name);
    const [url, setUrl] = useState(props.eventInfo.url);
    const [sponsor, setSponsor] = useState(props.eventInfo.sponsor);
    const [subject, setSubject] = useState(props.eventInfo.subject);
    const [city, setCity] = useState(props.eventInfo.city);
    const [dateStart, setDateStart] = useState(getDate(props.eventInfo.dateStart));
    const [dateEnd, setDateEnd] = useState(getDate(props.eventInfo.dateEnd));
    const [inProgress, setInProgress] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [errorCity, setErrorCity] = useState(false);

    const saveChangesHandler = async (e) => {
        e.preventDefault();
        if (city === null)
        {
            setErrorCity(true);
            return;
        }
        setInProgress(true);
        setDisabled(true);
        const eventInfo = {
            id: props.eventInfo.id,
            name: eventName,
            url: url,
            sponsor: sponsor,
            subject: subject,
            city: {...city, country: {...city.country}},
            dateStart: {_seconds: Math.floor(new Date(dateStart).getTime() / 1000)},
            dateEnd: {_seconds: Math.floor(new Date(dateEnd).getTime() / 1000)}
        }

        const updateEvent = httpsCallable(functions, 'updateEvent');
        await updateEvent(eventInfo);
        setInProgress(false);
        setDisabled(false);
        props.onClose(eventInfo);
    }

    const closeHandler = (e) => {
        e.preventDefault();
        props.onClose(undefined);
    }

    const setCityHandler = (city) => {
        setCity(city);
    }

    const handleDateStart = (value) => {
        setDateStart(value);
    }

    const handleDateEnd = (value) => {
        setDateEnd(value);
    }

    const changeNameHandler = (e) => {
        setEventName(e.target.value);
    }

    const changeUrlHandler = (e) => {
        setUrl(e.target.value);
    }

    const changeSponsorHandler = (e) => {
        setSponsor(e.target.value);
    }

    const changeSubjectHandler = (e) => {
        setSubject(e.target.value);
    }

    const deleteEvent = async () => {
        setShowConfirm(false);
        setInProgress(true);
        setDisabled(true);

        const eventInfo = {id: props.eventInfo.id};

        const deleteEvent = await httpsCallable(functions, 'deleteEvent');
        await deleteEvent(eventInfo);

        setInProgress(false);
        setDisabled(false);
        props.onDelete(eventInfo);
    }

    const deleteEventHandler = async () => {
        setShowConfirm(true);
    }

    const cancelConfirmHandler = () => {
        setShowConfirm(false);
    }

    return <Fragment><Dialog open={props.open}>
        <form onSubmit={saveChangesHandler} id='frmEvent'>
            <DialogTitle>Datos del Evento</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Por favor realice los cambios que crea conveniente al evento.
                </DialogContentText>
                <TextField label="Nombre" fullWidth className={classes.textField} required value={eventName} onChange={changeNameHandler} disabled={disabled} />
                <TextField label="Url" fullWidth className={classes.textField} required type="url" value={url} onChange={changeUrlHandler} disabled={disabled}/>
                <TextField label="Promotor" fullWidth className={classes.textField} required value={sponsor} onChange={changeSponsorHandler} disabled={disabled}/>
                <TextField label="Tema" fullWidth className={classes.textField} required value={subject} onChange={changeSubjectHandler} disabled={disabled}/>
                <CityInput onSetCity={setCityHandler} city={city} disabled={disabled} errorCity={errorCity}/>

                <DatePicker label="Desde" value={dateStart} onChange={handleDateStart} disabled={disabled} required id="dateStart" 
                renderInput={(params) => <TextField fullWidth {...params}/> }/>

                <DatePicker label="Hasta" value={dateEnd} onChange={handleDateEnd} disabled={disabled} required id="dateEnd" 
                renderInput={(params) => <TextField fullWidth {...params}/> }/>

                
                {inProgress && <LinearProgress className={classes.progress} />}
            </DialogContent>
            <DialogActions>
                <Button type="submit" color="primary" variant="contained" disabled={disabled}>Guardar Cambios</Button>
                <Button onClick={deleteEventHandler} color="secondary" variant="contained" disabled={disabled}>Eliminar</Button>
                <Button onClick={closeHandler} color="secondary" variant="outlined" disabled={disabled}>Cancelar</Button>
            </DialogActions>
        </form>
    </Dialog>
        {showConfirm && <Confirm open={showConfirm} onConfirm={deleteEvent} onCancel={cancelConfirmHandler} title='Advertencia'
                                 message={`¿Está seguro de eliminar el evento ${eventName}?`}
                                 confirmButtonText='Si' cancelButtonText='No'/>}
    </Fragment>
}

export default EditEvent;
