import {makeStyles} from "@mui/styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Breadcrumbs,
    Container, Grid,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import {ExpandMore} from "@mui/icons-material";
import {useEffect, useState} from "react";
import OpenedOrders from '../components/orders/OpenedOrders';
import NegotiationOrders from "../components/orders/NegotiationOrders";
import Share from '../components/common/Share';
import PaymentPending from '../components/orders/PaymentPending';
import PaymentSent from '../components/orders/PaymentSent';
import PaymentPaidOrders from '../components/orders/PaymentPaid';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {getQuantity} from '../components/orders/utilsOrders';

const functions = getFunctions(firebaseApp)

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    }
}));

const Trading = () => {
    const classes = useStyles();
    const [openedOrders, setOpenedOrders] = useState(undefined);
    const [pendingOrders, setPendingOrders] = useState(undefined);
    const [sentOrders, setSentOrders] = useState(undefined);
    const [paidOrders, setPaidOrders] = useState(undefined);
    const [isLoadingOrders, setIsLoadingOrders] = useState(true);
    const [isLoadingPendingOrders, setIsLoadingPendingOrders] = useState(true);
    const [isLoadingSentOrders, setIsLoadingSentOrders] = useState(true);
    const [isLoadingPaidOrders, setIsLoadingPaidOrders] = useState(true);
    const [quantity, setQuantity] = useState([]);
    const [idOrders, setIdOrders] = useState([]);
    const [checkedValues, setCheckedValues] = useState({});
    
    useEffect(() => {
        const OrdersSelected = Object.filter(checkedValues, checked => checked === true);
        setIdOrders(Object.keys(OrdersSelected));
    }, [checkedValues]);
    
    useEffect(() => {
        if(openedOrders?.length > 0){
            let dataQuantity = [];
            openedOrders?.forEach(order => {
                const {currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, proxQuarterQuantity, totalQuantity, newMonthyOrden, isQuarterDisabled} = getQuantity(order.monthlyOrder);
                dataQuantity.push({currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, proxQuarterQuantity, totalQuantity, newMonthyOrden, isQuarterDisabled})
            })
            setQuantity(dataQuantity)
        }
    }, [openedOrders])
    
    useEffect(() => {
        getOpenedOrders();
    }, []);
    
    const getOpenedOrders = async () => {
        setOpenedOrders(undefined);
        setIsLoadingOrders(true);
        const listOpenedOrders = httpsCallable(functions,'listOpenedOrders');
        listOpenedOrders().then(result=> {
            setOpenedOrders(result.data);
            setIsLoadingOrders(false);
        })
    }
    
    const getPendingOrders = async () =>{
        setPendingOrders(undefined);
        setIsLoadingPendingOrders(true);
        setIdOrders([]);
        setCheckedValues({});
        const listPendingOrders = httpsCallable(functions, 'listPendingOrders');
        listPendingOrders().then(result=> {
            setIsLoadingPendingOrders(false);
            setPendingOrders(result.data);
        })
    }
    
    const getSentOrders = async () =>{
        setSentOrders(undefined);
        setIsLoadingSentOrders(true);
        const listSentOrders = httpsCallable(functions, 'listSentOrders');
        listSentOrders().then(result=> {
            setSentOrders(result.data);
            setIsLoadingSentOrders(false);
        })
    }
    
    const getPaidOrders = async () =>{
        setPaidOrders(undefined);
        setIsLoadingPaidOrders(true);
        const listPaidOrders = httpsCallable(functions, 'listPaidOrders');
        listPaidOrders().then(result=> {
            setPaidOrders(result.data);
            setIsLoadingPaidOrders(false);
        })
    }

    const handleChange = (panel) => (event, newExpanded) => {
        if (panel === "products" && newExpanded)
        {
            if (openedOrders === undefined)
            {
                getOpenedOrders();
            }
        }
        else if (panel === "negotiation" && newExpanded)
        {
            if (openedOrders === undefined)
            {
                getOpenedOrders();
            }
        }
        /*Section 3*/
        else if (panel === "payment" && newExpanded)
        {
            if (pendingOrders === undefined)
            {
                getPendingOrders();
                getSentOrders();
                getPaidOrders();
            }
        }
    }

    return <div style={{marginTop: '-24px'}}>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
                <Typography>Trading </Typography>
                <Typography>Trading Platform</Typography>
            </Breadcrumbs>
            <Container fixed>
                <Grid>
                    <div>
                        <Accordion defaultExpanded={true} onChange={handleChange('products')}>
                            <AccordionSummary expandIcon={<ExpandMore/>} id="products">
                                <Typography className={classes.heading}>1. PRODUCTS</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <OpenedOrders isLoading={isLoadingOrders} orders={openedOrders} quantity={quantity} getOpenedOrders={getOpenedOrders}/>
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion onChange={handleChange('negotiation')}>
                            <AccordionSummary expandIcon={<ExpandMore/>} id="negotiation">
                                <Typography className={classes.heading}>2. NEGOTIATION</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <NegotiationOrders
                                          orders={openedOrders}
                                          quantity={quantity}
                                          setIsLoadingOrders={setIsLoadingOrders}
                                          isLoading={isLoadingOrders}
                                          getOpenedOrders={getOpenedOrders}
                                          getPendingOrders={getPendingOrders}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion onChange={handleChange('payment')}>
                            <AccordionSummary expandIcon={<ExpandMore/>} id="payment">
                                <Typography className={classes.heading}>3. PAYMENT</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <PaymentPending
                                          isLoading={isLoadingPendingOrders}
                                          orders={pendingOrders}
                                          getPendingOrders={getPendingOrders}
                                          getSentOrders={getSentOrders}
                                          setIsLoadingPendingOrders={setIsLoadingPendingOrders}
                                          idOrders={idOrders}
                                          setIdOrders={setIdOrders}
                                          checkedValues={checkedValues}
                                          setCheckedValues={setCheckedValues}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaymentSent
                                          isLoading={isLoadingSentOrders}
                                          orders={sentOrders}
                                          getSentOrders={getSentOrders}
                                          getPaidOrders={getPaidOrders}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PaymentPaidOrders
                                          isLoading={isLoadingPaidOrders}
                                          orders={paidOrders}
                                        />
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion onChange={handleChange('delivery')}>
                            <AccordionSummary expandIcon={<ExpandMore/>} id="delivery">
                                <Typography className={classes.heading}>4. DELIVERY</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Aquí irá la información relacionada a la entrega de las órdenes.</Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </Grid>
                <Share variant="fixed"/>
            </Container>
        </div>
}

export default Trading;
