import {Box} from "@mui/material";
import { Typography, Table, TableRow, TableCell } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const useStyles = makeStyles((theme) => ({
    paperDark: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#eee"
    }  
}));

const ListDocs = ({productDetail}) => {
    const {docs} = productDetail

    const classes = useStyles();

    return <Box className={classes.paperDark}>
    <Typography variant="button">II. DOCUMENTOS / ESPECIFICACIONES</Typography>
    <Table>
        {docs.length > 0 ? docs.map((item, docIndex) => {
            return  <a rel="noreferrer" target="_blank" href={`${STORAGE_BASE_URL}${BUCKET_NAME}/${item}`} download>
                <TableRow key={"docs"+docIndex}>
                    <TableCell>
                        <CloudDownloadIcon/>
                    </TableCell>
                    <TableCell>
                       {item}
                    </TableCell>
                </TableRow>
            </a>
        }) : <Typography variant="subtitle2">No hay documentos adjuntos</Typography>
        }
    </Table>
    </Box>
}

export default ListDocs;