import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { Link } from '@mui/material';
import {useState, Fragment} from 'react';
import ModalDetailOrder from './ModalDetailOrder';
import ModalModifyOrder from './ModalModifyOrder';

const OpenedOrders = (props) => {
    const {quantity, getOpenedOrders} = props;
    const [showOrder, setShowOrder] = useState(false);
    const [showModifyOrder, setShowModifyOrder] = useState(false);
    const [currentOrder, setCurrentOrder] = useState(undefined);
    const [currentMonthlyOrder, setCurrentMonthlyOrder] = useState(undefined);
    
    const showOrderDetail = (order) => {
        setCurrentOrder(order);
        setShowOrder(true);
    }
    
    const handleModifyOrder = (order) => {
        setCurrentMonthlyOrder(order);
        setShowModifyOrder(true);
    }
    
    const closeDetailOrder = () => {
        setCurrentMonthlyOrder(undefined);
        setShowOrder(false)
    }
    
    const closeModifyOrder = () => {
        setCurrentMonthlyOrder(undefined);
        setShowModifyOrder(false);
    }

    return <Fragment>
        {currentOrder && 
          <ModalDetailOrder
            showOrder={showOrder}
            currentOrder={currentOrder}
            closeModal = {closeDetailOrder}
          />
        }
        {currentMonthlyOrder &&
          <ModalModifyOrder
            showOrder={showModifyOrder}
            setShowOrder={setShowModifyOrder}
            currentOrder={currentMonthlyOrder}
            closeModal = {closeModifyOrder}
            getOpenedOrders={getOpenedOrders}
          />
        }
        <Card>
        <CardContent>
            <Typography variant="h6">Mis Órdenes Abiertas</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Producto</TableCell>
                            <TableCell>Cantidad</TableCell>
                            <TableCell>Tracking Code</TableCell>
                            <TableCell>Fecha y Hora</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.isLoading && (props.orders?.length > 0 ? props.orders?.map((order, i) =>
                          quantity[i].totalQuantity !== 0 && 
                            <TableRow key={order.id}>
                                <TableCell>{order.product.name}</TableCell>
                                <TableCell><Link href="#" onClick={() => handleModifyOrder(order)}>{quantity[i].totalQuantity} TM</Link></TableCell>
                                <TableCell><Link href="#" onClick={showOrderDetail.bind(this, order)}>{order.id}</Link></TableCell>
                                <TableCell>{new Date(order.creationDate._seconds * 1000).toLocaleDateString() + " " + new Date(order.creationDate._seconds * 1000).toLocaleTimeString()}</TableCell>
                            </TableRow>):
                          <TableRow><TableCell>Sin operaciones</TableCell></TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.isLoading && <LinearProgress/>}
        </CardContent>
    </Card>
    </Fragment>
}

export default OpenedOrders;
