import {
  Button,
  Paper,
  TextField,
  InputAdornment,
  Container,
  Grid,
  Box,
  Typography,
  Stack,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions, Dialog
} from "@mui/material";

import { makeStyles } from '@mui/styles';
import {Fragment, useState} from 'react';
import {useSelector} from 'react-redux';
import SubHeader from '../components/common/SubHeader';
import Share from '../components/common/Share';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  boxCheckbox:{
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px'
  },
  boxRow:{
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  center:{
    display: 'flex',
    justifyContent: 'center'
  }
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {borderBottom: 0,},
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary{...props}/>
))(({ theme }) => ({
  backgroundColor:'#e3f2fd',
  color:"#0d47a1"
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const LoyaltyClub = () => {
  const classes = useStyles();
  const {companyName, name} = useSelector(state => state.user);
  const {city, country} = useSelector(state => state.company);
  const [showDialogPerfil, setShowDialogPerfil] = useState(false);
  const [inputValue, setInputValue] = useState("");
  
  const handleChange = (value) => {
    setInputValue(value);
  }
  
  return <Fragment>
    <SubHeader title='Loyalty club'/>
    <Container sx={{maxWidth: '90% !important'}}>
      <Dialog open={showDialogPerfil}>
        <DialogTitle>
          Completa la siguiente información:
          <IconButton
            onClick={() => setShowDialogPerfil(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Paper
            elevation={0}
            component="form"
            sx={{p: '18px 4px', display: 'flex', alignItems: 'center', width: 400}}>
            <TextField
              fullWidth
              autoFocus
              id="name"
              label="Fecha de cumpleaños"
              placeholder="dia/mes/año"
              size="small"
              value={inputValue}
              onChange={(e) => handleChange(e.target.value)}
            />
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' onClick={() => setShowDialogPerfil(false)} autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Box sx={{display: 'flex', justifyContent: 'center', textAlign: 'center', padding: '30px 0 40px'}}>
          <Stack direction="row" spacing={8} sx={{width: '100%', justifyContent: 'center'}}>
            <Button variant="outlined" color="primary" sx={{width: '130px'}} component={Link} to="/principles">
              Principios
            </Button>
            <Button variant="outlined" color="primary" sx={{width: '130px'}} component={Link} to="/valores">
              Valores
            </Button>
            <Button variant="outlined" color="primary" sx={{width: '130px'}} component={Link} to="/loyalty-club/conduct">
              Código de conducta
            </Button>
            <Button variant="outlined" color="primary" sx={{width: '130px'}}
                    onClick={() => setShowDialogPerfil(true)}>
              Mi Perfil
            </Button>
            <Button variant="outlined" color="primary" sx={{width: '130px'}} component={Link} to="/loyalty-club/faq">
              FAQ
            </Button>
          </Stack>
        </Box>
      </Grid>
      <Paper elevation={1} sx={{padding: '34px 20px 14px', marginBottom: '40px'}}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography><strong>Usuario: </strong>{name}</Typography>
            <p><strong>Empresa: </strong>{companyName}</p>
            <p><strong>Locación: </strong>{city} - {country}</p>
            <p><strong>Celular: </strong>-</p>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label={"Puntos ALAXS"}
              defaultValue=""
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} sx={{bgcolor: '#ffd54f', textAlign: 'center', width: '150px', margin: 'auto', padding: '20px', borderRadius: '12px'}}>
              <Typography variant="h5"><strong>MI NIVEL</strong></Typography><br/>
              <Typography variant="h6">GOLD</Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Grid container spacing={4}>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} sx={{paddingTop: '10px'}}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography>Mis ingresos a myalaxs</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Número de ingresos</Typography>
                <Typography variant="caption" style={{marginTop: '16px', display: 'block'}}>Veces / minutos (último año)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""/>
                </Box>
                <Typography variant="caption" style={{marginTop: '20px', display: 'block'}}>Veces / minutos (último trimestre)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} sx={{paddingTop: '10px'}}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography>Mi trading</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Volumen de trading</Typography>
                <Typography variant="caption" style={{marginTop: '16px', display: 'block'}}>Negocios / USD (último año)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""
                             InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
                </Box>
                <Typography variant="caption" style={{marginTop: '20px', display: 'block'}}>Negocios / USD (último trimestre)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""
                             InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} sx={{paddingTop: '10px'}}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Typography>Mis servicios</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Volumen de servicios</Typography>
                <Typography variant="caption" style={{marginTop: '16px', display: 'block'}}>Servicios / USD (último año)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""
                             InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
                </Box>
                <Typography variant="caption" style={{marginTop: '20px', display: 'block'}}>Servicios / USD (último trimestre)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""
                             InputProps={{startAdornment: <InputAdornment position="start">$</InputAdornment>}}/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} sx={{paddingTop: '10px'}}>
            <Accordion expanded={true}>
              <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                <Typography>Mis referidos</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>Contacto profesionales</Typography>
                <Typography variant="caption" style={{marginTop: '16px', display: 'block'}}>Cam. asoc. grupos/ # referidos(últ. año)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""/>
                </Box>
                <Typography variant="caption" style={{marginTop: '20px', display: 'block'}}>Cam.asoc.grupos / # referidos (últ. trimestre)</Typography>
                <Box sx={{display: 'flex'}}>
                  <TextField defaultValue=""/>
                  <TextField defaultValue=""/>
                </Box>
                <Box sx={{marginTop: '24px'}}>
                  <Share className={classes.center}/>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Paper>
        </Grid>
      </Grid>
      </Container>
  </Fragment>
}

export default LoyaltyClub;
