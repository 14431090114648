import {useState, useRef} from 'react';
import {Paper, TextField, Typography, IconButton, Button} from "@mui/material";
import {makeStyles} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import CityInput from "../common/CityInput";

import DatePicker from '@mui/lab/DatePicker';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        backgroundColor: 'rgba(17,32,145,0.09)'
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    },
    typography: {
        color: '#112091',
        flexGrow: '1',
        fontWeight: '700'
    },
    paperTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: 'center'
    },
    button: {
        marginTop: theme.spacing(2)
    }
}));


const FormEvent = (props) => {
    const classes = useStyles();
    const [selectedCity, setSelectedCity] = useState(null);
    const [dateStart, setDateStart] = useState(new Date());
    const [dateEnd, setDateEnd] = useState(new Date());
    const nameRef = useRef('');
    const sponsorRef = useRef('');
    const subjectRef = useRef('');
    const urlRef = useRef('');
    const [disabledButton, setDisabledButton] = useState(false);
    const [errorCity, setErrorCity] = useState(false);

    const handleDateStart = (date) => {
        setDateStart(date);
    };

    const handleDateEnd = date => {
        setDateEnd(date);
    }

    const closeCreateEventHandler = () => {
        props.onCloseEvent(undefined);
    }

    const setCityHandler = (city) => {
        setSelectedCity(city);
    }

    const newEventHandler = async (e) => {
        e.preventDefault();
        if (selectedCity === null)
        {
            setErrorCity(true);
            return;
        }
        const newEvent = {
            name: nameRef.current.value,
            sponsor: sponsorRef.current.value,
            subject: subjectRef.current.value,
            city: {...selectedCity, country: {...selectedCity.country}},
            url: urlRef.current.value,
            dateStart: {_seconds: dateStart.setHours(0, 0, 0, 0) / 1000},
            dateEnd: {_seconds: dateEnd.setHours(23, 59, 59, 999) / 1000}
        }
        setDisabledButton(true);
        props.onCloseEvent(newEvent);
    }

    return <Paper className={classes.paper} elevation={2}>
        <div className={classes.paperTitle}>
            <Typography component="h6" className={classes.typography}>Nuevo Evento</Typography>
            <IconButton onClick={closeCreateEventHandler} disabled={disabledButton}><CloseIcon/></IconButton>
        </div>
        <form onSubmit={newEventHandler}>
            <TextField label="Nombre" fullWidth className={classes.textField} required inputRef={nameRef} disabled={disabledButton}/>
            <TextField label="Promotor" fullWidth className={classes.textField} required inputRef={sponsorRef} disabled={disabledButton}/>
            <TextField label="Tema" fullWidth className={classes.textField} required inputRef={subjectRef} disabled={disabledButton}/>
            <CityInput onSetCity={setCityHandler} city={selectedCity} disabled={disabledButton} errorCity={errorCity}/>
            <TextField label="Url" fullWidth className={classes.textField} required type="url" inputRef={urlRef} disabled={disabledButton}/>
            
            <DatePicker label="Desde" value={dateStart} onChange={handleDateStart} disabled={disabledButton} required id="dateStart" 
                renderInput={(params) => <TextField fullWidth {...params}/> }/>

            <DatePicker label="Hasta" value={dateEnd} onChange={handleDateEnd} disabled={disabledButton} required id="dateEnd" 
            renderInput={(params) => <TextField fullWidth {...params}/> }/>
            
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<EventAvailableIcon />}
                type="submit"
                disabled={disabledButton}
            >Agregar Evento</Button>
        </form>
    </Paper>
}

export default FormEvent;
