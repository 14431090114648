import styles from './../../styles/FormAuth.module.css';
import {useDispatch, useSelector} from "react-redux";
import { userLogin } from '../../store/userActions'
import {useState, Fragment} from "react";
import {LinearProgress, Alert, Button} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Redirect} from 'react-router-dom';
import LayoutAuth from '../layout/LayoutAuth';
import Register from "./Register";

const useStyles = makeStyles({
   alert: {
       marginTop: '10px'
   }
});

const Login = () => {
    const dispatch = useDispatch();
    const error = useSelector(state => state.user.error);
    const message = useSelector(state => state.user.message);
    const userLoggedIn = useSelector(state => state.user.loggedIn)
    const gettingUserInfo = useSelector(state => state.user.gettingUserInfo)
    const messageError = useSelector(state => state.user.messageError)
    const [tabView, setTabView] = useState('login');

    const [inProgress, setInProgress] = useState(false);
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const loginHandler = (event) => {
        event.preventDefault();
        setInProgress(true);
        dispatch(userLogin({email, password}))
        setInProgress(false)
    }

    const changeEmailHandler = e => {
        setEmail(e.target.value)
    }

    const changePasswordHandler = e => {
        setPassword(e.target.value)
    }

    // if (userLoggedInd) {
    //     console.info('Como el usuario se encuentra logueado lo mandamos al home principal')
    //     history.push('/')
    // }
    
    return <Fragment>{userLoggedIn && <Redirect to="/" />}
    {(!userLoggedIn || gettingUserInfo) && <LayoutAuth><form onSubmit={loginHandler}>
        <ul className={styles['nav-tabs']}>
            <li className={styles['nav-item']}>
                <Button component="button" variant="body2" className={tabView === "login" ? styles.active : ""} onClick={() => setTabView('login')}><span>Login</span></Button>
            </li>
            <li className={styles['nav-item']}>
                <Button component="button" variant="body2" className={tabView === "register" && styles.active} onClick={() => setTabView('register')}><span>Registro</span></Button>
            </li>
        </ul>
        {messageError && <Alert severity="warning">{messageError}</Alert>}<br/>
        {tabView === 'login' &&
            <div>
                <p className={styles.text}>Ingrese su información para iniciar sesión en MyALAXS.</p>
                <div className={styles['form-control']}>
                    <label>Email:</label>
                    <input type="email" onChange={changeEmailHandler} required/>
                </div>
                <div className={styles['form-control']}>
                    <label>Password:</label>
                    <input type="password" onChange={changePasswordHandler} required/>
                </div>
                <div className={styles['form-control']}>
                    <button className={styles.button}>Ingresar</button>
                    {inProgress && !error && <LinearProgress color="secondary"/>}
                    {error && <Alert severity="error" className={classes.alert}>{message}</Alert>}
                </div>
            </div>
        }
        {tabView === 'register' && <Register/>}
    </form>
    </LayoutAuth>}</Fragment>
}

export default Login;
