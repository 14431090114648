import {Table, TableBody, TableCell, TableRow, Typography} from "@mui/material";
import React from "react";

export const TablePrices = ({data, product, setCurrentPrice}) => {
    const currentDate = new Date().toISOString();
    let currentPrice = null;

    if(data?.length > 0){
        currentPrice = data.find((priceRange) => priceRange.startDate <= currentDate && priceRange.endDate >= currentDate)
        if(!currentPrice){
            return "No hay precio vigente"
        }
        if(setCurrentPrice){
            setCurrentPrice(currentPrice)
        }
    }

    const RowPrice = ({title, type}) => (
        currentPrice?.[type]?.price &&
        <TableRow>
            <TableCell sx={{paddingBlock:"8px"}}>
                <Typography variant="caption"><strong>{title}:</strong></Typography><br/>
                <Typography variant="caption">{currentPrice?.[type]?.from} - {currentPrice?.[type]?.to} TM</Typography>
            </TableCell>
            <TableCell sx={{paddingBlock:"8px"}}>
                <Typography variant="caption">{Number(currentPrice?.[type]?.price).toFixed(2)} {product?.currency} / {product?.priceUnit?.symbol}</Typography>
            </TableCell>
        </TableRow>
    )

    return (
        <Table>
            <TableBody>
                <RowPrice title="Inicial" type="inicial"/>
                <RowPrice title="Negocio" type="negocio"/>
                <RowPrice title="Volumen" type="volumen"/>
                <TableRow>
                    <TableCell colSpan={2} sx={{paddingBlock:"8px", borderBottom: 0}}>
                        <Typography variant="caption"><strong>Fecha vigente de Precio: {" "}</strong> </Typography>
                        <Typography variant="caption">{currentPrice?.startDate ? new Date(currentPrice?.startDate).toLocaleDateString() : ""} - {currentPrice?.endDate ? new Date(currentPrice?.endDate).toLocaleDateString() : ""}</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}
