import {
  Button,
  TextField,
  FormControl,
  MenuItem,
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  Switch,
  Typography, InputAdornment,
  Rating, Breadcrumbs
} from "@mui/material";

import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import SearchCatalog from '../components/common/SearchCatalog';
import {CheckCircle, CheckCircleOutline} from '@mui/icons-material';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  boxCheckbox:{
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '32px'
  },
  boxRow:{
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  mgTb:{
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  }
}));

const allCheckedMultiple = {
  transporte: 0,
  pago: 0,
  visita: 0,
  recojo: 0,
  despacho: 0,
  empresa: 0,
  estado: 0,
  producto: 0,
  locacion: 0,
  industria: 0
}
const multipleCheckbox = {
  transporte: [false, false, false],
  pago: [false, false, false],
  visita: [false, false, false, false],
  recojo: [false, false, false, false],
  despacho: [false, false, false],
  empresa: [false, false, false],
  estado: [false, false, false, false],
  producto: [false, false, false, false],
  locacion: [false, false, false, false],
  industria: [false, false, false]
}

const Sale = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState('panel1');
  const [expandedCond, setExpandedCond] = useState('panel1');
  const [allChecked, setAllChecked] = useState(allCheckedMultiple);
  const [isChecked, setIsChecked] = useState(multipleCheckbox);
  const [cantidad, setCantidad] = useState('Unidad');
  
  const handleChangeAccordion = (panel) => (isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const handleChangeAccordionCond = (panel) => (isExpandedCon) => {
    setExpandedCond(isExpandedCon ? panel : false);
  };
  
  const updateCheckedMultiple = (id, updateChecked, checked) => {
    const objTemp = isChecked;
    const objAllTemp = allChecked;
    objTemp[id] = updateChecked;
    objAllTemp[id] = checked ? 1: 0;
    setIsChecked({...objTemp});
    setAllChecked({...objAllTemp});
  }
  
  const handleCheckById = (id, index) => {
    const updateChecked = isChecked[id].map((item, i) => i === index ? !item : item);
    const checked = updateChecked.some((element) => element);
    updateCheckedMultiple(id, updateChecked, checked);
  };
  
  
  const handleSelect = (e) => {
    setCantidad(e.target.value);
  };
  
  return <div style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Typography>LATINOAMERICA </Typography>
      <Link to="/assets">Assets</Link>
      <Link to="/assets/marketplace">Marketplace</Link>
      <Typography>Venta</Typography>
    </Breadcrumbs>
    <Container fixed>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Mi información</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SearchCatalog/>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box className={classes.boxRow}>
                <TextField label="¿Cuántos?"/>
                <TextField  fullWidth
                            id="outlined-select-currency"
                            select
                            label="Seleccione"
                            value={cantidad}
                            onChange={handleSelect}
                >
                  <MenuItem value={'Unidades'}>Unidades</MenuItem>
                  <MenuItem value={'Kilogramos'}>Kilogramos</MenuItem>
                  <MenuItem value={'Litros'}>Litros</MenuItem>
                  <MenuItem value={'Toneladas'}>Toneladas</MenuItem>
                </TextField>
              </Box>
              <FormControl fullWidth>
                <Box style={{display: 'flex', alignItems: 'center'}} spacing={2}>
                  <Typography style={{marginRight: '20px'}}>¿Specs?</Typography>
                  <Typography variant="body2">No</Typography>
                  <Switch/>
                  <Typography variant="body2">Si</Typography>
                </Box>
                <Box fullWidth className={classes.boxRow}>
                  <Typography>Adjuntar</Typography>
                </Box>
                <TextField type='file'/>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box fullWidth className={classes.boxRow}>
                <FormControl fullWidth>
                  <TextField required id={'years'} label={'¿Año(s)? (Referencial)'} fullWidth/>
                </FormControl>
              </Box>
              <Box fullWidth className={classes.boxRow}>
                <TextField
                  required id={'how-much'} label={'Precio total'} fullWidth
                  InputProps={{
                    endAdornment: <InputAdornment position="end">USD</InputAdornment>
                  }}
                />
              </Box>
              <FormGroup>
                <FormControlLabel control={<Checkbox/>} label="Esperado" />
                <FormControlLabel control={<Checkbox/>} label="No negociable" />
              </FormGroup>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Mis condiciones</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <FormGroup>
                <TextField required id={'Distrito'} label={'Distrito'}/>
                <Box fullWidth className={classes.mgTb}>
                  <Accordion expanded={expandedCond === 'panel1'} onChange={handleChangeAccordionCond('panel1')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Rating
                        value={allChecked["transporte"]}
                        readOnly
                        max={1}
                        icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                        emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                      />
                      <Box sx={{ ml: 2 }}>Costos de transporte</Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControlLabel
                        label="Transportamos nosotros"
                        control={<Checkbox checked={isChecked['transporte'][0]} onChange={() => handleCheckById('transporte',0)} />}
                      />
                      <FormControlLabel
                        label="Ustedes recogen"
                        control={<Checkbox checked={isChecked['transporte'][1]} onChange={() => handleCheckById('transporte',1)} />}
                      />
                      <FormControlLabel
                        label="Transporte de tercero"
                        control={<Checkbox checked={isChecked['transporte'][2]} onChange={() => handleCheckById('transporte',2)} />}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box fullWidth className={classes.mgTb}>
                  <Accordion expanded={expandedCond === 'panel2'} onChange={handleChangeAccordionCond('panel2')}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Rating
                        value={allChecked["pago"]}
                        readOnly
                        max={1}
                        icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                        emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                      />
                      <Box sx={{ ml: 2 }}>Forma de pago</Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormControlLabel
                        label="Cash/abono en cuenta"
                        control={<Checkbox checked={isChecked['pago'][0]} onChange={() => handleCheckById('pago',0)} />}
                      />
                      <FormControlLabel
                        label="En partes (2 o 3)"
                        control={<Checkbox checked={isChecked['pago'][1]} onChange={() => handleCheckById('pago',1)} />}
                      /><br/>
                      <FormControlLabel
                        label="A negociar"
                        control={<Checkbox checked={isChecked['pago'][2]} onChange={() => handleCheckById('pago',2)} />}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box style={{display: 'flex', alignItems: 'center'}} spacing={2}>
                  <Typography style={{marginRight: '20px'}}>Impuestos aplicables</Typography>
                  <Typography variant="body2">No existen</Typography>
                  <Switch/>
                  <Typography variant="body2">Si existen</Typography>
                </Box>
              </FormGroup>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel3'} onChange={handleChangeAccordionCond('panel3')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["visita"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Visita</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="Permitido"
                      control={<Checkbox checked={isChecked['visita'][0]} onChange={() => handleCheckById('visita',0)} />}
                    />
                    <FormControlLabel
                      label="No permitido"
                      control={<Checkbox checked={isChecked['visita'][1]} onChange={() => handleCheckById('visita',1)} />}
                    /><br/>
                    <FormControlLabel
                      label="Precisa especialista"
                      control={<Checkbox checked={isChecked['visita'][2]} onChange={() => handleCheckById('visita',2)} />}
                    />
                    <FormControlLabel
                      label="Precisa empresa certificadora"
                      control={<Checkbox checked={isChecked['visita'][3]} onChange={() => handleCheckById('visita',3)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Accordion expanded={expandedCond === 'panel4'} onChange={handleChangeAccordionCond('panel4')}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Rating
                    value={allChecked["recojo"]}
                    readOnly
                    max={1}
                    icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                    emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                  />
                  <Box sx={{ ml: 2 }}>Fecha de recojo</Box>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControlLabel
                    label="Inmediato"
                    control={<Checkbox checked={isChecked['recojo'][0]} onChange={() => handleCheckById('recojo',0)} />}
                  />
                  <FormControlLabel
                    label="En la semana"
                    control={<Checkbox checked={isChecked['recojo'][1]} onChange={() => handleCheckById('recojo',1)} />}
                  />
                  <FormControlLabel
                    label="En el mes"
                    control={<Checkbox checked={isChecked['recojo'][2]} onChange={() => handleCheckById('recojo',2)} />}
                  />
                  <FormControlLabel
                    label="A detallar"
                    control={<Checkbox checked={isChecked['recojo'][3]} onChange={() => handleCheckById('recojo',3)} />}
                  />
                </AccordionDetails>
              </Accordion>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel5'} onChange={handleChangeAccordionCond('panel5')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["estado"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Estado/condición</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="Nuevo / como nuevo"
                      control={<Checkbox checked={isChecked['estado'][0]} onChange={() => handleCheckById('estado',0)} />}
                    />
                    <FormControlLabel
                      label="Buen estado / usable"
                      control={<Checkbox checked={isChecked['estado'][1]} onChange={() => handleCheckById('estado',1)} />}
                    /><br/>
                    <FormControlLabel
                      label="Necesita revisión / limpieza / reparación"
                      control={<Checkbox checked={isChecked['estado'][2]} onChange={() => handleCheckById('estado',2)} />}
                    />
                    <FormControlLabel
                      label="Deteriorado"
                      control={<Checkbox checked={isChecked['estado'][3]} onChange={() => handleCheckById('estado',3)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel6'} onChange={handleChangeAccordionCond('panel6')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["producto"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Vigencia del producto</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="Vigente"
                      control={<Checkbox checked={isChecked['producto'][0]} onChange={() => handleCheckById('producto',0)} />}
                    />
                    <FormControlLabel
                      label="Por vencer"
                      control={<Checkbox checked={isChecked['producto'][1]} onChange={() => handleCheckById('producto',1)} />}
                    /><br/>
                    <FormControlLabel
                      label="Vencido"
                      control={<Checkbox checked={isChecked['producto'][2]} onChange={() => handleCheckById('producto',2)} />}
                    />
                    <FormControlLabel
                      label="No aplica"
                      control={<Checkbox checked={isChecked['producto'][3]} onChange={() => handleCheckById('producto',3)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel7'} onChange={handleChangeAccordionCond('panel7')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["despacho"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Despacho</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="Al barrer"
                      control={<Checkbox checked={isChecked['despacho'][0]} onChange={() => handleCheckById('despacho',0)} />}
                    />
                    <FormControlLabel
                      label="Conteo(unidades)"
                      control={<Checkbox checked={isChecked['despacho'][1]} onChange={() => handleCheckById('despacho',1)} />}
                    />
                    <FormControlLabel
                      label="Pesaje"
                      control={<Checkbox checked={isChecked['despacho'][2]} onChange={() => handleCheckById('despacho',2)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box style={{display: 'flex', alignItems: 'center'}} spacing={2}>
                <Typography style={{marginRight: '20px'}}>¿Tiene certificaciones?</Typography>
                <Typography variant="body2">No</Typography>
                <Switch/>
                <Typography variant="body2">Si</Typography>
              </Box>
              <Box style={{display: 'flex', alignItems: 'center'}} spacing={2}>
                <Typography style={{marginRight: '20px'}}>Permisos aplicables</Typography>
                <Typography variant="body2">No/ No aplica</Typography>
                <Switch/>
                <Typography variant="body2">Si</Typography>
              </Box>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="button">Mis expectativas</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel31'} onChange={handleChangeAccordionCond('panel31')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["locacion"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Locación</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography variant="subtitle1">¿A qué empresas quiere llegar?</Typography>
                
                    <FormControlLabel
                      label="De mi ciudad"
                      control={<Checkbox checked={isChecked['locacion'][0]} onChange={() => handleCheckById('locacion',0)} />}
                    />
                    <FormControlLabel
                      label="De mi región"
                      control={<Checkbox checked={isChecked['locacion'][1]} onChange={() => handleCheckById('locacion',1)} />}
                    />
                    <FormControlLabel
                      label="De mi país"
                      control={<Checkbox checked={isChecked['locacion'][2]} onChange={() => handleCheckById('locacion',2)} />}
                    />
                    <FormControlLabel
                      label="De toda latinoamerica"
                      control={<Checkbox checked={isChecked['locacion'][3]} onChange={() => handleCheckById('locacion',3)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel32'} onChange={handleChangeAccordionCond('panel32')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["industria"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Industrias</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="De mi industria"
                      control={<Checkbox checked={isChecked['industria'][0]} onChange={() => handleCheckById('industria',0)} />}
                    />
                    <FormControlLabel
                      label="De otras industrias (cuáles)"
                      control={<Checkbox checked={isChecked['industria'][1]} onChange={() => handleCheckById('industria',1)} />}
                    />
                    <FormControlLabel
                      label="Todas las industrias"
                      control={<Checkbox checked={isChecked['industria'][2]} onChange={() => handleCheckById('industria',2)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
              <Box fullWidth className={classes.mgTb}>
                <Accordion expanded={expandedCond === 'panel33'} onChange={handleChangeAccordionCond('panel33')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Rating
                      value={allChecked["empresa"]}
                      readOnly
                      max={1}
                      icon={<CheckCircle sx={{color: '#4caf50'}}/>}
                      emptyIcon={<CheckCircleOutline sx={{color: '#848484', opacity: 0.9}}/>}
                    />
                    <Box sx={{ ml: 2 }}>Tamaño de empresa</Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <FormControlLabel
                      label="Pequeñas"
                      control={<Checkbox checked={isChecked['empresa'][0]} onChange={() => handleCheckById('empresa',0)} />}
                    />
                    <FormControlLabel
                      label="Medianas"
                      control={<Checkbox checked={isChecked['empresa'][1]} onChange={() => handleCheckById('empresa',1)} />}
                    />
                    <FormControlLabel
                      label="Grandes"
                      control={<Checkbox checked={isChecked['empresa'][2]} onChange={() => handleCheckById('empresa',2)} />}
                    />
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Button variant="contained" color="primary">
                Colocar pedido
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Container>
  </div>
}

export default Sale;
