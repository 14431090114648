import {Card, CardContent, CardHeader} from "@mui/material";
// import InputIcon from '@mui/icons-material/Input';
// import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
// import TrendingUpIcon from '@mui/icons-material/TrendingUp';
// import AccessibilityIcon from '@mui/icons-material/Accessibility';
// import {Link} from 'react-router-dom';

const Menu = () => {
    return <Card>
        <CardHeader title="Business Intelligence" />
        <CardContent>
            
        </CardContent>
    </Card>
}

export default Menu;