import {Checkbox, FormControlLabel, Grid} from "@mui/material";
import {useState} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const ListSubIndustries = (props) => {
    const [selectedSubIndustries, setSelectedSubIndustries] = useState(props.selectedSubIndustries);

    const changeSelectedIndustryHandler = async e => {

        setSelectedSubIndustries({
            ...selectedSubIndustries, [e.target.name]: e.target.checked
        })
        const updateSelectedSubIndustryFunction = httpsCallable(functions, 'updateSelectedSubIndustry');
        await updateSelectedSubIndustryFunction({
            subIndustryId: e.target.name,
            checked: e.target.checked
        });

    }

    return props.subIndustries.map(si => {
        return <Grid item xs={12} md={6}><FormControlLabel control={<Checkbox name={si.id}/>} label={si.name}
                                                           checked={(selectedSubIndustries !== null && !!selectedSubIndustries[si.id]) ? selectedSubIndustries[si.id] : false}
                                                           onChange={changeSelectedIndustryHandler}/></Grid>
    });
}

export default ListSubIndustries;