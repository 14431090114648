import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import {Fragment} from "react";
import ListVideos from "../components/videos/ListVideos";

const Videos = () => {
    return <Fragment>
        <SubHeader title="Videos Corporativos"/>
        <Container fixed>
            <ListVideos/>
        </Container>
    </Fragment>
}

export default Videos;