import {useState, useEffect} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import NaicElement from "./NaicElement";
import {Card, CardContent, CardHeader, LinearProgress} from "@mui/material";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const ListNaics = () => {
    const [naics, setNaics] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchNaics = () => {
            const listNaics = httpsCallable(functions, 'listNaics');
            listNaics({
                level: 2
            }).then(result => {
                setNaics(result.data);
                setIsLoading(false);
            });
        }
        fetchNaics();
    }, []);

    return <Card>
        <CardHeader title="NAICS" />
        <CardContent>
            {isLoading && <LinearProgress/>}
            {naics.map(c => {
                return <NaicElement key={c.id} naic={c} />
            })}
        </CardContent>
    </Card>
}

export default ListNaics;