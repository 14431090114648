import {Card, CardContent, CardHeader, Container, Grid, LinearProgress} from "@mui/material";
import SubHeader from "../components/common/SubHeader";
import {Fragment, useEffect, useState} from 'react';
import InfoDescription from "../components/profile/InfoDescription";
import firebaseApp from "../firebase/firebaseApp";
import UsersCollection from "../components/profile/UsersCollection";
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(firebaseApp)

const Profile = () => {

    const [companyInfo, setCompanyInfo] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const companyInfoFunction = httpsCallable(functions, 'getCompanyInfo');
            const companyData = await companyInfoFunction();
            setCompanyInfo(companyData.data);
            console.log(companyData.data);
            setIsLoading(false);
        }

        fetchData();
    }, []);

    return <Fragment>
        <SubHeader title="Mi Perfil"/>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Mi Perfil" />
                        <CardContent>
                            {isLoading && <LinearProgress />}
                            {!isLoading &&
                                <Fragment>
                                    <InfoDescription label="Nombre de la empresa" value={companyInfo.company.name}/>
                                    <InfoDescription label="Codigo Tributario" value={companyInfo.company.code}/>
                                    <InfoDescription label="AIID - Alaxs Internal ID" value={companyInfo.company.id}/>
                                    <InfoDescription label="Usuario Registrado" value={companyInfo.user.name}/>
                                    <InfoDescription label="Email Registrado" value={companyInfo.user.email}/>
                                    <InfoDescription label="Pais" value={companyInfo.company.city.country.name}/>
                                    <InfoDescription label="Rol" value={companyInfo.user.position.name}/>
                                    <InfoDescription label="Actividad de tu Empresa"
                                                     value={companyInfo.company.industry.name}/>
                                    <InfoDescription label="Número de Personal"
                                                     value={companyInfo.company.employeerange.name}/>
                                    <InfoDescription label="Facturación Anual (USD, M)"
                                                     value={companyInfo.company.income.name}/>
                                    <InfoDescription label="Fecha de tu Registro"
                                                     value={new Date(companyInfo.user.creationDate)
                                                         .toLocaleDateString()}/>
                                    <UsersCollection label="Otros Usuarios de tu Empresa"
                                                     users={companyInfo.company.users} />
                                </Fragment>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Fragment>
}

export default Profile;