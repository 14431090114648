import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    LinearProgress
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%"
    }
}));

const functions = getFunctions(firebaseApp)

const NaicElement = (props) => {
    const classes = useStyles();
    const [naics, setNaics] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    const handleChange = (event, isExpanded) => {
        if (isExpanded)
        {
            if (!isFetched)
            {
                if (props.naic.level < 5)
                {
                    setIsLoading(true);
                    const listNaics = httpsCallable(functions, 'listNaics');
                    listNaics({
                        level: props.naic.level + 1,
                        id: props.naic.id
                    }).then(result => {
                        setIsLoading(false);
                        setNaics(result.data);
                        setIsFetched(true);
                    });
                }
            }
        }
    }

    return <Accordion onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
            <Typography>{props.naic.code} - {props.naic.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.wrapper}>
                {isLoading && <LinearProgress/>}
                {!isLoading && props.naic.level < 5 && naics.map(c => {
                    return <NaicElement key={c.id} naic={c} />
                })}
                {!isLoading && props.naic.level === 5
                && naics.map(c => {
                    return <Typography>{c.code} - {c.name}</Typography>
                })
                }
            </div>
        </AccordionDetails>
    </Accordion>
}

export default NaicElement;