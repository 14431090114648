import firebaseApp from '../firebase/firebaseApp';
import {setPostThematics, setError} from './postSlice';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

export const getListPostThematic = () => {
  return async (dispatch) => {
    try {
      const listPostThematics = httpsCallable(functions, 'listPostThematics');
      const {data} = await listPostThematics();
      dispatch(setPostThematics(data));
    }
    catch (error){
      dispatch(setError({existError: true, message: error.message}));
    }
  }
}
