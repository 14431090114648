import { makeStyles } from '@mui/styles';
import {Box, Paper, Typography, TableContainer, Table, TableHead, TableRow, TableCell} from "@mui/material";
import {TablePrices} from "./TablePrices";
import React from "react";
const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#fff"
    },
    paperDark: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#eee"
    }  
}));

const PriceRanges = ({product}) => {
    const {currency, incoterm, unit, priceUnit, priceRanges} = product
    const classes = useStyles();

    return <Box className={classes.paperDark}>
        <Typography variant="button">IV. INFORMACIÓN DE PRECIOS</Typography>
        <Paper className={classes.paper}>
            <Typography variant="subtitle2" component="span">Moneda: </Typography>
            <Typography variant="body2" gutterBottom component="span">{currency ?? "-"}</Typography><br/>
            <Typography variant="subtitle2" component="span">Condición de venta: </Typography>
            <Typography variant="body2" gutterBottom component="span">{incoterm ?? "-"}</Typography><br/>
            <Typography variant="subtitle2" component="span">Unidad de precio: </Typography>
            <Typography variant="body2" gutterBottom component="span">{priceUnit?.symbol ?? "-"}</Typography><br/>
            <Typography variant="subtitle2" component="span">Unidad de cantidad: </Typography>
            <Typography variant="body2" gutterBottom component="span">{unit?.symbol ?? "-"}</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Rango Cantidades</TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
                <TablePrices data={priceRanges} product={product}/>
            </TableContainer>
        </Paper>
    </Box>
}

export default PriceRanges;
