import {Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%"
    }
}));

const ExportInfo = () => {
    const classes = useStyles();
    return <Card>
        <CardHeader title="Exportaciones al Asia del Este (2016 - 2018)" />
        <CardContent>
            <img className={classes.image} src="https://storage.googleapis.com/alaxs-0003.appspot.com/tt_1.png"
                 alt="Exportaciones" />
        </CardContent>
    </Card>
}

export default ExportInfo;