import {Link, Typography, TableRow, TableCell, IconButton, Checkbox} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import {useSelector} from "react-redux";
import {Fragment, useState} from 'react';
import EditEvent from "./EditEvent";

const RowEvent = (props) => {

    const userAdmin = useSelector(state => state.user.admin);
    const [open, setOpen] = useState(false);

    const editEventHandler = () => {
        setOpen(true);
    }

    const closeEventHandler = (eventInfo) => {
        // Todo: if the object eventInfo has data, make it visible in the table
        if (eventInfo)
        {
            props.onUpdateEvent(eventInfo);
        }
        setOpen(false);
    }

    const deleteEventHandler = (eventInfo) => {
        if (eventInfo)
        {
            props.onDeleteEvent(eventInfo);
        }
        setOpen(false);
    }

    return <Fragment>
        {open && <EditEvent open={open} onClose={closeEventHandler} onDelete={deleteEventHandler} eventInfo={props.event} />}
        <TableRow>
            <TableCell><Link href={props.event.url} target='_blank'><Typography variant='body2' >{props.event.name}</Typography></Link></TableCell>
            <TableCell><Typography variant='body2' >{props.event.dateStart}</Typography></TableCell>
            <TableCell><Typography variant='body2' >{props.event.city.name}</Typography></TableCell>
            <TableCell><Typography variant='body2' >{props.event.subject}</Typography></TableCell>
            <TableCell><Typography variant='body2' >{props.event.sponsor}</Typography></TableCell>
            <TableCell><Checkbox /></TableCell>
            {userAdmin && <TableCell><IconButton color='primary' onClick={editEventHandler}><EditIcon/></IconButton></TableCell>}
        </TableRow>
    </Fragment>
}

export default RowEvent;
