import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import CustomInfo from "../components/bi/CustomInfo";

const BiCustom = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Importaciones x Partida"/>
        <Container fixed>
            <CustomInfo/>
        </Container>
    </Fragment>
}

export default BiCustom;