import {createSlice} from '@reduxjs/toolkit'

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        id: null,
        email: null,
        loggedIn: null,
        role: null,
        profile: null,
        active: null,
        error: null,
        emailVerified: null,
        existError: null,
    },
    reducers: {
        login: (state) => {
            state.loggedIn = true;
            state.existError = false;
            state.message = '';
        },
        logout: (state) => {
            state.loggedIn = false;
        },
        setInfo: (state, action) => {
            state.role = action.payload.role
            state.profile = action.payload.profile
            state.active = action.payload.active
        },
        setError: (state, action) => {
            state.message = action.payload.message;
            state.existError = action.payload.existError;
        }
    }
})

export const {login, logout, setInfo, setError} = authSlice.actions;
export default authSlice.reducer;
