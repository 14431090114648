import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import FaqInfo from "../components/faq/FaqInfo";

const Faq = () => {
    return <Fragment>
        <SubHeader title="Preguntas Frecuentes"/>
        <Container fixed>
            <FaqInfo/>
        </Container>
    </Fragment>
}

export default Faq;