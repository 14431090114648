import {useState, useEffect} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import CustomElement from "./CustomElement";
import {Card, CardContent, CardHeader, LinearProgress} from "@mui/material";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const ListCustomCodes = () => {
    const [customs, setCustoms] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchCustoms = () => {
            const listCustoms = httpsCallable(functions, 'listCustoms');
            listCustoms({
                level: 2
            }).then(result => {
                setCustoms(result.data);
                setIsLoading(false);
            });
        }
        fetchCustoms();
    }, []);

    return <Card>
        <CardHeader title="Partidas Arancelarias" />
        <CardContent>
            {isLoading && <LinearProgress/>}
            {customs.map(c => {
                return <CustomElement key={c.id} custom={c} />
            })}
        </CardContent>
    </Card>
}

export default ListCustomCodes;