import {
  Container,
  Grid,
  Paper
} from "@mui/material";
import {Fragment} from 'react';
import SubHeader from '../components/common/SubHeader';

const Valores = () => {
  return <Fragment>
    <SubHeader parentUrl="/loyalty-club" parentTitle="Loyalty Club" title='Valores'/>
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <ul>
              <li><strong>Conectividad:</strong> conectamos oportunidades de mercado a nuestros clientes, explorando mayores mercados y haciendo más eficientes sus procesos de adquisición y ventas.</li>
              <li><strong>Diversidad: </strong> sabemos de la diversidad de industrias a las que atendemos, la multiculturalidad de las personas que laboran en éstas, la inmensa dimensión de empresas existentes, la diversidad de zonas de desarrollo y otras diversidades locales que atendemos; por lo expuesto es que nos adaptamos a las distintas realidades que atendemos.</li>
              <li><strong>Facilidad::</strong> simplificamos la información de mercados globales y sin intermediarios a fin de brindar a nuestros clientes el soporte necesario en operaciones de trading y transporte logístico alrededor del globo.</li>
              <li><strong>Expansión: </strong> nuestra plataforma está diseñada y estructurada para conectar y expandir las oportunidades de nuestros clientes con los mercados más vigorosos y en expansión del mundo.</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  </Fragment>
}

export default Valores;
