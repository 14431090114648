import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        id: null,
        email: null,
        loggedIn: null,
        role: null,
        profile: null,
        active: null,
        error: null,
        emailVerified: null,
        companyName: null,
        message: null,
        name: null,
        gettingUserInfo: null,
    },
    reducers: {
        login: (state, action) => {
            state.id = action.payload.id
            state.email = action.payload.email
            state.loggedIn = true
            state.emailVerified = action.payload.emailVerified
        },
        logout: state => {
            state.loggedIn = false
        },
        setInfo: (state, action) => {
            state.role = action.payload.role
            state.profile = action.payload.profile
            state.active = action.payload.active
            state.companyName = action.payload.companyName
            state.name = action.payload.name
        },
        setError: (state, action) => {
            state.error = action.payload.error
            state.message = action.payload.message
        },
        setGettingUserInfo: (state, action) => {
            state.gettingUserInfo = action.payload.gettingUserInfo
        },
        setMessageError: (state, action) => {
            state.messageError = action.payload.messageError
        }
    }
});

// export const userActions = userSlice.actions;
export const { login, logout, setInfo, setError, setGettingUserInfo, setMessageError } = userSlice.actions
export default userSlice.reducer;
