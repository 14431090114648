import StillWork from '../img/still-working.png'
import {Breadcrumbs, Typography} from '@mui/material';

const StillWorking = (props) => {
    const {title} = props
    return <div style={{
        background: `url(${StillWork}) no-repeat center bottom`, 
        backgroundSize: 'cover',
        height: 'calc(100vh - 64px)', marginTop: '-20px'}}>
        <div style={{marginTop: '-24px'}}>
            <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
                <Typography>{title} </Typography>
            </Breadcrumbs>
        </div>
    </div>
}

export default StillWorking;
