import React, {useEffect} from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  TextField,
  LinearProgress,
  Table,
  TableContainer,
  TableHead, TableRow, Paper, TableCell, TableBody
} from "@mui/material";
import {useState} from 'react';
import firebaseApp from '../../firebase/firebaseApp';
import {makeStyles} from '@mui/styles';
import {getQuantity} from './utilsOrders';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {useSelector} from 'react-redux';

const functions = getFunctions(firebaseApp)

const useStyles = makeStyles((theme) => ({
  dialogRequerimiento: {
    '& .MuiPaper-root': {
      maxWidth: '900px'
    }
  },
  contentTitle: {
    display: 'flex',
    margin: '20px 0 18px',
    justifyContent: 'space-between'
  },
  colorRed: {
    color: 'red'
  }
}));

const ModalModifyOrder = (props) => {
  const {email, name, companyName} = useSelector(state => state.user);
  const {showOrder, setShowOrder, currentOrder, closeModal, getOpenedOrders} = props;
  const classes = useStyles();
  const [processing, setProcessing] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [monthlyOrder, setMonthlyOrder] = useState([]);
  
  useEffect(() => {
    const {newMonthyOrden, totalQuantity} = getQuantity(currentOrder.monthlyOrder);
    setMonthlyOrder(newMonthyOrden);
    setTotalQuantity(totalQuantity);
  }, [currentOrder])
  
  useEffect(() => {
    const reduce = monthlyOrder.reduce((acc, current) => parseFloat(acc) + current?.quantity, 0);
    setTotalQuantity(reduce)
  }, [monthlyOrder])
  
  const changeMonthlyQuantity = (e, index) => {
    let {value} = e.target;
    if (value !== '') {
      value = parseFloat(value);
    }
    let listTemp = monthlyOrder;
    listTemp[index].quantity = value;
    setMonthlyOrder([...listTemp]);
    
  }
  
  const handleConfirm = () => {
    if (totalQuantity > 0) {
      setProcessing(true);
      const orderBody = {id: currentOrder.id, monthlyOrder, quantity: totalQuantity};
      const updateOrderFunction = httpsCallable(functions, 'updateOpenOrder');
      updateOrderFunction(orderBody).then(result => {
        console.log('se modificó la orden', result);
        setProcessing(false);
        getOpenedOrders();
        setShowOrder(false);
        sendMailing();
      });
    }
  }
  
  const sendMailing = () => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>La cantidad de su pedido ha sido modificado con éxito en nuestra plataforma.</p>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p><strong>Detalle de la orden</strong></p>
             <ul>
              <li><strong>Tracking Code: </strong>${currentOrder.id}</li>
              <li><strong>Producto: </strong>${currentOrder.product.name}</li>
              <li><strong>Cantidad: </strong>${currentOrder.quantity} {currentOrder.unitOrder}</li>
              <li><strong>Proveedor: </strong>${currentOrder.product.supplier.name}</li>
             </ul>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }
  
  return <Dialog open={showOrder} className={classes.dialogRequerimiento}>
    <DialogTitle>Requerimiento total</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Especifique los siguentes datos para confirmar su orden: <br/><br/>
      </DialogContentText>
      <b>Cantidad Anual:</b> {totalQuantity} {currentOrder?.product?.unit?.symbol}
      <div className={classes.contentTitle}>
        <DialogContentText>
          Meses Tentativos:
        </DialogContentText>
      </div>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{monthlyOrder[0]?.label}</TableCell>
              <TableCell>{monthlyOrder[1]?.label}</TableCell>
              <TableCell>{monthlyOrder[2]?.label}</TableCell>
              <TableCell>{monthlyOrder[3]?.label}</TableCell>
              <TableCell>{monthlyOrder[4]?.label}</TableCell>
              <TableCell>{monthlyOrder[5]?.label}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell>
                {monthlyOrder[0]?.state === "disabled" ?
                    <TextField type='number' variant="filled" disabled/> :
                    <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                               onChange={(e) => changeMonthlyQuantity(e, 0)} value={monthlyOrder[0]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[1]?.state === "disabled" ?
                    <TextField type='number' variant="filled" disabled/> :
                    <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                               onChange={(e) => changeMonthlyQuantity(e, 1)} value={monthlyOrder[1]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[2]?.state === "disabled" ?
                    <TextField type='number' variant="filled" disabled/> :
                    <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                               onChange={(e) => changeMonthlyQuantity(e, 2)} value={monthlyOrder[2]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[3]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 3)} value={monthlyOrder[3]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[4]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 4)} value={monthlyOrder[4]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[5]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 5)} value={monthlyOrder[5]?.quantity}/>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer component={Paper}>
        <Table sx={{minWidth: 650}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{monthlyOrder[6]?.label}</TableCell>
              <TableCell>{monthlyOrder[7]?.label}</TableCell>
              <TableCell>{monthlyOrder[8]?.label}</TableCell>
              <TableCell>{monthlyOrder[9]?.label}</TableCell>
              <TableCell>{monthlyOrder[10]?.label}</TableCell>
              <TableCell>{monthlyOrder[11]?.label}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
              <TableCell>
                {monthlyOrder[6]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 6)} value={monthlyOrder[6]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[7]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 7)} value={monthlyOrder[7]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[8]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 8)} value={monthlyOrder[8]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[9]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 9)} value={monthlyOrder[9]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[10]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 10)} value={monthlyOrder[10]?.quantity}/>
                }
              </TableCell>
              <TableCell>
                {monthlyOrder[11]?.state === "disabled" ?
                  <TextField type='number' variant="filled" disabled/> :
                  <TextField type='number' InputProps={{inputProps: {min: 0}}} placeholder={0}
                             onChange={(e) => changeMonthlyQuantity(e, 11)} value={monthlyOrder[11]?.quantity}/>
                }
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {processing && <LinearProgress/>}
    </DialogContent>
    <DialogActions>
      <Button onClick={closeModal}>Cancelar</Button>
      <Button onClick={handleConfirm} variant='contained'>Confirmar</Button>
    </DialogActions>
  </Dialog>
}

export default ModalModifyOrder;
