import {Breadcrumbs, Container} from "@mui/material";
import ListNaics from "../components/catalogs/ListNaics";
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

const Customs = () => {
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>LATINOAMERICA </Typography>
            <Link to="/assets">assets</Link>
            <Link to="/assets/warehouse">warehouse</Link>
            <Link to="/assets/warehouse/catalog">Catálogos ALAXS</Link>
            <Typography>NAICS</Typography>
        </Breadcrumbs>
        <Container style={{marginTop: '24px'}}>
            <ListNaics/>
        </Container>
    </div>
}

export default Customs;
