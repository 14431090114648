import React, {useState, useRef} from 'react';
import {Link} from 'react-router-dom'
import './../styles/Home.css'
import {
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import {makeStyles} from '@mui/styles';
import alaxs from './../img/alaxs.svg';
import video from './../img/video.png';
import obj1 from './../img/objetivo-1.svg';
import obj2 from './../img/objetivo-2.svg';
import obj3 from './../img/objetivo-3.svg';
import obj4 from './../img/objetivo-4.svg';
import trading from './../img/Trading_c.svg';
import loyalty from './../img/Loyalti_c.svg';
import perfil from './../img/Perfil_c.svg';
import ferias from './../img/Ferias_c.svg';
import thinktank from '../img/Think_tank_c.svg';
import arrowNext from '../img/arrow-next.svg';
import arrowPrev from '../img/arrow-prev.svg';
import alaxsB from '../img/alaxs.gif';
import facebook from '../img/facebook.svg';
import twitter from '../img/twitter.svg';
import instagram from '../img/instagram.svg';
import youtube from '../img/youtube.svg';
import linkedin from '../img/Linkedin.svg';
/*
import dashboard from '../img/dashboard.png';
*/
import arrowDown from '../img/arrow-down.svg';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper':{
      maxWidth: '70%',
      width: '100%'
    }
  }
}));

const Home = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [linkVideo, setLinkVideo] = useState('');
  const sectionDescripRef = useRef(null);
  
  const openVideo = (video) => {
    setLinkVideo(`https://www.youtube.com/embed/${video}?modestbranding=1&rel=0&controls=1&showinfo=0&html5=1&autoplay=1`)
    setOpen(true);
  }
  
  const SlickButtonFix = ({ currentSlide, slideCount, children, ...props }) => (
    <span {...props}>{children}</span>
  );
  
  const settings = {
    className: 'center',
    centerMode: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    centerPadding: "0",
    infinite: true,
    nextArrow: <SlickButtonFix><img alt='arrow-next' src={arrowNext}/></SlickButtonFix>,
    prevArrow: <SlickButtonFix><img alt='arrow-next' src={arrowPrev}/></SlickButtonFix>,
    dots: true,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 2
      }
    },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  };
  
  return <div className='home' style={{marginTop: '-24px'}}>
    <Link className='dashboard' to='/trading'><ShoppingCartIcon style={{width: '42px', height: '42px'}}/></Link>
    <section className='hero'>
      <Container>
        <img className='logo' src={alaxs} alt='alaxs'/>
        <div className='content'>
          <div>
            <p className='title red-lucky'>¡Bienvenido</p>
            <p className='title dark-blue'>a MyALAXS!</p>
            <div className='subtitle'>
              El marketplace <br/>donde conectamos <br/>oportunidades B2B <br/>entre <span className='red-lucky'>LatAm y Asia.</span>
            </div>
          </div>
          <div className='content-buttons'>
            <div className='button-icon' onClick={() => openVideo('T7Aj4eUw1rs')}>
              <h4>Mira lo último</h4> <img src={video} alt='video'/>
            </div>
          </div>
        </div>
      </Container>
    </section>
    <img className='arrow-down' src={arrowDown} alt='bajar'
         onClick={() => sectionDescripRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})}/>
    <div className='bg-arco'/>
    <Container>
      <section className='section-description'>
        <div className='card'>
          <img src={obj1} alt='incremento'/>
          <p>Incrementa la eficiencia de tu negocio.</p>
        </div>
        <div className='card'>
          <img src={obj2} alt='descubre'/>
          <p>Descubre oportunidades en Asia a un click</p>
        </div>
        <div className='card'>
          <img src={obj3} alt='logística'/>
          <p>Negocios, pagos y logística en una misma plataforma.</p>
        </div>
        <div className='card'>
          <img src={obj4} alt='comercio'/>
          <p>Comercio B2B, simple, directo y <br/> en tu idioma. </p>
        </div>
      </section>
    </Container>
    <section className='section-cards'>
      <Container>
        <p className='title' ref={sectionDescripRef}>¿CÓMO PODEMOS <br/>AYUDARTE HOY?</p>
        <div className='grid-card'>
          <div></div>
          <Link to='/buying' className='card'>
            <i className='icon-plus'/>
            <img className='card-icon' src={trading} alt='trading'/>
            <p className='card-title'>TRADING</p>
            <p className='card-subtitle'>(Compras)</p>
          </Link>
          <Link to='/think-tank' className='card'>
            <i className='icon-plus'/>
            <img className='card-icon' src={thinktank} alt='think-tank'/>
            <p className='card-title'>THINK TANK</p>
            <p className='card-subtitle'>(Artículos, info y más)</p>
          </Link>
          <Link to='/loyalty-club' className='card'>
            <i className='icon-plus'/>
            <img className='card-icon' src={loyalty} alt='loyalty'/>
            <p className='card-title'>LOYALTY <br/> CLUB</p>
          </Link>
          <Link to='/events/proximos' className='card'>
            <i className='icon-plus'/>
            <img className='card-icon' src={ferias} alt='ferias'/>
            <p className='card-title'>EVENTOS/ <br/>FERIAS</p>
          </Link>
          <Link to='/profile' className='card'>
            <i className='icon-plus'/>
            <img className='card-icon' src={perfil} alt='perfil'/>
            <p className='card-title'>MI PERFIL</p>
          </Link>
        </div>
        <div className='section-space'/>
        <Slider {...settings}>
          <Link to='/think-tank' className='slide-item noticias'>
            <div className='bg-slide'/>
            <div className='slide-title'>NOTICIAS</div>
          </Link>
          <Link to='/webinars' className='slide-item webinar'>
            <div className='bg-slide'/>
            <div className='slide-title'>WEBINARS</div>
          </Link>
          <Link to='/think-tank' className='slide-item articulos'>
            <div className='bg-slide'/>
            <div className='slide-title'>ARTÍCULOS</div>
          </Link>
          <Link to='/events/proximos' className='slide-item otros'>
            <div className='bg-slide'/>
            <div className='slide-title'>EVENTOS</div>
          </Link>
        </Slider>
      </Container>
    </section>
    <footer>
      <Container>
        <div className='content-footer'>
          <img src={alaxsB} className='logo' alt='alaxs'></img>
          <div className='socials'>
            <a href='https://facebook.com/alaxs.official' target='_blank' rel='noreferrer'><img src={facebook} alt='facebook'/></a>
            <a href='https://www.linkedin.com/company/alaxs' target='_blank' rel='noreferrer'><img src={linkedin} alt='linkedin'/></a>
            <a href='https://twitter.com/ALAXS_Official' target='_blank' rel='noreferrer'><img src={twitter} alt='twitter'/></a>
            <a href='https://www.instagram.com/alaxs_official' target='_blank' rel='noreferrer'><img src={instagram} alt='instagram'/></a>
            <a href='https://www.youtube.com/channel/UCYpIlZsYokQat0wGDjqz5hQ' target='_blank' rel='noreferrer'><img src={youtube} alt='youtube'/></a>
          </div>
        </div>
      </Container>
    </footer>
    <Dialog open={open} className={classes.dialog} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Button onClick={() => setOpen(false)}>Cerrar</Button>
      </DialogTitle>
      <DialogContent>
        <div className="embed-container">
          <iframe title="video embed" width="80%" height="350" src={linkVideo} frameBorder="0" allowFullScreen></iframe>
        </div>
      </DialogContent>
    </Dialog>
  </div>
}

export default Home;
