import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import {Link} from '@mui/material';
import React, {useState, Fragment} from 'react';
import ModalDetailOrder from './ModalDetailOrder';
import ModalDetailPayment from './ModalDetailPayment';

const PaymentDeliveryOrders = (props) => {
  const [showOrder, setShowOrder] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(undefined);
  const [showDetailPayment, setShowDetailPayment] = useState(false);
  const [typeDocs, setTyDocs] = useState(false);
  
  const showOrderDetail = (order) => {
    setCurrentOrder(order);
    setShowOrder(true);
  }
  
  const closeDetailOrder = () => {
    setCurrentOrder(undefined);
    setShowOrder(false)
  }
  
  const showOrderPayment = (order, type) => {
    setCurrentOrder(order);
    setTyDocs(type);
    setShowDetailPayment(true);
  }
  
  const closeDetailPayment = () => {
    setCurrentOrder(undefined);
    setShowDetailPayment(false)
  }
  
  return <Fragment>
    {currentOrder &&
      <ModalDetailOrder
        showOrder={showOrder}
        currentOrder={currentOrder}
        closeModal={closeDetailOrder}
      />
    }
    {currentOrder &&
      <ModalDetailPayment
        showOrder={showDetailPayment}
        typeDocs={typeDocs}
        currentOrder={currentOrder}
        setShowOrder = {setShowDetailPayment}
        closeModal = {closeDetailPayment}
      />
    }
    <Card>
      <CardContent>
        <span>3.3 OPERACIONES YA PAGADAS</span><br/><br/>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Track Code</TableCell>
                <TableCell>Monto Final Pagado (USD)</TableCell>
                <TableCell>Docs</TableCell>
                <TableCell>Fecha Pago Banco</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!props.isLoading && (props.orders?.length > 0 ? props.orders?.map((order) =>
                  <TableRow key={order.id}>
                    <TableCell>{order.product.name}</TableCell>
                    <TableCell>{order.quantity}</TableCell>
                    <TableCell><Link onClick={showOrderDetail.bind(this, order)}>{order.id}</Link></TableCell>
                    <TableCell>{new Intl.NumberFormat('es-MX').format(order.finalAmount)}</TableCell>
                    <TableCell>
                      <p><Link href='#' onClick={() => showOrderPayment(order, 'invoice')}>Payment</Link></p>
                      <Link href='#' onClick={() => showOrderPayment(order, 'packing')}>Packing</Link>
                    </TableCell>
                     <TableCell>
                       {new Date(order.payment?.date._seconds * 1000).toLocaleDateString()}
                     </TableCell>
                  </TableRow>):
                  <TableRow><TableCell>Sin operaciones</TableCell></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {props.isLoading && <LinearProgress/>}
      </CardContent>
    </Card>
  </Fragment>
}

export default PaymentDeliveryOrders;
