export const getLocacion = (locacion) => {
  switch (locacion){
    case '1': return 'Asia';
    case '2': return 'África';
    case '3': return 'LatAm';
    case '4': return 'Europe';
    default: return '';
  }
}

export const pathImageStorage = 'https://storage.googleapis.com/myalaxs-images/';

const optionsDate = {year: 'numeric', month: 'long', day: 'numeric' };

export const formatDate = (datePost) => {
  if(datePost){
    const date = new Date(datePost);
    const format = date.toLocaleDateString('es-ES', optionsDate);
    return format;
  }
}

export const formatDateShort = (datePost) => {
  if(datePost){
    const date = new Date(datePost);
    const format = date.toLocaleDateString('es-ES', {month: 'short'}) + '/' + date.getUTCFullYear();
    return format;
  }
}
