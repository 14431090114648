import {
    Card,
    CardContent,
    Container, Typography, Breadcrumbs
} from "@mui/material";

import { makeStyles } from '@mui/styles';
import Families from "../components/products/Families";
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row'
    },
    chip: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginBottom: theme.spacing(2)
    },
    textField: {
        marginTop: theme.spacing(2),
        width: '100%',
    },
    formControl: {
        marginBottom: theme.spacing(1)
    },
    alignTop: {
        marginTop: theme.spacing(2)
    },
    listItem: {
        backgroundColor: "#eee",
        '&:hover': {
            background: "#ccc",
         },
    },
    divider: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(1)
    },
    icon: {
        width: 150
    },
    rest: {
        width: '100%'
    },
    right: {
        textAlign: 'right'
    },
    fit: {
        width: 30
    },
    card: {
        backgroundColor: "#fff"
    },
    cardTitle: {
        color: "#112091",
    },
    paper: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#fff"
    },    
    paperDark: {
        backgroundColor: "#eee"
    },
    mt3: {
        marginTop: theme.spacing(4)
    },
    table: {
        marginTop: theme.spacing(2),
        backgroundColor: "#fff"
    },
    shoppingCart: {
      color: "#DE521B"
    },
    tableHead: {
        backgroundColor: "#F9FBFA"
    },
    selectedRow: {
        backgroundColor: "#a9b6f5"
    },
    pointRow: {
        color: "#475df1",
        fontSize: "2rem",
        fontWeight: "bold"
    },
    messageUser: {
        color: "#0e1b7a",
    },
    info: {
        width: '40%'
    },
    smallNumber: {
        width: '28%'
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '15%',
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: '#fff',
    },
    accordion: {
        marginTop: theme.spacing(2),
        backgroundColor: '#DE521B',
        color: '#fff'
    },
    expandIcon: {
        color: '#fff'
    }
}));

const NewBuying = () => {
    const classes = useStyles();
    
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>trading </Typography>
            <Typography>requerimiento </Typography>
            <Link to="/buying">Nuevo</Link>
            <Typography>requerimiento por catálogo</Typography>
        </Breadcrumbs><br/>
        <Container fixed>
            <Card className={classes.card}>
                <CardContent>
                    <Families/>
                </CardContent>
            </Card>
        </Container>
    </div>
}

export default NewBuying;
