import {Checkbox, FormControlLabel} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import {Link} from '@mui/material';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import React, {useState, Fragment} from 'react';
import ModalDocsBank from './ModalDocsBank.js';
import ModalDetailOrder from './ModalDetailOrder';
import ModalDetailPayment from './ModalDetailPayment';
import ModalLiquitationDocs from "./ModalLiquitationDocs";

Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter( key => predicate(obj[key]) )
    .reduce( (res, key) => (res[key] = obj[key], res), {} );

const PaymentPending = (props) => {
  const {setIsLoadingPendingOrders, getPendingOrders, getSentOrders, idOrders, setIdOrders, checkedValues, setCheckedValues} = props;
  const [showOrder, setShowOrder] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(undefined);
  const [showDialog, setShowDialog] = useState(false);
  const [showLiquitationDocs, setShowLiquitationDocs] = useState(false);
  const [showDetailPayment, setShowDetailPayment] = useState(false);
  const [typeDocs, setTyDocs] = useState(false);
  const [sendEnabled, setSendEnabled] = useState(false);
  const [docs, setDocs] = useState([]);
  
  const showOrderDetail = (order) => {
    setCurrentOrder(order);
    setShowOrder(true);
  }

  const showDialogLiquitationDocs = (docs) => {
    setDocs(docs);
    setShowLiquitationDocs(true);
  }

  const showOrderPayment = (order, type) => {
    setCurrentOrder(order);
    setTyDocs(type);
    setShowDetailPayment(true);
  }
  
  const handleChange = (event) => {
    setCheckedValues({ ...checkedValues, [event.target.id]: event.target.checked });
  };
  
  const closeDetailOrder = () => {
    setCurrentOrder(undefined);
    setShowOrder(false)
  }
  
  const closeDetailPayment = () => {
    setCurrentOrder(undefined);
    setShowDetailPayment(false)
  }
  
  return <Fragment>
    <Card>
      <CardContent>
        <span>3.1 OPERACIONES PENDIENTES DE ENVIO AL BANCO</span><br/><br/>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Producto</TableCell>
                <TableCell>Cantidad</TableCell>
                <TableCell>Track Code</TableCell>
                <TableCell>Monto Final a pagar (USD)</TableCell>
                <TableCell>Docs</TableCell>
                <TableCell>Fecha OK Supplier</TableCell>
                <TableCell>Docs al Banco?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!props.isLoading && (props.orders?.length > 0 ? props.orders?.map((order, i) =>
                 <TableRow key={order.id}>
                  <TableCell>{order.product.name}</TableCell>
                  <TableCell>{order.quantity} TM</TableCell>
                  <TableCell><Link href="#" onClick={showOrderDetail.bind(this, order)}>{order.id}</Link></TableCell>
                  <TableCell>{new Intl.NumberFormat('es-MX',  {minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(order.finalAmount)} {order.product?.currency} / {order.product?.priceUnit?.symbol}</TableCell>
                  <TableCell>
                    <p><Link href='#' onClick={() => showOrderPayment(order, 'invoice')}>Payment</Link></p>
                    <Link href='#' onClick={() => showOrderPayment(order, 'packing')}>Packing</Link>
                  </TableCell>
                  <TableCell>
                    {new Date(order.creationDate._seconds * 1000).toLocaleDateString() + " " + 
                      new Date(order.creationDate._seconds * 1000).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    {sendEnabled ?
                      <FormControlLabel control={<Checkbox id={order.id} checked={checkedValues[order.id] || false}
                                                           onChange={handleChange}/>} label=''/>
                      :
                      <HourglassBottomIcon/>
                    }
                  </TableCell>
                </TableRow>):
                <TableRow><TableCell>Sin operaciones</TableCell></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {props.isLoading && <LinearProgress/>}
        {sendEnabled &&
          <>
            <p>NOTA: <br/> Has marcado las operaciones: <br/></p>
            {idOrders?.length>0 &&
              <>
                <ul>{idOrders.map((idOrder, i) => <li key={'li-order'+ i}>{idOrder}</li>)}</ul>
                las cuales enviarán factura y packing list al banco que nos indiques.
              </>
            }
          </>
        }
        {sendEnabled &&
        <ModalDocsBank
          showDialog={showDialog}
          setShowDialog={setShowDialog}
          setIsLoadingPendingOrders={setIsLoadingPendingOrders}
          getPendingOrders={getPendingOrders}
          getSentOrders={getSentOrders}
          checkedValues={checkedValues}
          setCheckedValues={setCheckedValues}
          idOrders={idOrders}
          setIdOrders={setIdOrders}
          
        />}
        {currentOrder && 
          <ModalDetailOrder
            showOrder={showOrder}
            currentOrder={currentOrder}
            setShowOrder = {setShowOrder}
            closeModal = {closeDetailOrder}
          />}
        {currentOrder &&
          <ModalDetailPayment
            showOrder={showDetailPayment}
            typeDocs={typeDocs}
            currentOrder={currentOrder}
            setShowOrder = {setShowDetailPayment}
            closeModal = {closeDetailPayment}
            showDialogLiquitationDocs = {showDialogLiquitationDocs}
          />
        }
        {docs &&
            <ModalLiquitationDocs
                open={showLiquitationDocs}
                docs={docs}
                setShowOrder = {setShowLiquitationDocs}
                closeModal = {() => setShowLiquitationDocs(false)}
            />
        }
      </CardContent>
    </Card>
  </Fragment>
}

export default PaymentPending;
