import { Fragment, useEffect, useState } from "react";
import { LinearProgress, ImageList, ImageListItem, Button } from "@mui/material";
import firebaseApp from '../../firebase/firebaseApp';
import { makeStyles } from '@mui/styles';
import Categories from "./CategoriesV2";
import { getFunctions, httpsCallable } from 'firebase/functions';

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const useStyles = makeStyles((theme) => ({
    imageButton: {
        cursor: "pointer",
        transition: 'opacity 0.2s',
        '&:hover': {
            opacity: '80%',
            
        },
    },
    imageButtonDisabled: {
        cursor: "not-allowed",
        '& img': {
            filter: 'grayscale(100%)',
            opacity: '50%',
        }
    },
    categoryContainer: {
        position: "relative",
        textAlign: "center",
        cursor: "pointer"
    },
    catTitle: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "2rem",
        fontWeight: "bold"
    },
    categoryImage: {
        maxWidth: '100%',
        opacity: '70%'
    }
}));

const functions = getFunctions(firebaseApp)

const Families = (props) => {
    const [loading, setLoading] = useState(true);
    const [families, setFamilies] = useState([]);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [showedEntity, setShowedEntity] = useState('fam');
    const classes = useStyles();

    useEffect(() => {
        const fetchFamilies = () => {
            const listFamilies = httpsCallable(functions, 'listGeneralFamilies');
            listFamilies().then(result => {
                setFamilies(result.data);
                setLoading(false);
            });
        }
        fetchFamilies();
    }, []);
    
    const selectImage = (fam, e) => {
        if(fam.tagFamily === "BO" || fam.tagFamily === "VA") return;
        setSelectedFamily(fam);
        setShowedEntity('cat');
    }

    const goBackHandler = () => {
        setShowedEntity('fam');
    }

    return <Fragment>
        {loading && <LinearProgress/>}
        {!loading && showedEntity==='fam' && <ImageList cols={3} gap={100}>
                {families.map(fam => {
                    return <ImageListItem key={fam.image} onClick={selectImage.bind(this, fam)} className={fam.tagFamily === "BO" || fam.tagFamily === "VA" ? classes.imageButtonDisabled : classes.imageButton}>
                        {fam.image && <img src={`${STORAGE_BASE_URL}${BUCKET_NAME}/${fam.image}`} alt={fam.image} />}
                    </ImageListItem>
                })}
            </ImageList>}
        {!loading && showedEntity==='cat' && !!selectedFamily.categories && <Categories goBackHandler={goBackHandler} family={selectedFamily} />}
        {!loading && showedEntity==='cat' && !!!selectedFamily.categories && <Button onClick={goBackHandler} fullWidth>Regresar</Button>}
     </Fragment>
}

export default Families;
