import {Fragment, useEffect, useState} from "react";
import firebaseApp from "../firebase/firebaseApp";
import {Button, Card, CardContent, CardHeader, Container, Divider, Grid, LinearProgress, Paper,} from "@mui/material";
import IconButton from "@mui/material/IconButton";
/*import AddIcon from '@mui/icons-material/Add';
import {useSelector} from "react-redux";*/
import FormEvent from "../components/events/FormEvent";
import ListEvents from "../components/events/ListEvents";
import SubHeader from "../components/common/SubHeader";
import {getFormatDateObject} from '../util/util';
import format from "date-fns/esm/format"
import { getFunctions, httpsCallable } from 'firebase/functions';
import Typography from '@mui/material/Typography';

const dateCurrent = new Date();

const functions = getFunctions(firebaseApp)

const Events = () => {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [newEvent, setNewEvent] = useState(false);
/*
    const userAdmin = useSelector(state => state.user.admin);
*/
    
    const fetchData = async () => {
          const listEvents = httpsCallable(functions, 'listEvents');
          const eventsData = await listEvents();
          const isEventActive = [];
          const isEventDesactive = [];
          eventsData.data.forEach(item => {
                const dateStart = new Date(getFormatDateObject(item.dateStart, 'en'));
                dateCurrent < dateStart
                  ? isEventActive.push({...item, dateStart: format(dateStart, "dd/MM/yyyy")})
                  : isEventDesactive.push({...item, dateStart: 'Por definir'})
            }
          )
          setEvents([...isEventActive, ...isEventDesactive]);
          setIsLoading(false);
      }

    useEffect(() => {
        fetchData();
    }, [])

/*    const showCreateEventHandler = () => {
        setNewEvent(true);
    }*/

    const closeCreateEventHandler = async (newEvent) => {
        if (newEvent)
        {
            setIsLoading(true);
            const addEvent = await httpsCallable(functions, 'addEvent');
            await addEvent(newEvent);
            await fetchData();
            setIsLoading(false);
        }
        setNewEvent(false);
    }

    const updateEventHandler = (eventInfo) => {
        if (eventInfo)
        {
            const updatedEvents = updateEvents(eventInfo);
            setEvents(updatedEvents);
        }
    }

    const updateEvents = (updatedEvent) => {
        const listEvents = events.map(eventInfo => {
            const newEvent = {
                ...eventInfo,
                city: {...eventInfo.city, country: {...eventInfo.city.country}},
            }
            return newEvent;
        })

        const indexEvent = listEvents.findIndex(a_event => a_event.id === updatedEvent.id);
        if (indexEvent > -1)
        {
            listEvents[indexEvent] = {
                ...updatedEvent
            }
        }

        return listEvents;
    }

    const deleteEventHandler = (eventToDelete) => {
        if (eventToDelete)
        {
            const listEvents = events.map(eventInfo => {
                return {
                    ...eventInfo,
                    city: {...eventInfo.city, country: {...eventInfo.city.country}},
                };
            })

            const filteredEvents = listEvents.filter(e => e.id !== eventToDelete.id);
            setEvents(filteredEvents);
        }
    }

    return <Fragment>
        <SubHeader title="asia / ferias / proximas"/>
        <Container fixed>
            <Grid item xs={12} md={12}>
                <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
                    <Typography>En esta sección encontrarás las principales ferias comerciales y de negocios que tienen lugar en los principales mercados de Asia y otras partes del mundo.
                        <br/>Además, podrás solicitar información y recibir asesoría de nuestro equipo de especialistas para separar un lugar en la feria de tu elección. Haz click en las ferias de tu interés para recibir más información.
                    </Typography>
                    <br/>
                </Paper>
            </Grid>
            <Card>
                <CardHeader title="Próximas Ferias" action={
                <IconButton aria-label="settings" onClick={() => console.log('Enviando info')}>
                    <Button color='primary' variant='contained'>Solicitar información</Button>
                </IconButton>
                }/>
                <Divider/>
                <CardContent>
                    {newEvent && <FormEvent onCloseEvent={closeCreateEventHandler}/>}
                    {isLoading && <LinearProgress />}
                    {!isLoading && <ListEvents events={events} onUpdateEvent={updateEventHandler}
                                               onDeleteEvent={deleteEventHandler}/>}
                </CardContent>
            </Card>
        </Container>
    </Fragment>
}

export default Events;
