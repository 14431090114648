import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "30px"
    },
    label: {
        color: theme.palette.primary.dark,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    value: {
        borderBottomStyle: "dotted",
        borderBottomWidth: "1px",
        paddingBottom: "5px"
    }
}));

const InfoDescription = (props) => {
    const classes = useStyles();
    return <section className={classes.root}>
        <p className={classes.label}>{props.label}</p>
        <p className={classes.value}>{props.value}</p>
    </section>
}

export default InfoDescription;