import {CircularProgress, TextField, Autocomplete} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {Fragment, useState} from "react";
import firebaseApp from "../../firebase/firebaseApp";
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles((theme) => ({
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2)
    }
}));

const functions = getFunctions(firebaseApp)

const CityInput = (props) => {
    const [cities, setCities] = useState([]);
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    let timeoutId = 0;

    const searchCityHandler = async (textCity) => {
        props.onSetCity(null);
        if (textCity.length >= 3) {
            setLoading(true);
            const listCities = await httpsCallable(functions, 'listCities');
            const cityData = await listCities({name: textCity});
            setCities(cityData.data);
            setLoading(false);
        }
    }

    const selectCityHandler = (e, v, r) => {
        props.onSetCity(v);
    }

    return <Autocomplete
        freeSolo
        disableClearable
        getOptionLabel={(option) => `${option.name} (${option.country.name})`}
        options={cities.map((city) => city)}
        value={props.city}
        renderInput={(params) => (
            <TextField
                {...params}
                label="Ciudad"
                margin="normal"
                InputProps={{...params.InputProps, type: 'search', endAdornment: (
                        <Fragment>
                            {params.InputProps.endAdornment}
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        </Fragment>
                    )}}
                className={classes.textField}
                required
                error={props.errorCity}
                helperText={props.errorCity && 'Especifique una ciudad válida'}
            />
        )}
        onInputChange={async (event, value, reason ) => {
            if (reason === 'input' && value.length >= 3)
            {
                if (timeoutId !== 0)
                {
                    clearTimeout(timeoutId);
                }
                timeoutId = setTimeout(async () => {await searchCityHandler(value)}, 500);
            }
        }}
        onChange={selectCityHandler}
        disabled={props.disabled}
    />
}

export default CityInput;
