import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, TextField, LinearProgress } from "@mui/material";
import firebaseApp from '../../firebase/firebaseApp';
import {useSelector} from "react-redux";
import { useState } from "react";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const TechnicalSupport = (props) => {
    const name = useSelector(state => state.user.name);
    const email = useSelector(state => state.user.email);
    const companyName = useSelector(state => state.user.companyName);

    const [comment, setComment] = useState('');
    const [sending, setSending] = useState(false);

    const changeCommentHanlder = (e) => {
        setComment(e.target.value);
    }

    const sendQuestionHandler = () => {
        setSending(true);
        const sendMessage = httpsCallable(functions, 'sendMessage');
        sendMessage({
            to: ['support@alaxs.zendesk.com', 'rmx@alaxs.com'],
            cc: email,
            subject: 'Mensaje de Cliente desde MyALAXS',
            replyTo: email,
            html: `<p>Empresa: ${companyName}</p>
                <p>Usuario: ${name}</p>
                <p>Asunto: Soporte Tecnico</p>
                <p>Descripción:</p>
                <p>${comment}</p>
                `
        }).then(() => {
            setComment('');
            setSending(false);
            props.handleClose();
        });
        
    }

    return <Dialog open={props.open} fullWidth={true} maxWidth='sm'>
        <DialogTitle>Otros Productos</DialogTitle>
        <DialogContent>
            <DialogContentText>
                Especifique su consulta técnica:
            </DialogContentText>
            <TextField multiline fullWidth rows={5} onChange={changeCommentHanlder}></TextField>
            {sending && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose} disabled={sending}>Cancelar</Button>
            <Button onClick={sendQuestionHandler} disabled={sending}>Enviar</Button>
        </DialogActions>
    </Dialog>
}

export default TechnicalSupport;