import { getAuth, signOut } from "firebase/auth";
import firebaseApp from '../../firebase/firebaseApp';
import {Redirect} from "react-router-dom";
import {useDispatch} from 'react-redux';
import {logout} from '../../store/userSlice';

const Logout = () => {
    const dispatch = useDispatch();
    console.info("Quitando autenticacion del usuario2")
    const auth = getAuth(firebaseApp);
    signOut(auth);
    dispatch(logout());
    console.info("Redireccionando a login2")
    return <Redirect to="/login" />

}

export default Logout;
