import {
  Autocomplete,
  CircularProgress,
  TextField,
  Grid,
  Tooltip,
  IconButton,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {useState, useEffect} from 'react';
import firebaseApp from '../../firebase/firebaseApp'
import { getFunctions, httpsCallable } from 'firebase/functions'

const functions = getFunctions(firebaseApp)

const SearchCatalog = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [code, setCode] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);
  
  const getOptions = async(value, optionsCustom) => {
    if(optionsCustom.length === 0){
      setLoading(true);
      const listCustomsByName = httpsCallable(functions, 'listCustomsByName');
      const customData = await listCustomsByName({name: value});
      const dataCode = customData.data.filter(e => e.code.length === 6);
      setOptions([...dataCode]);
    }
    return null;
  }
  
  useEffect(() => {
    if(inputValue.length === 3){
      getOptions(inputValue, options);
    }
    if(inputValue.length < 3 && options){
      setOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[inputValue])

  useEffect(() => {
    if(options.length > 0){
      setLoading(false);
    }
  }, [options])
  
  const onSelectedCode = (event, option) => {
    if(option){
      setCode(option.code);
    }
  }
  
  return <Grid container spacing={0}>
    <Grid item md={9}>
      <Autocomplete
        freeSolo
        id="asynchronous-demo"
        open={open}
        onOpen={() => {setOpen(true);}}
        onClose={() => {setOpen(false);}}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        getOptionLabel={(option) => option.name}
        onChange={(e, option) => onSelectedCode(e, option)}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label="¿Qué estás buscando?"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Grid>
    <Grid item xs={12} md={3}>
      <TextField fullWidth id={'code'} label={'Partida arancelaria'} value={code} onChange={(e) => setCode(e.target.value)}
                 helperText="(opcional)"
        InputProps={{
          endAdornment: (
            <div><Tooltip title="Si no tienes la partida arancelaria, el equipo ALAXS se comunicará contigo">
            <IconButton><InfoOutlinedIcon /></IconButton>
            </Tooltip></div>
          )
          }
        }
      />
    </Grid>
  </Grid>
}

SearchCatalog.defaultProps = {
  className: ''
}

export default SearchCatalog;
