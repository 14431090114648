import { login, logout, setError } from "./authSlice";
import { getAuth, signInWithEmailAndPassword, signOut, createUserWithEmailAndPassword } from 'firebase/auth';
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {userLogin} from "./userActions";

const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp)

export const loginUser = (payload) => {
    return async (dispatch) => {
        const loginUserAsync = async () => {
            const userCredential = await signInWithEmailAndPassword(auth, payload.email, payload.password);
            return userCredential.user;
        };

        try {
            dispatch(setError({existError: false, message: ''}));
            const userData = await loginUserAsync();
            if (userData) {
                dispatch(login());
            } else {
                dispatch(setError({existError: true, message: 'El usuario no puede ser autenticado'}))
            }
        }
        catch (error) {
            dispatch(setError({existError: true, message: error.message}));
        }
    }
}

export const logoutUser = (payload) => {
    return async (dispatch) => {
        const logoutUserAsync = async () => {
            await signOut(auth);
        }

        try {
            localStorage.removeItem('user');
            localStorage.removeItem('company');
            dispatch(logout());
            await logoutUserAsync();
            console.log('User has been logout')
        }
        catch (error){
            dispatch(setError({existError: true, message: error.message}));
        }
    }
}

export const registerUser = (payload) => {
    const {email, password, role} = payload;
    return async (dispatch) => {
        const registerUserAsync = async () => {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            return userCredential.user;
        };

        try {
            const userData = await registerUserAsync();
            if (userData) {
                const createUserInfo = httpsCallable(functions, 'createUserInfo');
                await createUserInfo({email, role});
            }
            dispatch(userLogin({email, password}))
        }
        catch (error) {
            dispatch(setError({existError: true, message: error.message}));
        }
    }
}
