import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import store from './store/store';
import Loading from './components/common/Loading';

ReactDOM.render(
  <React.StrictMode>
      <BrowserRouter>
        <Provider store={store}>
          <Suspense fallback={<Loading/>}>
            <App />
          </Suspense>
      </Provider>
      </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
