import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Card,
    CardContent,
    Collapse, ListItem, ListItemIcon, ListItemText,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {makeStyles} from "@mui/styles";
import CheckIcon from '@mui/icons-material/Check';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(9),
    }
}));

const FaqInfo = () => {
    const classes = useStyles();
    return <Card>
        <CardContent>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">1. ¿QUÉ SERVICIOS PRINCIPALES CONTIENE MYALAXS.COM?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>Son varios los servicios que les ofrecemos vía el módulo “MyALAXS”; básicamente se
                            estructuran en cinco (05) servicios principales:</Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText primary="Relacionamiento Comercial" />
                            </ListItem>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary="En este módulo está la conectividad con el Asia; podrán colocar pedidos (compras), así como ofertar productos (ventas)." />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary="Podrán tomar contacto con nosotros a fin de ver las diversas compañías con las cuales interactuamos, podrán validar proveedores, entre otras cosas" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary="Podrán ver los diversos eventos que durante todo el año se tienen en el Asia y próximamente en otras partes del mundo" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary" /></ListItemIcon>
                                <ListItemText>Partnerships</ListItemText>
                            </ListItem>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Podrán coordinar con los partners suscritos a nuestra plataforma; ver los servicios que se ofrecen y la conveniencia de éstos" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Estos partners cubren toda la cadena de valor; hay partners que ya se muestran en nuestra plataforma y otros que están en proceso de negociación e interacción digital" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="No es necesario comprar o vender algo a través nuestro a fin de realizar transporte alguno con nosotros; el transporte es independiente de cualquier servicio" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary="Asimismo, la validación de proveedores o productos o cualquier servicio; los servicios son independientes y se pueden tomar en conjunto o el que cada cliente desee" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText>Destino Asia</ListItemText>
                            </ListItem>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Podrán solicitar la opción de visitar el Asia; la visita requiere el soporte de nuestros aliados por lo que se requiere tiempo para coordinar la misma" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Las visitas pueden ser para asistir a ferias, congresos, así como visitar proveedores, fábricas y otros ligados a su modelo de negocio, expertise, requerimientos; realizar visitas técnico-comerciales" />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText>Reportes</ListItemText>
                            </ListItem>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Existen a la fecha muchas opciones de reportes en el módulo de MyALAXS; éstos están distribuidos por todo el módulo y son de fácil acceso a un solo click de nuestros clientes" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Sobre comercio exterior global, pueden acceder al Think Tank (en la parte alta) el cual tiene reportes de todo tipo para optimizar la gestión y datos comerciales que ayuden a su empresa; existen reportes de data histórica de trading (bajo la pestaña de business intelligence), así como proyecciones de mercado que buscan ayudar a su empresa en la toma de decisiones a futuro (esos datos están en la pestaña de market analysis)" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Una vez hayan realizado búsquedas, consultas de equis productos o compra/ventas en el Asia, el sistema reflejará las mismas en la pestaña “reportes” de la sección trading; en este reporte podrán tener el comparativo de empresas similares a la suya" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Asimismo, una vez hayan realizado viajes, visitas, validaciones de proveedores o productos, etc., Uds. podrán ver esos registros en nuestro sistema y verán los comparativos con empresas similares" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Por último, una vez complete su registro en nuestro sistema (empresa, sectores, bienes y servicios), Uds. podrán ver cuántas empresas similares a la suya existen en el mercado y -a través de nuestro sistema- poder saber más de las oportunidades, estrategias, capacidades y otros puntos en común con estas empresas; una vez se abra el módulo de SAW les avisaremos a fin de que puedan ver qué activos pueden Uds. intercambiar con las otras empresas." />
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText>Soporte Diverso</ListItemText>
                            </ListItem>
                            <Collapse in={true} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Desde el módulo MyALAXS pueden revisar y consultar aspectos legales y contables que aplican a sus productos, compras, pagos, transporte, etc." />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Pueden ponerse en contacto con nosotros de manera inmediata y a cualquier hora a través de nuestro helpdesk (en la parte superior del módulo); este helpdesk está subdividido por temas y motivos a fin de poder entender mejor el contacto de Uds." />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Podrá acceder y entender el network de oficinas y contactos que tenemos a nivel global; estas oficinas podrán ayudarle a expandir mercados, así como mejorar sus adquisiciones; en ese proceso estamos para ayudarle" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Asimismo, podrá ver las oportunidades en capacitaciones que se tendrán durante el año (tanto presencial como vía digital; training en su país, en la región latinoamericana y en otras partes del mundo)" />
                                    </ListItem>
                                    <ListItem className={classes.nested}>
                                        <ListItemIcon><CheckIcon color="primary"/></ListItemIcon>
                                        <ListItemText primary="Por último, en la pestaña ‘busco innovación’ podrán ver los últimos avances registrados en nuestra plataforma, así como averiguar por innovaciones puntuales que se ajustan a su modelo de negocio" />
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">2. ¿QUÉ TIPO DE COMPRAS PUEDO REALIZAR?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>De todo tipo y que se ajuste a sus necesidades. En la sección compras tenemos tres (03) niveles para que -dependiendo de su tipo y volumen de compra- pueda Ud. elegir qué le acomoda; estos niveles son:</Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText primary="Compras por Catálogo" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary" /></ListItemIcon>
                                <ListItemText primary="Compras anuales recurrentes" />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><ArrowForwardIcon color="primary"/></ListItemIcon>
                                <ListItemText primary="Proyectos / licitaciones"/>
                            </ListItem>
                        </List>
                        <Typography>Cada sección responde a un interés diferente, a una propuesta de valor distinta, así como a un tipo de necesidad y giro de negocio muy particular. Los dos últimos niveles tienen un diseño similar; la diferencia está en el seguimiento y soporte que nosotros daremos detrás de cada nivel. En todo nivel y en toda compra, Uds. contarán con el soporte de ALAXS Analytics Engine (AAE) quien en todo momento está actuando y revisando las mejores opciones para Uds. y su empresa. Cualquier consulta nos la puede hacer vía el helpdesk.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">3. ¿QUÉ TIPO DE VENTAS PUEDO REALIZAR?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>De todo tipo y que se ajuste a su oferta de negocios. El módulo de ventas aún no está 100% activado; se debe activar en pocas semanas; les estaremos avisando de manera oportuna. Es clave que para ofertar y vender equis productos, su empresa haya completado el módulo de registro respectivo y haya indicado claramente qué productos comercializa.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">4. ¿DÓNDE PUEDO VER A LAS EMPRESAS SIMILARES A LA MIA? ¿PUEDO IDENTIFICAR A MIS PARES LATINOAMERICANOS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>En la pestaña “empresas similares” de la sección soporte. Para que Uds. puedan ver cómo está su empresa en comparación con empresas similares de la región latinoamericana y tener ventajas de esta integración, es clave que la data de su empresa esté completa y sea lo más ajustada a la realidad posible. Caso contrario no podrá entender cómo están desenvolviéndose el resto de las empresas similares a la suya, así como el crecimiento y acciones comerciales que éstas van teniendo con el mercado asiático.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">5. ¿HE LLENADO ALGUNOS DATOS DE MI EMPRESA EN LA WEB DE REGISTRO INICIAL, DÓNDE PUEDO COMPLETAR MI REGISTRO?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>En la parte alta a la derecha del Módulo MyALAXS están sus iniciales; le da un click a éstas y se abrirá una pequeña ventana donde podrá ver su perfil, actualizar sus datos, ver los pagos a los organismos asiáticos a los cuales nos adherimos, así como cambiar su password o salir del sistema. En la pestaña de ‘Actualizar Mis Datos’ podrá completar los mismos o ajustar alguno vigente a la nueva realidad de su empresa. La sección es bastante amigable y fácil de entender. Cuanto más completos y veraces estén sus datos, más lo podremos guiar y ayudar.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">6. QUIERO VER MIS DATOS Y/O CAMBIAR MI CONTRASEÑA, ¿DÓNDE LO PUEDO HACER?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>Al igual que la pregunta anterior; en la parte alta a la derecha del Módulo MyALAXS están sus iniciales; le da un click a éstas y se abrirá una pequeña ventana donde podrá ver su perfil, actualizar sus datos, ver los pagos a los organismos asiáticos a los cuales nos adherimos, así como cambiar su password o salir del sistema. En la pestaña de ‘Mi Perfil’ o la de ‘Cambiar Password’ podrá revisar y modificar sus datos como Uds. lo consideren.
                            Ambas secciones son bastante amigables y fáciles de entender. Cuanto más completos y veraces estén sus datos, más lo podremos guiar y ayudar.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">7. ¿CUÁNTO CUESTA ESTE SERVICIO AL AÑO? ¿CÓMO SE PAGA? ¿PUEDO PAGAR POR PARTES?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>En nuestro portal digital no se paga ningún fee anual por inscripción, ni monto alguno ‘in-advance’. ALAXS.com genera eficiencias en la gestión de sus clientes y en las negociaciones con diversos proveedores y partners; es de ese dinero que generamos nuestra participación.
                            Hay servicios que tienen pagos puntuales (viajes, entrenamientos, hospedajes, validaciones, etc.) y esos importes serán oportunamente informados a las empresas interesadas a fin de que tomen sus mejores decisiones.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">8. ¿PUEDO INVITAR A EMPRESAS AMIGAS?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>Siempre que Uds. lo deseen pueden participar nuestros servicios a empresas amigas; en la medida que más empresas sean parte de nuestros servicios, más beneficios podremos encontrar para Uds.; basta que les haga llegar el link de nuestra web a empresas amigas [https://www.alaxs.com] para que siguiendo el mismo proceso de Uds., puedan acceder a nuestros servicios y beneficios.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">9. ¿DÓNDE ESTÁN LAS OFICINAS DEL NETWORK QUE UDS. INDICAN?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>Estas oficinas están alrededor del mundo; están en ciudades y países muy conocidos y otros no tanto; el contacto con nosotros es justamente para eso, para facilitar su experiencia y contactos con localidades que requieran mayor conocimiento de la cultura, idiosincrasia de esa determinada zona.
                            El detalle de todo este network lo puede encontrar en nuestra web ALAXS.com y en el módulo MyALAXS en la sección soporte.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>} >
                    <Typography variant="button" color="primary">10. ¿PUEDO ACCEDER AL MÓDULO MYALAXS Y LA WEB MYALAXS.COM DE MANERA DIRECTA?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <Typography>No, no se puede; para acceder al módulo de MyALAXS sólo se puede hacer a través de nuestra web principal, https://www.alaxs.com. Luego de registrarse basta un click y ya estará en contacto con el Asia a fin de explorar muchas oportunidades para su compañía.</Typography>
                    </div>
                </AccordionDetails>
            </Accordion>
        </CardContent>
   </Card>
}

export default FaqInfo;
