import {
  Box,
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Paper,
  Stack,
  Typography
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import {Link} from 'react-router-dom';
import {
  EmojiObjectsOutlined,
  ZoomOutMapOutlined,
  FactCheckOutlined,
  BalanceOutlined,
  LocalShippingOutlined
} from '@mui/icons-material';
import location from '../img/location-white.svg';
import Share from '../components/common/Share';

const useStyles = makeStyles((theme) => ({
  main: {
    height: 'calc(100vh - 96px)',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    height: '100%',
    display: 'flex !important',
    flexFlow: 'column nowrap',
  },
  buttonIcon: {
    borderRadius: '50%',
    backgroundColor: '#ff3036 !important',
    width: '86px',
    height: '86px',
    padding: 0,
    color: 'white !important',
    marginBottom: '14px !important',
    '& img': {
      height: '50px'
    }
  },
  disabled: {
    '& a': {
      backgroundColor: '#FFB9B6 !important',
      pointerEvents: 'none',
    },
    '& span': {
      color: 'gray'
    }
  }
}));

const Search = () => {
  const classes = useStyles();
  return <div style={{marginTop: '-24px'}} className={classes.main}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Typography>asia </Typography>
      <Typography>búsqueda</Typography>
    </Breadcrumbs>
    <Container fixed className={classes.container}>
      <Grid container spacing={2} sx={{marginBottom: 'auto'}}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <Typography>Esta sección es para necesidades varias que su empresa pueda buscar en el Asia.<br/>
              Deseamos que puedan encontrar en nosotros soporte directo en temas puntuales.<br/>
              ¿En qué lo podemos ayudar?
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <Stack direction="row" spacing={12}>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/innovation">
                  <EmojiObjectsOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Innovación</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/network">
                  <ZoomOutMapOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Network</Typography>
              </Box>
              <Box className={classes.disabled}>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/search/visits">
                  <img src={location} alt="location"/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Visitas</Typography>
              </Box>
            </Stack>
          </Box><br/><br/>
          <Box sx={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}>
            <Stack direction="row" spacing={12}>
              <Box className={classes.disabled}>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/search/validacion-proveedores">
                  <FactCheckOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Validación de <br/>proveedores <br/>y productos</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/search/soporte-legal-contable">
                  <BalanceOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Soporte Legal <br/> y contable</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/search/soporte-logistico">
                  <LocalShippingOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Soporte <br/>Logístico</Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Share variant="fixed"/>
    </Container>
  </div>
}

export default Search;