import React, {useEffect, useState} from "react";
import {useParams, Link} from "react-router-dom";
import {
  Breadcrumbs, CircularProgress,
  Container, Button
} from "@mui/material";
import Typography from '@mui/material/Typography';
import firebaseApp from '../firebase/firebaseApp';
import {getLocacion, pathImageStorage, formatDate} from '../components/thinktank/utils';
import MenuThinktank from '../components/thinktank/menuThinktank';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {useSelector} from 'react-redux';

const functions = getFunctions(firebaseApp)

const Post = () => {
  const { id } = useParams();
  const [post, setPost] = useState([]);
  const {contenido, fuente, image, fecha, title, locacion, tematica} = post;
  const [isLoading, setIsLoading] = useState(false);
  const [thematic, setThematic] = useState('');
  const listThematics = useSelector(state => state.post.listThematics)
  
  useEffect(() => {
    window.scrollTo(0,0);
    setIsLoading(true);
    const fetchPosts = async () => {
      const getPost = httpsCallable(functions, 'getPost');
      const {data} = await getPost({id});
      setPost(data);
      setIsLoading(false)
    }
    fetchPosts();
  }, [id]);
  
  useEffect(() => {
    if(listThematics.length > 0 && tematica){
      const name = listThematics.filter(e => e.order === parseInt(tematica));
      setThematic(name[0].name);
    }
  },[listThematics, tematica])
  
  return <div className='think-tank' style={{marginTop: '-24px', background: '#f4f4f4'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Link to="/think-tank">THINK TANK</Link>
      <Typography>POST</Typography>
    </Breadcrumbs><br/><br/>
    <Container>
      <MenuThinktank/>
      <div className='think-tank item-post'>
        {isLoading ? <CircularProgress/> :
        <article className='post-inner-area'>
          <h1>{title}</h1>
          <div className="all-flex">
            <div className="post-inner-data flex">
              <div className="post-inner-user">
              </div>
              <div className="post-inner-username">
                <span className="post-author-times" itemProp="name">
                  Fuente: {fuente} | Temática: {thematic} | Locación: {getLocacion(locacion)}
                </span>
                <div className="post-times">
                  <span className="post-date published">Fecha: {formatDate(fecha)}</span>
                </div>
              </div>
            </div>
            {/*<div className="post-inner-comments flex">
              <span className="comment-bubble show">4</span>
              <div className="share-top"><i className="fas fa-share-alt"></i></div>
            </div>*/}
          </div>
          <div className="post-body entry-content" id="postBody">
            <div id="bottom-ad-placeholder"></div>
            <div style={{textAlign: "center"}}>
              <img border="0" alt={'img8'} className="post-image" src={pathImageStorage + image}/>
            </div>
            <div dangerouslySetInnerHTML={{__html: contenido}}/>
            <div style={{textAlign: "center"}}><br/></div>
          </div>
          <br />
          <div className="feed-share">
            <ul className="xxXio-share colorful-ico social-front-hover">
              <li className="facebook-f">
                <a className="facebook window-piki" data-height="650"
                                            href={"https://www.facebook.com/sharer.php?u=https://myalaxs.com/post/" + id}
                                            width="550" rel="nofollow"
                                            title="Facebook">Facebook</a></li>
              <li className="twitter">
                <a className="twitter window-piki" data-height="460"
                                         href={"https://twitter.com/intent/tweet?url=https://myalaxs.com/post/" + id + "&amp;text=" + title}
                                         data-width="550" rel="nofollow" title="Twitter">Twitter</a>
              </li>
              <li className="whatsapp">
                <a className="whatsapp window-piki" data-height="650"
                                          href={"https://api.whatsapp.com/send?text="+ title + " | https://myalaxs.com/post/" + id}
                                          data-width="550" rel="noreferrer" target="_blank"
                                          title="Whatsapp">Whatsapp</a>
              </li>
            </ul>
          </div>
          <Button component={Link} variant="outlined" color='success' to={'/think-tank'}><Typography>{'< Regresar'}</Typography></Button>
        </article>
        }
      </div>
    </Container>
    <br/>
  </div>
};

export default Post;
