import React, {useEffect, Fragment} from "react";
import {useDispatch, useSelector} from "react-redux";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns'
import Logout from "./components/auth/Logout";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { userComeBack } from "./store/userActions";
import {setMessageError} from './store/userSlice';
import {getCompany} from './store/CompanyActions';
import LayoutClient from "./components/layout/LayoutClient";
import Dashboard from "./pages/Dashboard";
import Login from "./components/auth/Login";
import NotDataYet from "./pages/NotDataYet";
import SelectPurchase from "./pages/SelectPurchase";
import NewBuying from "./pages/NewBuying";
import StillWorking from "./pages/Still-working";
import Events from "./pages/Events";
import Universities from "./pages/Universities";
import SearchInnovation from "./pages/SearchInnovation";
import Trading from "./pages/Trading";
import Suppliers from "./pages/Suppliers";
import Search from "./pages/Search";
import Profile from "./pages/Profile";
import UpdateProfile from "./pages/UpdateProfile";
import Zendesk from "./pages/Zendesk";
import Faq from "./pages/Faq";
import BiImport from "./pages/BiImport";
import BiExport from "./pages/BiExport";
import BiOpportunity from "./pages/BiOpportunity";
import BiRatio from "./pages/BiRatio";
import BiRevenue from "./pages/BiRevenue";
import BiProvider from "./pages/BiProvider";
import BiCustom from "./pages/BiCustom";
import Bi from "./pages/Bi";
import Videos from "./pages/Videos";
import Customs from "./pages/Customs";
import Industries from "./pages/Industries";
import Products from "./pages/Products";
import Naics from './pages/Naics'
import Auction from "./pages/Auction";
import Donation from "./pages/Donation";
import Sale from "./pages/Sale";
import Purchase from "./pages/Purchase";
import Marketplace from "./pages/Marketplace";
import CriticalSpare from "./pages/CriticalSpare";
import Catalog from "./pages/Catalog";
import Warehouse from "./pages/Warehouse";
import Assets from "./pages/Assets";
import LegalConduct from "./pages/LegalConduct";
import DataPrivacy from "./pages/DataPrivacy";
import TermsConditions from "./pages/TermsConditions";
import Principles from "./pages/Principles";
import Valores from "./pages/Valores";
import LoyaltyClubFaq from "./pages/LoyaltyClubFaq";
import LoyaltyConduct from './pages/LoyaltyConduct'
import LoyaltyClub from "./pages/LoyaltyClub";
import Post from './pages/Post'
import Home from "./pages/Home";
import { Switch, Route } from "react-router-dom";
import PostsByTag from "./pages/PostsByTag";
import Posts from "./pages/Posts";
import firebaseApp from './firebase/firebaseApp'
import ChangePassword from "./pages/ChangePassword";
import SuppliersByFamily from './pages/SuppliersByFamily';
import Target from './pages/Target';
import { useHistory } from "react-router-dom";
import Register from "./components/auth/Register";


const theme = createTheme({
    palette: {
        primary: {
            main: '#041DDD',
        },
        secondary: {
            main: '#D23036',
        },
    },
});


const auth = getAuth(firebaseApp)

function App() {
    const dispatch = useDispatch();
    const history = useHistory();
    const active = useSelector(state => state.user.active)
    const profile = useSelector(state => state.user.profile)
    const gettingUserInfo = useSelector(state => state.user.gettingUserInfo)
    const role = useSelector(state => state.user.role)
    
    useEffect(() => {
        onAuthStateChanged(auth, user => {
            if (user) {
                console.log('Se Obtiene información de usuario')
            //    dispatch(userComeBack({id: user.uid, email: user.email, emailVerified: user.emailVerified}));
            //    dispatch(getCompany());
            }
            if(user === null){
                history.push('/logout');
                let message = null;
                if (role === null && role !== "alaxs" && gettingUserInfo === null) {
                    message = ""
                }
                else if (active !== undefined && !active && gettingUserInfo === null) {
                    message = "Account not active. Your account has been deactivated."
                }
                else if (profile !== undefined && profile !== "administrator" && profile !== "operator" && profile !== "reader" && !gettingUserInfo) {
                    message = "No profile found. Your account does not have an associated profile."
                }
                dispatch(setMessageError({messageError: message}))
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <Fragment>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
            <Switch>
                <Route path="/" exact>
                    <LayoutClient><SelectPurchase/></LayoutClient>
                </Route>
                <Route path="/home" exact>
                    <Home/>
                </Route>
                <Route path="/login">
                    <Login/>
                </Route>
                <Route path="/logout">
                    <Logout />
                </Route>
                <Route path="/changepassword">
                    <ChangePassword />
                </Route>
                <Route path="/buying/process">
                    <LayoutClient><NotDataYet title="trading / compras / en proceso"/></LayoutClient>
                </Route>
                <Route path="/buying/historical">
                    <LayoutClient><NotDataYet title="trading / compras / historicas"/></LayoutClient>
                </Route>
                <Route path="/buying">
                    <LayoutClient><SelectPurchase/></LayoutClient>
                </Route>
                <Route path="/newbuying">
                    <LayoutClient><NewBuying/></LayoutClient>
                </Route>
                <Route path="/sale/new">
                    <LayoutClient><NotDataYet title="trading / ventas / Nueva"/></LayoutClient>
                </Route>
                <Route path="/sale/process">
                    <LayoutClient><NotDataYet title="trading / ventas / en proceso"/></LayoutClient>
                </Route>
                <Route path="/sale/historical">
                    <LayoutClient><NotDataYet title="trading / ventas / historicas"/></LayoutClient>
                </Route>
                <Route path="/partners">
                    <LayoutClient><StillWorking title="Partners"/></LayoutClient>
                </Route>
                <Route path="/banks">
                    <LayoutClient><StillWorking title="trading / partners / Bancos"/></LayoutClient>
                </Route>
                <Route path="/transportation">
                    <LayoutClient><StillWorking title="trading / partners / transportes"/></LayoutClient>
                </Route>
                <Route path="/insurance">
                    <LayoutClient><StillWorking title="trading / partners / seguros"/></LayoutClient>
                </Route>
                <Route path="/agents">
                    <LayoutClient><StillWorking title="trading / partners / agentes"/></LayoutClient>
                </Route>
                <Route path="/ferias/proximos">
                    <LayoutClient><Events/></LayoutClient>
                </Route>
                <Route path="/events/todos">
                    <LayoutClient><StillWorking title="asia / ferias / todos"/></LayoutClient>
                </Route>
                <Route path="/events/historical">
                    <LayoutClient><StillWorking title="asia / ferias / Históricos"/></LayoutClient>
                </Route>
                <Route path="/ferias">
                    <LayoutClient><Events /></LayoutClient>
                </Route>
                <Route path="/universities">
                    <LayoutClient><Universities/></LayoutClient>
                </Route>
                <Route path="/innovation">
                    <LayoutClient><SearchInnovation/></LayoutClient>
                </Route>
                <Route path="/target">
                    <LayoutClient><Target/></LayoutClient>
                </Route>
                <Route path="/trading">
                    <LayoutClient><Trading/></LayoutClient>
                </Route>
                <Route path="/suppliers/:id">
                    <LayoutClient><SuppliersByFamily/></LayoutClient>
                </Route>
                <Route path="/suppliers">
                    <LayoutClient><Suppliers/></LayoutClient>
                </Route>
                <Route path="/network">
                    <LayoutClient><StillWorking title="asia / búsqueda / network"/></LayoutClient>
                </Route>
                <Route path="/search/visits">
                    <LayoutClient><StillWorking title="asia / búsqueda / visitas"/></LayoutClient>
                </Route>
                <Route path="/search/validacion-proveedores">
                    <LayoutClient><StillWorking title="asia / búsqueda / validación de proveedores y productos"/></LayoutClient>
                </Route>
                <Route path="/search/soporte-legal-contable">
                    <LayoutClient><StillWorking title="asia / búsqueda / soporte legal y contable"/></LayoutClient>
                </Route>
                <Route path="/search/soporte-logistico">
                    <LayoutClient><StillWorking title="asia / búsqueda / soporte logístico"/></LayoutClient>
                </Route>
                <Route path="/search">
                    <LayoutClient><Search/></LayoutClient>
                </Route>
                <Route path="/profile">
                    <LayoutClient><Profile/></LayoutClient>
                </Route>
                <Route path="/updateprofile">
                    <LayoutClient><UpdateProfile/></LayoutClient>
                </Route>
                <Route path="/zendesk">
                    <LayoutClient><Zendesk /></LayoutClient>
                </Route>
                <Route path="/faq">
                    <LayoutClient><Faq /></LayoutClient>
                </Route>
                <Route path="/bi/imports">
                    <LayoutClient><BiImport/></LayoutClient>
                </Route>
                <Route path="/bi/exports">
                    <LayoutClient><BiExport/></LayoutClient>
                </Route>
                <Route path="/bi/opportunities">
                    <LayoutClient><BiOpportunity/></LayoutClient>
                </Route>
                <Route path="/bi/ratios">
                    <LayoutClient><BiRatio/></LayoutClient>
                </Route>
                <Route path="/bi/revenues">
                    <LayoutClient><BiRevenue/></LayoutClient>
                </Route>
                <Route path="/bi/providers">
                    <LayoutClient><BiProvider/></LayoutClient>
                </Route>
                <Route path="/bi/customs">
                    <LayoutClient><BiCustom/></LayoutClient>
                </Route>
                <Route path="/bi">
                    <LayoutClient><Bi/></LayoutClient>
                </Route>
                <Route path="/videos">
                    <LayoutClient><Videos/></LayoutClient>
                </Route>
                <Route path="/catalog/customs">
                    <LayoutClient><Customs/></LayoutClient>
                </Route>
                <Route path="/catalog/industries">
                    <LayoutClient><Industries/></LayoutClient>
                </Route>
                <Route path="/catalog/products">
                    <LayoutClient><Products/></LayoutClient>
                </Route>
                <Route path="/catalog/naics">
                    <LayoutClient><Naics/></LayoutClient>
                </Route>
                <Route path="/assets/marketplace/auction">
                    <LayoutClient><Auction/></LayoutClient>
                </Route>
                <Route path="/assets/marketplace/donation">
                    <LayoutClient><Donation/></LayoutClient>
                </Route>
                <Route path="/assets/marketplace/sale">
                    <LayoutClient><Sale/></LayoutClient>
                </Route>
                <Route path="/assets/marketplace/purchase">
                    <LayoutClient><Purchase/></LayoutClient>
                </Route>
                <Route path="/assets/marketplace">
                    <LayoutClient><Marketplace/></LayoutClient>
                </Route>
                <Route path="/assets/partnerships">
                    <LayoutClient><StillWorking parentUrl="/assets" parentTitle="Assets" title="latinoamerica / assets / partnerships"/></LayoutClient>
                </Route>
                <Route path="/assets/visits">
                    <LayoutClient><StillWorking parentUrl="/assets" parentTitle="Assets" title="latinoamerica / assets / visitas"/></LayoutClient>
                </Route>
                <Route path="/assets/warehouse/repuesto-critico">
                    <LayoutClient><CriticalSpare/></LayoutClient>
                </Route>
                <Route path="/assets/warehouse/catalog">
                    <LayoutClient><Catalog/></LayoutClient>
                </Route>
                <Route path="/assets/warehouse/planning">
                    <LayoutClient><StillWorking title="latinoamerica / assets / warehouse / planning"/></LayoutClient>
                </Route>
                <Route path="/assets/warehouse">
                    <LayoutClient><Warehouse/></LayoutClient>
                </Route>
                <Route path="/assets">
                    <LayoutClient><Assets/></LayoutClient>
                </Route>
                <Route path="/webinars">
                    <LayoutClient><StillWorking title="latinoamerica / webinars"/></LayoutClient>
                </Route>
                <Route path="/talent">
                    <LayoutClient><StillWorking title="latinoamerica / talento"/></LayoutClient>
                </Route>
                <Route path="/legal/conduct">
                    <LayoutClient><LegalConduct/></LayoutClient>
                </Route>
                <Route path="/legal/data-privacy">
                    <LayoutClient><DataPrivacy/></LayoutClient>
                </Route>
                <Route path="/legal/terms-conditions">
                    <LayoutClient><TermsConditions/></LayoutClient>
                </Route>
                <Route path="/principles">
                <LayoutClient><Principles/></LayoutClient>
                </Route>
                <Route path="/valores">
                <LayoutClient><Valores/></LayoutClient>
                </Route>
                <Route path="/loyalty-club/faq">
                    <LayoutClient><LoyaltyClubFaq/></LayoutClient>
                </Route>
                <Route path="/loyalty-club/conduct">
                    <LayoutClient><LoyaltyConduct/></LayoutClient>
                </Route>
                <Route path="/loyalty-club">
                    <LayoutClient><LoyaltyClub/></LayoutClient>
                </Route>
                <Route path="/post/:id">
                    <LayoutClient><Post/></LayoutClient>
                </Route>
                <Route path="/posts/:tag/:id">
                    <LayoutClient><PostsByTag/></LayoutClient>
                </Route>
                <Route path="/posts">
                    <LayoutClient><Posts/></LayoutClient>
                </Route>
                <Route path="/think-tank">
                    <LayoutClient><Posts/></LayoutClient>
                </Route>
                <Route path='/dashboard'>
                    <Dashboard/>
                </Route>
                <Route path='/register'>
                    <Register/>
                </Route>
                <Route>
                    <div>Página no encontrada</div>
                </Route>
            </Switch>
            </ThemeProvider>
        </LocalizationProvider>
    </Fragment>

}

export default App;
