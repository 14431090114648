import {Breadcrumbs, Container} from "@mui/material";
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

const Products = () => {
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>LATINOAMERICA </Typography>
            <Link to="/assets">assets</Link>
            <Link to="/assets/warehouse">warehouse</Link>
            <Link to="/assets/warehouse/catalog">Catálogos ALAXS</Link>
            <Typography>Productos</Typography>
        </Breadcrumbs>
        <Container style={{marginTop: '24px'}}>
            <span>Lista de Productos</span>
        </Container>
    </div>
}

export default Products;
