import styles from './ChangePassword.module.css'
import { useState, Fragment } from 'react';
import alaxs_logo from '../img/alaxs_logo.png';
import { getAuth, updatePassword, reauthenticateWithCredential, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from '../firebase/firebaseApp';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';


const ChangePassword = () => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const auth = getAuth(firebaseApp);
    const user = auth.currentUser;
    // const navigate = useNavigate();
    const loggedIn = useSelector(state => state.user.loggedIn);
    const history = useHistory()

    if (!loggedIn) {
        // navigate("/login", { replace: true });
        history.push('/login')
    }

    const submitChangePasswordHandler = async () => {
        if (password.length > 0 && passwordAgain.length > 0 && password === passwordAgain) {
            try {
                await updatePassword(user, password);
                // navigate("/", { replace: true });
                history.push("/")
            }
            catch (error) {
                if (error.code === 'auth/requires-recent-login') {
                    console.info('El usuario requiere un nuevo login')
                    const credentials = await signInWithEmailAndPassword(auth, user.email, currentPassword);
                    console.info('Se autenticó al usuario')
                    await reauthenticateWithCredential(user, credentials);
                    console.info('Se reautenticó al usuario')
                    updatePassword(user, password);
                    console.info('Se actualizó password')
                    // navigate("/", { replace: true });
                    history.push("/")
                }
                else {
                    setErrorMessage("The credentials are not valid.");
                    console.log(error.code, error.message);
                }
            }
        }
        else {
            setErrorMessage("The credentials are not valid.")
        }
    }

    const changePasswordHandler = (e) => {
        setPassword(e.target.value);
    }

    const changePasswordAgainHandler = (e) => {
        setPasswordAgain(e.target.value);
    }

    const changeCurrentPasswordHandler = e => {
        setCurrentPassword(e.target.value);
    }

    return <Fragment>
        <main className={styles.main}>
            <div className={styles.container}>
                <div className={styles.left} />
                <div className={styles.right}>
                    <div className={styles.logo}>
                        <img src={alaxs_logo} alt="Logo ALAXS" />
                    </div>
                    <div className={styles.controls}>
                        <h5>Cambiar clave</h5>
                        <input type="password" placeholder="Clave actual" className={styles.textinput} onChange={changeCurrentPasswordHandler} />
                        <input type="password" placeholder="Nueva clave" className={styles.textinput} onChange={changePasswordHandler} />
                        <input type="password" placeholder="Repita clave" className={styles.textinput} onChange={changePasswordAgainHandler} />
                        <span className={styles.error}>{errorMessage}</span>
                        <button className={styles.button} onClick={submitChangePasswordHandler}>Cambiar clave</button>
                    </div>
                </div>
            </div>
        </main></Fragment>
}

export default ChangePassword;