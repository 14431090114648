import GeneralInfo from './GeneralInfo';
import FamilyCategories from "./FamilyCategory";
import Supplier from './Supplier';
import PriceRanges from './PriceRanges';

const ProductInfo = ({product}) => {
    const {supplier} = product;

    return <>
        <GeneralInfo product={product} />
        <Supplier supplier={supplier} />
        <FamilyCategories product={product}/>
        <PriceRanges product={product} />
    </>
}

export default ProductInfo;
