import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import ProductInfo from '../products/ProductInfo';
import DialogActions from '@mui/material/DialogActions';
import {Button} from '@mui/material';
import Dialog from '@mui/material/Dialog';

const ModalDetailOrder = (props) => {
  const {showOrder, currentOrder, closeModal} = props;
  
  return (
    <Dialog open={showOrder}>
      <DialogTitle>Detalles de la Orden</DialogTitle>
      <DialogContent>
        <ProductInfo product={currentOrder.product} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="contained">Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDetailOrder;
