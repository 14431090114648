import {createSlice} from '@reduxjs/toolkit';

const companySlice = createSlice({
    name: 'company',
    initialState: {
        city: '',
        country: '',
        codeCompany: ''
    },
    reducers: {
        setCompany: (state, action) => {
            state.city = action.payload.company.city.name;
            state.country = action.payload.company.city.country.name;
            state.codeCompany = action.payload.company.code;
        }
    }
});

export const {setCompany} = companySlice.actions;
export default companySlice.reducer;
