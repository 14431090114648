import {Breadcrumbs, Container, Typography} from "@mui/material";
import Menu from '../components/catalogs/Menu';
import {Link} from 'react-router-dom';

const Catalog = () => {
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>LATINOAMERICA </Typography>
            <Link to="/assets">assets</Link>
            <Link to="/assets/warehouse">warehouse</Link>
            <Typography>Catálogos Alaxs</Typography>
        </Breadcrumbs>
        <Container style={{marginTop: '24px'}}>
            <Menu/>
        </Container>
    </div>
}

export default Catalog;
