import React, {useEffect, useState} from "react";
import TableCell from "@mui/material/TableCell";
import {Button, Link, TextField, Typography, Table} from "@mui/material";
import {TablePrices} from "../products/TablePrices";
import {format, parseISO} from "date-fns";
import TableRow from "@mui/material/TableRow";
import {DatePicker} from "@mui/x-date-pickers";

const RowNegotiationOrder = (props) => {
    const { order, showOrderDetail, quantity, i,currentQuarter, nextQuarter, lastQuarter, proxQuarter,
    handleModifyOrder, handleModalOrder, isDisabled, handleUpdateOrder} = props;
    const [volumenFinal, setVolumenFinal] = useState(order?.volumenFinal)
    const [priceFinal, setPriceFinal] = useState(order?.priceFinal);
    const [deliveryDate, setDeliveryDate] = useState(order?.deliveryDate ? parseISO(order?.deliveryDate) :  null);
    const [currentPrice, setCurrentPrice] = useState(null);

    useEffect(() => {
            if(volumenFinal && currentPrice){
                setPriceFinal(getPriceByVolumen(volumenFinal))
            }
    }, [volumenFinal, currentPrice])


    const getPriceByVolumen = (volumen) => {
        for (const key in currentPrice) {
            if (currentPrice[key].from !== undefined && currentPrice[key].to !== undefined) {
                const minCantidad = parseInt(currentPrice[key].from);
                const maxCantidad = parseInt(currentPrice[key].to === "+" ? 999999999 : currentPrice[key].to);
                const precio = parseFloat(currentPrice[key].price);
                if (volumen >= minCantidad && volumen <= maxCantidad) {
                    return precio;
                }
            }
        }
        return "---"; // En caso de que la cantidad no corresponda a ningún rango
    }


    return (
        <TableRow key={order.id}>
            <TableCell sx={{paddingTop: 0,paddingBottom: 0, verticalAlign: "top"}}>
                <Table>
                    <TableRow>
                        <TableCell>
                            <Typography variant="caption"><strong>Producto: </strong>
                                <Link href="#" onClick={showOrderDetail.bind(this, order)}>{order.product.name}</Link>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{paddingBottom: 0}}>
                            <Typography variant="caption"><strong>Mis Prox. Requerim. Trimestral con pedido: </strong>
                                {quantity[i]?.currentQuarterQuantity !== 0 &&
                                    <div>- Q{currentQuarter.q + '-' + currentQuarter.year + ': '}{quantity[i].currentQuarterQuantity} {order.product?.unit?.symbol}</div>
                                }
                                {quantity[i]?.nextQuarterQuantity !== 0 &&
                                    <div>- Q{nextQuarter.q + '-' + nextQuarter.year + ': '}{(quantity[i].nextQuarterQuantity).toFixed(2)} {order.product?.unit?.symbol}</div>
                                }
                                {quantity[i]?.lastQuarterQuantity !== 0 &&
                                    <div>- Q{lastQuarter.q + '-' + lastQuarter.year + ': '}{(quantity[i].lastQuarterQuantity).toFixed(2)} {order.product?.unit?.symbol}</div>
                                }
                                {quantity[i]?.proxQuarterQuantity !== 0 &&
                                    <div>- Q{proxQuarter.q + '-' + proxQuarter.year + ': '}{(quantity[i].proxQuarterQuantity).toFixed(2)} {order.product?.unit?.symbol}</div>
                                }<br/>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <Typography variant="caption"><strong>Mi Requerimiento <br/>Total Anual: </strong>
                                <Link onClick={() => handleModifyOrder(order)}>
                                    {quantity[i].totalQuantity} {order.product?.unit?.symbol}
                                </Link>
                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow sx={{background: "#e3f2fd", borderBottom: 0, paddingBottom: 0}}>
                        <TableCell>
                            <Typography variant="caption"><strong>Acumulado <br/> Regional: </strong>
                                {new Intl.NumberFormat('es-MX').format(order.totalAmount)} {order.product?.unit?.symbol}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </Table>
            </TableCell>
            <TableCell sx={{paddingTop: 0}}>
                <TablePrices data={order?.product?.priceRanges} product={order.product} setCurrentPrice={setCurrentPrice}/>
            </TableCell>
            <TableCell>
                <Typography variant="caption">
                    <strong>Volumen Final: </strong><br/>
                    <TextField size="small" type='number' value={volumenFinal} onChange={(e) => setVolumenFinal(e.target.value)}/>
                </Typography><br/><br/>
                <Typography sx={{marginBottom: "20px"}} variant="caption">
                    <strong>Precio Final: </strong><br/>{priceFinal} {order.product?.currency ?? "--"} {order.product?.incoterm}<br/>
                </Typography><br/>
                <DatePicker size="small" label="Delivery Date"
                            slotProps={{
                                textField: {
                                    size: "small",
                                    name: "deliveryDate",
                                    format: "dd/MM/yyyy"
                                }
                            }}
                            value={deliveryDate}
                            onChange={(newValue) => setDeliveryDate(newValue)}
                            sx={{marginTop: '14px', width: "160px"}}
                            renderInput={(params) => <TextField size="small" fullWidth {...params}/> }/>
            </TableCell>
            <TableCell>
                <p>
                    <Button disabled={isDisabled(volumenFinal, priceFinal, deliveryDate)}
                            variant='outlined' color='success' style={{width: '110px'}}
                            onClick={() => handleModalOrder(order, volumenFinal, priceFinal, deliveryDate, i, 'confirm')}>Confirmar</Button>
                </p>
                <p>
                    <Button variant='outlined' color='success' style={{width: '110px'}}
                            onClick={() => handleModifyOrder(order)}>Modificar</Button>
                </p>
                <p>
                    <Button variant='outlined' color='success' style={{width: '110px'}}
                            onClick={() => handleUpdateOrder(order, volumenFinal, priceFinal, deliveryDate)} disabled={!volumenFinal}>Actualizar</Button>
                </p>
                <Button variant='outlined' color='success' style={{width: '110px'}}
                        onClick={() => handleModalOrder(order, null, null , null ,i, 'cancel')}>Cancelar</Button>
            </TableCell>
        </TableRow>
    )
}

export default RowNegotiationOrder;