import {Box} from "@mui/material";
import { Typography, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { makeStyles } from '@mui/styles';

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const useStyles = makeStyles((theme) => ({
    paperDark: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        backgroundColor: "#eee"
    }  
}));

const ListImages = ({productDetail}) => {
    const {images} = productDetail

    const classes = useStyles();

    return <Box className={classes.paperDark}>
    <Typography variant="button">I. IMÁGENES DEL PRODUCTO</Typography>
    <ImageList  variant="masonry" cols={2} gap={8}>
        {images.length > 0 ? images.map((item, imageIndex) => {
            return <ImageListItem key={"img"+imageIndex}>
                <img src={`${STORAGE_BASE_URL}${BUCKET_NAME}/${item}`} alt={item} />
                <ImageListItemBar title={item} />
            </ImageListItem>
        }) : <Typography variant="subtitle2">No hay imágenes adjuntas</Typography>
        }
    </ImageList>
    </Box>
}

export default ListImages;