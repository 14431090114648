import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "30px"
    },
    label: {
        color: theme.palette.primary.dark,
        textTransform: "uppercase",
        fontWeight: "bold"
    },
    value: {
        borderBottomStyle: "dotted",
        borderBottomWidth: "1px",
        paddingBottom: "5px"
    }
}));

const UsersCollection = (props) => {
    const classes = useStyles();

    const detail = props.users.map(user => {
        return <p className={classes.value} key={user.email}>{user.name} ({user.email})</p>
    })

    return <section className={classes.root}>
        <p className={classes.label}>{props.label}</p>
        {detail}
    </section>
}

export default UsersCollection;