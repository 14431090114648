import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import ExportInfo from "../components/bi/ExportInfo";

const BiExport = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Exportaciones"/>
        <Container fixed>
            <ExportInfo/>
        </Container>
    </Fragment>
}

export default BiExport;