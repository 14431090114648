import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
    IconButton,
  Chip
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import logo from '../../img/alaxs_logo.png';
import {useSelector} from 'react-redux';

const ModalDetailPayment = (props) => {
  const {showOrder, setShowOrder, currentOrder, typeDocs, showDialogLiquitationDocs} = props;
  const {liquitation} = currentOrder;
  const codType = typeDocs === 'invoice' ? currentOrder.invoice : currentOrder.package;
  const title = typeDocs === 'invoice' ? 'PAYMENT INSTRUCTIONS' : 'packing list';
  const currentDate = new Date(); 
  const formatDate = currentDate.toLocaleString('es-Es', { year: 'numeric', month: 'short', day: 'numeric' })
  const {city, country, codeCompany} = useSelector(state => state.company);
  const {companyName} = useSelector(state => state.user);
  
  const handleClose = () => {
    setShowOrder(false);
  }
  
  const style = {
    codeDoc:{
      fontSize: '10px',
      textAlign: 'right',
      paddingBottom: 0
    },
    padtb: {
      paddingTop: 0,
      paddingBottom: 0
    },
    padb: {
      paddingBottom: 0
    },
    nopad:{
      padding: 0
    },
    logo: {
      height: '40px',
    },
    title: {
      textAlign: 'center',
      padding: 0,
      textTransform: 'uppercase',
    },
    grey: {
      color: '#656161',
      fontSize: '11px'
    },
    tag: {
      margin: '4px auto',
      fontSize: '12px'
    },
    date:{
      margin: '4px auto',
      fontSize: '12px',
      textTransform: 'capitalize'
    },
    li: {
      margin: '0 auto',
      fontSize: '12px'
    },
  }
  
  return (
    <Dialog open={showOrder} fullWidth={true} maxWidth={'md'}>
      <DialogTitle style={style.codeDoc}>{codType}</DialogTitle>
      <DialogTitle style={style.padtb}><img alt={'alaxs'} src={logo} style={style.logo}/></DialogTitle>
      <DialogTitle style={style.title}>{title}</DialogTitle>
      <DialogTitle style={style.nopad}><p style={{textAlign: 'center', margin: '0 auto 12px', padding:0, fontSize: '16px'}}>(Digital Version)</p></DialogTitle>
      <DialogContent>
        {typeDocs === 'invoice' &&
          <>
            <p style={style.li}><strong>BILL TO: </strong></p>
            <ul style={style.li}>
              <li><strong>CLIENT: </strong>{companyName}
              </li>
              <li><strong>LOCATION: </strong>{city + ' '+ country}
              </li>
              <li><strong>ID CODE: </strong>{codeCompany}
              </li>
            </ul>
          </>
        }
        <br/>
        <p style={style.li}><strong>Monto final a Pagar (USD): </strong>{new Intl.NumberFormat('es-MX',  {minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(currentOrder.finalAmount)}</p>
        {typeDocs === 'packing' &&
          <>
            <p style={style.tag}><strong>OUR REFERENCE: </strong>LATAM TRADING LTD.</p>
            <p style={style.tag}><strong>ADDRESS: </strong>Unit. 19B Golden Star Building, 20-24 Lockhart Road, Wanchai, Hong Kong</p>
            <p style={style.tag}><strong>COMPANY ID CODE: </strong>2971297</p>
            <p style={style.date}><strong>DATE: </strong>{formatDate}</p>
            <hr/>
            <p style={style.tag}><strong>Supplier: </strong>{currentOrder.product.supplier.name}</p>
            <p style={style.tag}><strong>Product: </strong>{currentOrder.product.name}</p>
            <p style={style.tag}><strong>Quantity: </strong>{currentOrder.quantity} {currentOrder?.unit}</p>
            <p style={style.tag}><strong>Details: </strong>
              {currentOrder.product.descriptions?.map(d => {
                return <span key={d}>{d}</span>
              })}
            </p>
            <p style={style.tag}><strong>Amount: </strong>{(currentOrder.finalAmount).toFixed(2)} USD</p>
          </>
        }
        <hr/>
        {typeDocs === 'invoice' &&
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 620 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>WHO</TableCell>
                  <TableCell>NAME</TableCell>
                  <TableCell>USD</TableCell>
                  <TableCell>DOC</TableCell>
                  <TableCell>CONDITION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Supplier</TableCell>
                  <TableCell>{currentOrder.product.supplier.name}</TableCell>
                  <TableCell>$ {liquitation?.supplier.amount ?? "--"}</TableCell>
                  <TableCell>
                    {liquitation?.supplier?.docs?.length > 0 &&
                      <IconButton>
                        <DescriptionOutlinedIcon onClick={() => showDialogLiquitationDocs(liquitation?.supplier?.docs)}/>
                      </IconButton>
                    }
                  </TableCell>
                  <TableCell>
                    {liquitation?.supplier?.docs?.length > 0 ?
                        <Chip icon={<CheckCircleOutlinedIcon color={'primary'}/>} sx={{background: 'white'}} label='Must Pay'/>
                        : "No hay adjuntos"
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>ALAXS</TableCell>
                  <TableCell>{liquitation?.alaxs.name ?? "--"}</TableCell>
                  <TableCell>$ {liquitation?.alaxs.amount ?? "--"}</TableCell>
                  <TableCell>
                    {liquitation?.alaxs?.docs?.length > 0 &&
                      <IconButton>
                        <DescriptionOutlinedIcon onClick={() => showDialogLiquitationDocs(liquitation?.alaxs?.docs)}/>
                      </IconButton>
                    }
                  </TableCell>
                  <TableCell>
                    {liquitation?.alaxs?.docs?.length > 0 ?
                        <Chip icon={<CheckCircleOutlinedIcon color={'primary'}/>} sx={{background: 'white'}} label='Must Pay'/>
                        : "No hay adjuntos"
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>TIC</TableCell>
                  <TableCell>{liquitation?.tic.name ?? "--"}</TableCell>
                  <TableCell>$ {liquitation?.tic.amount ?? "--"}</TableCell>
                  <TableCell>
                    {liquitation?.tic?.docs?.length > 0 &&
                        <IconButton>
                          <DescriptionOutlinedIcon onClick={() => showDialogLiquitationDocs(liquitation?.tic?.docs)}/>
                        </IconButton>
                    }
                  </TableCell>
                  <TableCell>
                    {liquitation?.tic?.docs?.length > 0 ?
                        <Chip icon={<CheckCircleOutlinedIcon color={'primary'}/>} sx={{background: 'white'}} label='Must Pay'/>
                        : "No hay adjuntos"
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FREIGHT</TableCell>
                  <TableCell>{liquitation?.freight.name ?? "--"}</TableCell>
                  <TableCell>$ {liquitation?.freight.amount ?? "--"}</TableCell>
                  <TableCell>
                    {liquitation?.freight?.docs?.length > 0 &&
                        <IconButton>
                          <DescriptionOutlinedIcon onClick={() => showDialogLiquitationDocs(liquitation?.freight?.docs)}/>
                        </IconButton>
                    }
                  </TableCell>
                  <TableCell>
                    {liquitation?.freight?.docs?.length > 0 ?
                        <Chip icon={<CheckCircleOutlinedIcon color={'primary'}/>} sx={{background: 'white'}} label='Must Pay'/>
                        : "No hay adjuntos"
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>INSURANCE</TableCell>
                  <TableCell>{liquitation?.insurance.name ?? "--"}</TableCell>
                  <TableCell>$ {liquitation?.insurance.amount ?? "--"}</TableCell>
                  <TableCell>
                    {liquitation?.insurance?.docs?.length > 0 &&
                        <IconButton>
                          <DescriptionOutlinedIcon onClick={() => showDialogLiquitationDocs(liquitation?.insurance?.docs)}/>
                        </IconButton>
                    }
                  </TableCell>
                  <TableCell>
                    {liquitation?.insurance?.docs?.length > 0 && <Chip icon={<CheckCircleOutlinedIcon color={'primary'}/>} sx={{background: 'white'}} label='Must Pay'/>}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell><strong>GRAND TOTAL</strong></TableCell>
                  <TableCell>$ {liquitation?.grandTotal ?? "--"}</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        }
        <p style={style.grey}>Note: the following data is part of the <span>{title}</span> delivered to the bank.</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} variant="contained">Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalDetailPayment;
