import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material";
import {useState} from 'react';

const ListIndustries = (props) => {
    const [value, setValue] = useState(props.selectedIndustry);

    const setValueHandler = e => {
        setValue(e.target.value);
        props.onClick(e.target.value);
    };

    const the_list = props.industries.map(i => {
        return <FormControlLabel value={i.id} control={<Radio/>} label={i.name}  />
    });

    return <FormControl component="fieldset">
        <RadioGroup value={value} onChange={setValueHandler}>
            {the_list}
        </RadioGroup>
    </FormControl>
}

export default ListIndustries;