import {Button, IconButton, Snackbar, Alert} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import React, {useState, Fragment} from 'react';
import {getQuantityModificated, getQuarter} from './utilsOrders';
import ModalDetailOrder from './ModalDetailOrder';
import ModalModifyOrder from './ModalModifyOrder';
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {useSelector} from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import RowNegotiationOrder from "./RowNegotiationOrder";

const functions = getFunctions(firebaseApp)

const {currentQuarter, nextQuarter, lastQuarter, proxQuarter} = getQuarter();

const NegotiationOrders = (props) => {
  const {email, name, companyName} = useSelector(state => state.user);
  const {orders, quantity, setIsLoadingOrders, getOpenedOrders, getPendingOrders, isLoading} = props;
  const [showOrder, setShowOrder] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(undefined);
  const [showModifyOrder, setShowModifyOrder] = useState(false);
  const [currentMonthlyOrder, setCurrentMonthlyOrder] = useState(undefined);
  const [showModalOrder, setShowModalOrder] = useState({show: false, message: '', fn: ''});
  const [open, setOpen] = useState(false);

  const codeFamily = (family) => {
    switch (family) {
    case 'PUMP':
    case 'PUMPS':
      return 'BO';
    case 'VALVES':
      return 'VA';
    case 'HARDWARE':
      return 'FE';
    case 'STEEL':
      return 'FA';
    case 'Plásticos / Plastics':
      return 'PL';
    default:
      return 'MM';
    }
  }
  
  const getQuarterQuantity = (i) => {
    if (quantity[i]?.currentQuarterQuantity > 0) {
      return {quantity: quantity[i]?.currentQuarterQuantity, index: 3};
    }
    if (quantity[i]?.nextQuarterQuantity > 0) {
      return {quantity: quantity[i]?.nextQuarterQuantity, index: 6};
    }
    if (quantity[i]?.lastQuarterQuantity > 0) {
      return {quantity: quantity[i]?.lastQuarterQuantity, index: 9};
    }
    if (quantity[i]?.proxQuarterQuantity > 0) {
      return {quantity: quantity[i]?.proxQuarterQuantity, index: 12};
    }
    return {quantity: undefined};
  }
  
  const showOrderDetail = (order) => {
    setCurrentOrder(order);
    setShowOrder(true);
  }
  
  const handleConfirmOrder = (order, volumenFinal, priceFinal, deliveryDate) => {
    setShowModalOrder({show: false});
    const currentDate = new Date();
    const getMonth = currentDate.getMonth() + 1;
    const getYear = currentDate.getFullYear();
    const getCodeFamily = codeFamily(order.product.family);
    const numDoc = `#${getYear}.${getMonth}.${getCodeFamily}.00000000`
    setIsLoadingOrders(true);

    const pendingOrder = {
      id: order.id,
      invoice: 'PI' + numDoc,
      package: 'PK' + numDoc,
    }

    if(volumenFinal){
      pendingOrder.quantity = volumenFinal
      pendingOrder.quantityOrder = order.quantity
      pendingOrder.volumenFinal = volumenFinal
      pendingOrder.priceFinal = priceFinal
      pendingOrder.deliveryDate = deliveryDate ? new Date(deliveryDate).toISOString() : null
      pendingOrder.orderOrigin = "myalaxs"
    }

    const createPendingFunction = httpsCallable(functions, 'createPendingOrder');
    createPendingFunction(pendingOrder).then(result => {
       console.log('se ingresó la orden', result);
       getPendingOrders();
       getOpenedOrders();
    //   sendMailingConfirm(order, quantity)
   });
  }
  
  const handleModifyOrder = (order) => {
    setCurrentMonthlyOrder(order);
    setShowModifyOrder(true);
  }
  
  const closeDetailOrder = () => {
    setCurrentMonthlyOrder(undefined);
    setShowOrder(false)
  }
  
  const closeModifyOrder = () => {
    setCurrentMonthlyOrder(undefined);
    setShowModifyOrder(false);
  }
  
  const handleCancelOrder = (order, quantity) => {
    setShowModalOrder({show: false});
    const {newMonthyOrden} = getQuantityModificated(order.monthlyOrder);
    const orderBody = {
      id: order.id,
      monthlyOrder: newMonthyOrden,
      quantity: order.quantity - quantity
    }
    
    if (order.quantity - quantity <= 0) {
      orderBody.state = 'cancel'
    }

    const updateOrderFunction = httpsCallable(functions, 'updateOpenOrder');
    updateOrderFunction(orderBody).then(result => {
      console.log('se canceló la orden', result);
      getOpenedOrders();
      sendMailingCancel(order)
    });
  }

  const handleUpdateOrder = (order, volumenFinal, priceFinal, deliveryDate) => {
    const orderBody = {
      id: order.id,
      volumenFinal,
      quantity: volumenFinal,
      priceFinal,
      deliveryDate: deliveryDate ? new Date(deliveryDate).toISOString() : null
    }

    const updateOrderFunction = httpsCallable(functions, 'updateOpenOrder');
    updateOrderFunction(orderBody).then(result => {
      console.log('se actualizo la orden', result);
      getOpenedOrders();
      setOpen(true);
    });
  }
  
  const handleModalOrder = (order,volumenFinal, priceFinal, deliveryDate, i, type) => {
    const {quantity} = getQuarterQuantity(i);
    if(quantity !== undefined ){
      if(type === 'confirm') setShowModalOrder({show: true, message: "¿Estás seguro de confirmar el pedido de " + volumenFinal ?? quantity + " unidad(es)", fn: () => handleConfirmOrder(order, volumenFinal, priceFinal, deliveryDate)})
      if(type === 'cancel') setShowModalOrder({show: true, message: "¿Estás seguro de cancelar el pedido de " + quantity + " unidad(es)", fn: () => handleCancelOrder(order, quantity)})
    }
  }
  
 /* const sendMailingConfirm = (order, quantity) => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Su pedido ha sido confirmado con éxito en nuestra plataforma.</p>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p><strong>Detalle de la orden</strong></p>
             <ul>
              <li><strong>Tracking Code: </strong>${order.id}</li>
              <li><strong>Producto: </strong>${order.product.name}</li>
              <li><strong>Cantidad: </strong>${quantity} unidad(es)</li>
              <li><strong>Proveedor: </strong>${order.product.supplier.name}</li>
             </ul>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }*/
  
  const sendMailingCancel = (order) => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Su pedido ha sido cancelado en nuestra plataforma.</p>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p><strong>Detalle de la orden</strong></p>
             <ul>
              <li><strong>Tracking Code: </strong>${order.id}</li>
              <li><strong>Producto: </strong>${order.product.name}</li>
              <li><strong>Cantidad: </strong>${quantity} unidad(es)</li>
              <li><strong>Proveedor: </strong>${order.product.supplier.name}</li>
             </ul>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }

  const isDisabled = (volumenFinal, priceFinal, deliveryDate) => {
    return !(deliveryDate && priceFinal > 0 && volumenFinal > 0)
  }
  
  return <Fragment>
    {currentOrder &&
      <ModalDetailOrder
        showOrder={showOrder}
        currentOrder={currentOrder}
        closeModal={closeDetailOrder}
      />
    }
    {currentMonthlyOrder &&
      <ModalModifyOrder
        showOrder={showModifyOrder}
        currentOrder={currentMonthlyOrder}
        setShowOrder={setShowModifyOrder}
        getOpenedOrders={getOpenedOrders}
        closeModal={closeModifyOrder}
      />
    }
    <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
        anchorOrigin={{vertical: "bottom", horizontal: "center"}}
    >
      <Alert
          severity="success"
          variant="filled"
      >
        El volumen final se actualizó correctamente
      </Alert>
    </Snackbar>
    <Card>
      <CardContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{width: "auto"}}>Requerimiento</TableCell>
                <TableCell sx={{minWidth: "260px"}}>Precios Vigentes</TableCell>
                <TableCell sx={{width: "180px"}}>Condiciones finales</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && (orders?.length > 0 ? orders?.map((order, i) =>
                getQuarterQuantity(i).quantity !== undefined &&
                <RowNegotiationOrder
                  order={order}
                  i={i}
                  quantity={quantity}
                  showOrderDetail={showOrderDetail}
                  handleModalOrder={handleModalOrder}
                  handleModifyOrder={handleModifyOrder}
                  isDisabled={isDisabled}
                  currentQuarter={currentQuarter}
                  nextQuarter={nextQuarter}
                  lastQuarter={lastQuarter}
                  proxQuarter={proxQuarter}
                  handleUpdateOrder={handleUpdateOrder}
                />
                ):
                <TableRow><TableCell>Sin operaciones</TableCell></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {isLoading && <LinearProgress/>}
      </CardContent>
    </Card>
    {showModalOrder.show &&
      <Dialog open={showModalOrder.show} onClose={() => setShowModalOrder({show: false})}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <IconButton
            aria-label="close"
            onClick={() => setShowModalOrder({show: false})}
            sx={{position: 'absolute', right: 8, top: 8,}}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{margin: '24px auto 0'}}>
          {showModalOrder.message}
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center', marginBottom: '20px'}}>
          <Button sx={{marginRight: '25px'}} variant="contained" onClick={showModalOrder.fn}> Si</Button>
          <Button variant="outlined" onClick={() => setShowModalOrder({show: false})}>No</Button>
        </DialogActions>
      </Dialog>
    }
    </Fragment>
}

export default NegotiationOrders;
