import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { CircularProgress} from '@mui/material';
import Dialog from '@mui/material/Dialog';

const ModalLoading = (props) => {
  const {showModal, closeModal, message} = props;
  
  return (
    <Dialog open={showModal} onClose={closeModal}>
      <DialogTitle>{message}</DialogTitle>
      <DialogContent style={{margin: 'auto'}}>
        <CircularProgress/>
      </DialogContent>
    </Dialog>
  )
}

ModalLoading.defaultProps = {
  message: 'Procesando la información...'
}

export default ModalLoading;
