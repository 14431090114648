import React from 'react';
import styles from './Loading.module.css';
import load from '../../img/loading.svg';
import alaxs from '../../img/alaxs.gif';

const Loading = () => {
  return <>
    <div className={styles.bg}>
      <img className={styles.logo} src={alaxs} alt='alaxs'/>
      <img className={styles.load} src={load} alt='loading'/>
      <p className={styles.text}>Iniciando el sistema...</p>
    </div>
  </>
}

export default Loading;
