import {Fragment} from "react";
import SubHeader from '../components/common/SubHeader';
import ConductContent from './ConductContent';

const LegalConduct = () => {
    
  return <Fragment>
    <SubHeader title=' legal / Código de conducta'/>
    <ConductContent/>
  </Fragment>
}

export default LegalConduct;
