import {Checkbox, Link, TableCell, TableRow, Typography} from "@mui/material";
import { Fragment, useState } from 'react';
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const RowUniversity = (props) => {
    const [isChecked, setIsChecked] = useState(props.checked);

    const changeHandler = async e => {
        setIsChecked(e.target.checked);
        const updateUniversity = httpsCallable(functions, 'updateSelectedUniversity');
        await updateUniversity({
            universityId: e.target.name,
            checked: e.target.checked
        });
    }

    return <Fragment>
        <TableRow>
            <TableCell><Typography variant='body2' >{props.universityInfo.globalRanking}</Typography></TableCell>
            <TableCell><Typography variant='body2' >{props.universityInfo.localRanking}</Typography></TableCell>
            <TableCell><Link href={props.universityInfo.url} target='_blank'><Typography variant='body2'>{props.universityInfo.name}</Typography></Link></TableCell>
            <TableCell><Typography variant='body2' >{props.universityInfo.city.name}</Typography></TableCell>
            <TableCell><Typography variant='body2' >{props.universityInfo.city.country.name}</Typography></TableCell>
            <TableCell><Checkbox name={props.universityInfo.id} checked={isChecked} onChange={changeHandler} /></TableCell>
        </TableRow>
    </Fragment>
}

export default RowUniversity;
