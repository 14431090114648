import {
  Container,
  Grid,
  Paper,
  Typography
} from "@mui/material";

import {Fragment} from "react";
import SubHeader from '../components/common/SubHeader';

const Conduct = () => {
    
  return <Fragment>
    <SubHeader parentUrl="/loyalty-club" parentTitle="Loyalty Club" title=' Código de conducta'/>
    <Container fixed>
      <Paper sx={{padding: '30px 0'}}>
        <Grid container spacing={2}>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Typography>
              Este código define nuestro compromiso a los principios y valores que profesamos; determina nuestro trabajo diario, así como nuestro relacionamiento con las asociaciones [vía Alliances] con las cuales compartimos una visión común de integración, así como los clientes a los cuales nos debemos, la manera cómo trabajamos, cómo nos relacionamos y creamos valor para la sociedad y cómo nos comunicamos con los diversos stakeholders.
              <p>Cada persona que componemos nuestra organización, nos comprometemos a:</p>
              <ul>
                <li>
                  Tener una sola prioridad: nuestros clientes, por lo que cada acción que damos es pensando en ellos y cuidando el maximizar sus beneficios.
                </li>
                <li>Velar en todo momento y en cada acción por nuestros principios (integridad, compromiso, adaptabilidad, empatía, consistencia y seriedad) así como por nuestros valores (conectividad, diversidad, expansión y simpleza).</li>
                <li>
                  Respetar y ser leal a las normas y reglamento interno de nuestra organización.
                </li>
                <li>
                  Ofrecer el mejor de los tratos a nuestros clientes, partners y sociedad en general.
                </li>
                <li>
                  Ser proactivos y diligentes ante cualquier necesidad, observación, sugerencia, queja o reclamo; todo significa una oportunidad para mejorar nuestro servicio.
                </li>
                <li>
                  Nuestro comportamiento y vocabulario -tanto interno como con quienes interactuamos vía nuestros servicios- es cordial, breve, resolutivo, fácil de entender y siempre abierto a resolver futuras inquietudes.
                </li>
                <li>
                  Promover un ambiente de trabajo interno positivo, horizontal, colaborativo, transparente, directo y asimismo aclarativo ante cualquier diferencia.
                </li>
                <li>Priorizar la confidencialidad de la información de nuestros clientes así como información sensible de nuestra organización de manera absoluta, segura y constante.</li>
                <li>
                  No arriesgar ni compartir de manera indebida la información de nuestros clientes, proveedores, partners y otros stakeholders.
                </li>
                <li>
                  No competir con los servicios de nuestra organización, propiedad intelectual, modalidad funcional, reglas comerciales y convenciones operativas fuera del sistema que operamos.
                </li>
                <li>
                  Cuidar los activos de nuestra organización, nuestros objetivos y metas internas, la eficacia y eficiencia comprometidas con nuestros clientes y nuestro ofrecimiento de propuesta de valor al mercado donde operamos.
                </li>
                <li>
                  Acceder de manera segura a nuestro sistema y -ante cualquier potencial riesgo- nos ponemos en contacto con nuestro inmediato superior disponible para ejecutar las acciones que se establezcan convenientes velando siempre por nuestros clientes.
                </li>
                <li>
                  No diferenciar ningún aspecto de nuestros clientes, partners o cualquier stakeholder con el que interactuamos. Asimismo, respetar cualquier origen, comportamiento, motivación u opción alguna de nuestros compañeros de trabajo.
                </li>
                <li>
                  Si cometemos un error, lo reconoceremos y pediremos las disculpas necesarias, asumiremos y resarciremos el daño que corresponda, optimizaremos nuestros procesos al interno y al final tendremos un mejor servicio en beneficio de nuestros clientes.
                </li>
              </ul>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  </Fragment>
}

export default Conduct;
