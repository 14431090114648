import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton, LinearProgress,
  Paper,
  TextField,
} from '@mui/material';
import React, {useState, useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';
import {useSelector} from 'react-redux';

const functions = getFunctions(firebaseApp)

const useStyles = makeStyles(() => ({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '60px',
    '& button': {
      marginBottom: '30px'
    }
  },
  icon: {
    height: '28px',
    marginRight: '12px'
  },
  button: {
    background: '#6200ea !important',
    padding: '16px 18px !important',
    borderRadius: '40px !important'
  }
}));

const stateInitial = {
  'bank': '',
  'date':'',
}

const ModalPaymentBank = (props) => {
  const { name, companyName} = useSelector(state => state.user);
  const {showModal, setShowModal, currentOrder, className, variant,
    getSentOrders, getPaidOrders} = props
  const classes = useStyles();
  const [showDialogThank, setShowDialogThank] = useState(false);
  const [values, setValues ] = useState(stateInitial);
  const [processing, setProcessing] = useState(false);
  const {bank, date} = values;
  
  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name] : e.target.value
    })
  }
  
  const handleClose = () => {
    setShowModal(false);
  }
  
  useEffect(() => {
    if (showDialogThank) {
      const timer = setTimeout(() => setShowDialogThank(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showDialogThank]);
  
  const send = () => {
    setProcessing(true);
    const orderBody = {
      id: currentOrder.id,
      bank: values.bank,
      date: values.date,
    }
    const updateOrderFunction = httpsCallable(functions, 'updateSentOrder')
    updateOrderFunction(orderBody).then(result => {
      getSentOrders();
      getPaidOrders();
      handleClose();
      sendMailing();
      setShowDialogThank(true);
      console.log('se ingresó la orden', result);
    });
  }
  
  const sendMailing = () => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: ['support@alaxs.com'],
      subject: 'Mensaje de MyALAXS',
      html: `<p>Hola ${name} - ${companyName}</p>
             <p>Se ha enviado la información del Banco.</p>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p><strong>Detalle de la orden</strong></p>
             <ul>
              <li><strong>Tracking Code: </strong>${currentOrder.id}</li>
              <li><strong>Producto: </strong>${currentOrder.product.name}</li>
              <li><strong>Cantidad: </strong>${currentOrder.quantity} unidad(es)</li>
              <li><strong>Proveedor: </strong>${currentOrder.product.supplier.name}</li>
              <li><strong>Invoice: </strong>${currentOrder.invoice}</li>
              <li><strong>Package: </strong>${currentOrder.package}</li>
             </ul>
             <p>------------------------------------------------------------------------------------------------------------------</p>
             <p>Atte.</p>
             <p>ALAXS Team</p>`
    });
  }
  
  return <div className={clsx(className, {[classes.fixed]: variant === 'fixed'})}>
    <Dialog open={showModal}>
      <DialogTitle sx={{padding: '40px 30px 20px'}}>
        Envía tu información de pago
        <IconButton
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{padding: '20px 30px 0'}}>
        <DialogContentText id="alert-dialog-description">
        </DialogContentText>
        <Paper
          elevation={0}
          component="form"
          sx={{p: '18px 4px', display: 'flex', flexDirection: 'column', gap: '30px 0', alignItems: 'center', width: '400'}}>
          <TextField
            fullWidth
            autoFocus
            id="bank"
            name="bank"
            label="Nombre del Banco"
            value={bank}
            onChange={e => handleChange(e)}
          />
          <TextField
            fullWidth
            autoFocus
            id="fecha"
            label="Fecha"
            name="date"
            type="datetime-local"
            value={date}
            onChange={handleChange}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Paper>
        {processing && <LinearProgress/>}
      </DialogContent>
      <DialogActions sx={{padding: '20px 30px 40px'}}>
        <Button variant='contained' onClick={() => send()} autoFocus>
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={showDialogThank}>
      <DialogTitle><br/>
        <IconButton>
          <CheckCircleOutlineOutlinedIcon color={'primary'}/>
        </IconButton>
        Gracias por actualizar
        <IconButton
          onClick={() => setShowDialogThank(false)}
          sx={{
            position: 'absolute',
            right: 4,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          El envío del pago fue exitoso.<br/>
        </DialogContentText><br/>
      </DialogContent>
    </Dialog>
  </div>
}

ModalPaymentBank.defaultProps = {
  variant: '',
  className: ''
}

export default ModalPaymentBank;
