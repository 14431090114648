import { Typography, Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions, TextField, LinearProgress,FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useState, useEffect } from "react";
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const OtherProducts = (props) => {
    const [comments, setComments] = useState('');
    const [processing, setProcessing] = useState(false);
    const [partidas, setPartidas] = useState([]);
    const [selectedPartida, setSelectedPartida] = useState('');

    const changeCommentsHandler = e => {
        setComments(e.target.value);
    };

    useEffect(() => {
        if (props.family.partidas)
        {
            console.log('Se tiene info de partidas');
            console.log(props.family.partidas);
            const fetchPartida = () => {
                const partidaInfo = httpsCallable(functions, 'listCustomsByCodes');
                partidaInfo({codes: props.family.partidas}).then(result => {
                    setPartidas(result.data);
                })
            };
            fetchPartida();
        }
        else if (props.category && props.category.indexOf('(') >= 0)
        {
            console.log('Se tiene categoria seleccionada');
            const partidas = props.category.substring(props.category.indexOf('(') + 1, props.category.indexOf(')'));
            const fetchPartida = () => {
                const partidaInfo = httpsCallable(functions, 'listCustomsByCodes');
                partidaInfo({codes: partidas}).then(result => {
                    setPartidas(result.data);
                })
            };
            fetchPartida();
        }
    }, [props.family, props.category])
    
    const sendSuggestionHandler = () => {
        setProcessing(true);
        const addDesiredProductFunction = httpsCallable(functions, 'addDesiredProduct');
        addDesiredProductFunction({desiredProduct: comments, selectedCustom: selectedPartida}).then(result => {
            setProcessing(false);
            props.handleClose();
        });
    }

    const changePartidaHandler = (e) => {
        setSelectedPartida(e.target.value);
    };

    return <Dialog open={props.open}>
        <DialogTitle>Otros Productos</DialogTitle>
        <DialogContent>
            <DialogContentText>
                <Typography gutterBottom>Especifique los productos que le gustaría obtener de la familia {props.family.name}:</Typography>
            </DialogContentText>
            <FormControl fullWidth>
                <InputLabel id="listaPartidas">Partida Arancelaria</InputLabel>
                <Select labelId="listaPartidas" label="Partida Arancelaria" value={selectedPartida} onChange={changePartidaHandler}>
                    {partidas.map(p => <MenuItem key={p.id} value={p.code}>{p.code} - {p.name}</MenuItem>)}
                </Select>
            </FormControl>
            <TextField multiline fullWidth rows={5} onChange={changeCommentsHandler}></TextField>
            {processing && <LinearProgress/>}
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose}>Cancelar</Button>
            <Button onClick={sendSuggestionHandler}>Enviar</Button>
        </DialogActions>
    </Dialog>
}

export default OtherProducts;