import styles from './../../styles/FormAuth.module.css';
import {useDispatch, useSelector} from "react-redux";
import {registerUser} from "../../store/auth-actions";
import {useEffect, useState} from "react";
import {LinearProgress, Alert, TextField, Button} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {setError} from "../../store/authSlice";

const useStyles = makeStyles({
    alert: {
        marginBottom: '10px'
    }
});

const Register = () => {
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repassword, setRepassword] = useState('');
    const [inProgress, setInProgress] = useState(false);
    const error = useSelector(state => state.auth?.existError || "");
    const message = useSelector(state => state.auth?.message || "");
    const classes = useStyles();

    const registerHandler = (event) => {
        event.preventDefault();
        setInProgress(true);
        dispatch(setError({existError: false, message: ''}));
        if(password !== repassword) {
            dispatch(setError({existError: true, message: 'Las contraseñas no coinciden'}));
            setInProgress(false);
            return
        }
        dispatch(registerUser({role: 'alaxs', email, password})).then(r => {
            setInProgress(false);
        });
    }

    useEffect(() => {
        return () => {
            if(error){
                dispatch(setError({existError: false, message: ''}));
            }
        }
    }, []);

    return <>
        <p className={styles.text}>Ingrese su información para crear una nueva cuenta en MyALAXS.</p>
        <div className={styles['form-control']}>
            <label>Email:</label>
            <TextField type="email" value={email} onChange={(e) => setEmail(e.target.value)} autoComplete="off" defaultValue=""/>
        </div>
        <div className={styles['form-control']}>
            <label>Password:</label>
            <TextField type="password" value={password} onChange={(e) => setPassword(e.target.value)} autoComplete={"new-password"}/>
        </div>
        <div className={styles['form-control']}>
            <label>Repetir Password:</label>
            <TextField type="password" value={repassword} onChange={(e) => setRepassword(e.target.value)}/>
        </div>
        <div className={styles['form-control']}>
            {error && <Alert severity="error" className={classes.alert}>{message}</Alert>}
            <Button variant="contained" className={styles.button} onClick={registerHandler} disabled={inProgress}>Registrar</Button>
            {inProgress && <LinearProgress color="secondary"/>}
        </div>
    </>
}

export default Register;
