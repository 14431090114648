import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState, Fragment} from "react";
import SubHeader from '../components/common/SubHeader';

const DataPrivacy = () => {
  const [expanded, setExpanded] = useState('panel1');
  
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const ComponentAccordion = (props) => {
    const {numPanel = '', title = '' , content = ''} = props;
    
    return <Accordion expanded={expanded === numPanel} onChange={handleChangeAccordion(numPanel)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
        <Typography variant="button" color="primary">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={1}/>
          <Grid item xs={10}>{content}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }
  
  return <Fragment>
    <SubHeader title='legal / POLÍTICA DE PRIVACIDAD DE DATOS'/>
    <Container fixed>
      <Paper sx={{padding: '30px 0'}}>
        <Grid container spacing={2}>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Typography>El presente documento está delineado respondiendo a los valores que profesamos y a fin de dar a conocer el trabajo que realizamos, cómo estructuramos nuestro sistema y -principalmente- cómo y qué realizamos de cara a maximizar la protección de la información de nuestros clientes.</Typography>
          </Grid>
        </Grid>
      </Paper>
      <ComponentAccordion 
        numPanel={1}
        title={'1. ACERCA DE NOSOTROS'}
        content={
          <Typography>
            El portal web ALAXS.com es operado en exclusiva por LatAm Trading Ltd., (en adelante LAT), una empresa privada de capitales latinoamericanos constituida y domiciliada en Hong Kong (Asia). ALAXS.com promueve alianzas en el Asia y en Latino América con organismos públicos de promoción económica, comercial, cultural, tecnológico, turístico, etc. Asimismo, organiza data y masas críticas (volúmenes de compras y ventas) buscando eficiencias comerciales y técnicas, visitas y validación de proveedores, trading, participación en ferias comerciales, congresos y eventos, charlas de especialistas, soporte logístico, legal y contable en cada operación, entre otros servicios.
            <p>
            A la fecha tenemos un acuerdo contractual con la Hong Kong Latin America Business Association (en adelante HKLABA) como “supporting organization” a fin de dar soporte a nuestros clientes en dos servicios principales 1. client management y 2. trading. HKLABA es una organización empresarial fundada en Latino América y vinculada con la Hong Kong Trade Development Council (HKTDC) y la Federation of Hong Kong Business Associations Worldwide (FHKBAW) y la Cámara de Comercio Asia Pacífico. En breve estaremos ampliando nuestro relacionamiento con otros importantes organismos y mercados del Asia.
            </p>
            Existe un registro inicial único y luego tanto la web de estas organizaciones como la web de ALAXS.com se interconectan a fin de dar soporte a cada empresa y ver en qué poder ayudarla. Los datos de su empresa son protegidos tanto por ALAXS.com y por LAT como por cada organismo público y partner con el cual interactuemos; todos los cuales damos cumplimiento a la privacidad de datos al más alto nivel de los estándares mundiales asegurando en todo momento la confidencialidad de su información.
            <p>
            Nuestro sistema ALAXS se estructura de la siguiente manera:
            </p>
            <ul>
              <li>
                ALAXS.com: es nuestra web principal -desde donde se conecta todo nuestro sistema- así como el acceso a nuestras webs relacionadas y la base de toda nuestra comunicación.
              </li>
              <li>
                REGISTERASIALATAM.com: esta web se conecta desde ALAXS.com a fin de dar inicio al registro de nuestros clientes.
              </li>
              <li>
                MyALAXS.com: se conecta desde ALAXS.com y sirve para conectar a nuestros clientes con el Asia y sus oportunidades.
              </li>
              <li>
                ALAXS.app: se conecta desde ALAXS.com y sirve para la gestión de nuestros diversos partners globales.
              </li>
              <li>
                CRM Dynamics: se interconecta con nuestras diversas webs y da soporte a las mismas con el software desarrollado por Microsoft y customizado por nosotros a fin de facilitar el trabajo de nuestros clientes y partners.
              </li>
            </ul>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={2}
        title={'2. OBJETO DE ESTA POLÍTICA'}
        content={
          <Typography>
            Cuando utiliza nuestros portales webs, nos confía su información la cual nosotros mantenemos encriptada y óptimamente almacenada en nuestro sistema. La siguiente política de privacidad rigen el uso que toda persona natural o jurídica, o representante en cualquier forma de los mismos, que se registren y operen en nuestro portal web (el/los “usuario(s)”), hace(n) de nuestra plataforma y/o portal web.
            <p>
              Por medio de este documento se explica qué información se recopila, cómo se utiliza dicha información, en qué circunstancias es compartida dicha información y los derechos que tiene el usuario(s) sobre esta información. Por el uso de la plataforma ALAXS.com, se entiende que cada usuario ha leído y aceptado la “Política de Privacidad de Datos” que se expresa a continuación, en todas sus partes y en su integridad. En caso contrario, el usuario deberá abstenerse de registrar sus datos en nuestra plataforma web ALAXS.com, ya sea directa o indirectamente y de utilizar cualquier información o servicio provisto por la misma.</p>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={3}
        title={'3. ¿QUIÉN ES EL RESPONSABLE DEL TRATAMIENTO DE SUS DATOS?'}
        content={
          <Typography>
              <p>El responsable del tratamiento de sus datos es
                ALAXS.com quienes registramos su data en nuestro sistema de ‘back-end’ [base de datos] el cual está
                soportado en Google Cloud Platform y en los sistemas Power Platform y Azure Cloud de Microsoft que
                sustentan nuestro sistema ALAXS. Una vez recibida su información, es inmediatamente encriptada en estas
                plataformas y sistemas cloud, los cuales no sólo tienen la infraestructura tecnológica global que nos
                garantiza la inviolabilidad de las mismas, sino que cumplen con los más altos estándares normativos
                globales (ISO 27001, ISO 27017 e ISO 27018) y la norma europea GDPR, así como numerosas otras normativas
                globales, regionales, nacionales y locales.</p>
              <p>Las referencias a los estándares antes mencionados,
                puede Ud. revisarlas cuando así lo disponga:</p>
              <p><b>Administración de riesgos de la información</b><br/><a
                href="https://cloud.google.com/security/compliance/iso-27001"
                target="_blank" rel="noreferrer">https://cloud.google.com/security/compliance/iso-27001/</a></p>
              <p><b>Control de la seguridad de la
                información </b><br/>
                <a href="https://cloud.google.com/security/compliance/iso-27017/" target="_blank" rel="noreferrer">https://cloud.google.com/security/compliance/iso-27017/</a></p>
              <p><b>Protección de los datos personales</b><br/><a
                href="https://cloud.google.com/security/compliance/iso-27018/"
                target="_blank" rel="noreferrer">https://cloud.google.com/security/compliance/iso-27018/</a></p>
              <p><b>Cumplimiento al estándar europeo</b><br/>
                <a href="https://cloud.google.com/security/gdpr/" target="_blank" rel="noreferrer">https://cloud.google.com/security/gdpr/</a>
              </p>
              <p><b>Privacidad de Microsoft</b><br/><a
                href="https://privacy.microsoft.com/es-es/privacy"
                target="_blank" rel="noreferrer">https://privacy.microsoft.com/es-es/privacy</a></p>
              <p><b>Privacidad y Protección de Datos en Microsoft Azure</b><br/>
                <a href="https://azure.microsoft.com/es-es/overview/trusted-cloud/privacy/"
                   target="_blank" rel="noreferrer">https://azure.microsoft.com/es-es/overview/trusted-cloud/privacy/</a>
              </p>
              <p><b>Declaración de Privacidad de Microsoft</b><br/><a
                href="https://privacy.microsoft.com/es-mx/privacystatement"
                target="_blank" rel="noreferrer">https://privacy.microsoft.com/es-mx/privacystatement</a></p>
              <p>Contacte con ALAXS.com a:</p>
              <ul>
                <li><b>Correo electrónico:</b> legal@alaxs.com</li>
                <li><b>Dirección (HK):</b> Unit 19B, Golden Star Building, 20-24 Lockhard Road, WanChai, Hong Kong</li>
                <li><b>Teléfono (HK):</b> +852 2528 4999</li>
                <li><b>Dirección USA:</b> 2320 Ponce de Leon Blvd., Coral Gables, Miami FL 33134, USA</li>
                <li><b>Teléfono USA:</b> +1 (305) 444.8800</li>
              </ul>
              <p>En paralelo a las previsiones técnicas y
                normativas ya descritas en este acápite, en ALAXS.com hemos adoptado otras medidas más de seguridad para
                proteger la información recolectada de los usuarios, la que será mantenida de manera estrictamente
                confidencial. El acceso a los datos personales está restringido contractualmente a aquellos empleados,
                contratistas, representantes y agentes de ALAXS.com que necesitan conocer tales datos para el desempeño
                de sus funciones y para el desarrollo o mejora de nuestros servicios, impidiendo el acceso, modificación
                o divulgación de la información por cualquier persona no autorizada formalmente por nuestra empresa o
                por el propio usuario. Si así lo estima, Ud. puede solicitar una copia del contrato interno que es
                firmado por nuestros representantes para la óptima gestión y cuidado de su información (ver punto
                10).</p>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={4}
        title={'4. ¿QUÉ DATOS RECIBIMOS?'}
        content={
          <Typography>
              <p>La información que recabamos de nuestros clientes es de tres (03) tipos:</p>
              <ol>
                <li><b>Información que usted nos proporciona:</b> a
                  través del formulario de registro recibimos y almacenamos toda la información que usted nos confía y/o
                  que nos facilita por cualquier otro medio. Puede elegir no proporcionar cierta información, sin
                  embargo, si así lo hace, no podrá contar con muchas de los servicios que le ofrecemos. Utilizamos la
                  información que usted nos proporciona con el objeto de proporcionarle nuestros servicios, ponernos en
                  contacto con usted y su empresa y conectarlos con las oportunidades globales que nosotros ofrecemos
                  vía la conectividad digital con los mercados asiáticos.
                </li>
                <li><b>Información automática:</b> Recibimos y
                  almacenamos muchos tipos de información cada vez que usted interactúa con nosotros. Los datos de
                  proveedores asiáticos (productos, precios, volúmenes, fechas, consideraciones técnicas, etc.), los
                  datos de ferias en el Asia y en otras partes del mundo, los datos de oficinas que son parte de la red
                  de oficinas de los organismos asiáticos, etc. Esta información está disponible en nuestro portal web y
                  cada vez que usted interactúa con nosotros, el sistema registra sus intereses, consultas y otros.
                </li>
                <li><b>Información de otras fuentes:</b> Es posible
                  que recibamos información sobre usted de otras fuentes (partnerships, por ejemplo), la cual añadiremos
                  a la información de su propia cuenta. Si esto llegara a suceder usted estará al tanto de esto en todo
                  momento dado que su información será siempre visible en su perfil de usuario.
                </li>
              </ol>
              <p>Es preciso decir que mucha de la información que
                recabamos de usted al inicio -a través del formulario de registro-, es data aproximada de su empresa a
                fin de darnos un contexto referencial de su empresa y entender dónde opera, qué realiza, su dimensión,
                entre otros datos generales. Es decir, por ejemplo, al dimensionar su empresa sólo requerimos saber
                rangos de datos aproximados al número de personal, facturación y/o equipamiento; no necesitamos los
                datos exactos de su empresa que siempre quedarán al interno de su empresa.</p>
              <p>Incluso a lo que se dedica su empresa, sólo requerimos
                saber datos generales de qué familia de productos compra, vende, comercializa o transporta sin necesidad
                de saber -en la mayoría de los casos- al detalle en sí de los productos con los cuales interactúa su
                empresa con el mercado.</p>
              <p>Sí existen otros datos más precisos como el código
                tributario, personas de contacto, correos electrónicos que requieren precisión a fin de estar seguros de
                que nosotros estamos tratando con quien dice ser, que su empresa está representada por estas personas y
                que los datos centrales de su empresa son correctos y por eso requerimos estas precisiones en estos
                datos puntuales.</p>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={5}
        title={'5. ¿POR QUÉ RECOPILAMOS SUS DATOS?'}
        content={<Typography>
          Los datos que recopilamos sobre su empresa y personas de contacto varían en función a cómo use nuestro portal web.
          <p>
          Utilizamos datos sobre el uso de nuestro portal web para ayudarnos a garantizar que nuestros contenidos se presenten eficazmente y para ayudarles a acceder más rápidamente a la información que buscan en nuestro portal web.
          </p>
          Si usted registra a su empresa en <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a>, utilizaremos la información que nos faciliten para administrar su cuenta en <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a> y utilizaremos dicha información para optimizar su experiencia en nuestra plataforma digital.
          <p>
          Si se registra para recibir material de promoción de nosotros, utilizaremos sus datos personales para nuestros propósitos de marketing directo.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={6}
        title={'6. ¿CON QUIÉN COMPARTIMOS LA INFORMACIÓN RECOPILADA?'}
        content={<Typography>
          <p>Sin su consentimiento, con nadie; siempre su información
            estará encriptada en nuestro sistema. Lo que sí hacemos es compartir información grupal (acumulada de
            varios clientes) -y no individual- a fin de:</p>
          <ol>
            <li>ligar su información -y la de varios otros clientes-
              con la información de los mercados asiáticos (proveedores, productos, ferias, visitas, etc.) a fin de
              que su empresa y otras empresas puedan ampliar sus oportunidades en estos mercados.
            </li>
            <li>en la medida que su empresa requiera los servicios que
              nosotros ofrecemos (trading, visitas, validaciones, etc.) su empresa podrá tomar contacto con empresas
              proveedoras en Asia y las de soporte con las cuales nosotros mantenemos relación comercial vía
              partnerships.
            </li>
          </ol>
          <p>Usted presta su consentimiento inequívoco para que
            la web <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a> pueda compartir sus datos con terceras
            partes una vez así lo requiera y por propio interés de su empresa.</p>
          <p>Tenga en cuenta que, al usted facilitar de manera
            libre, voluntaria y por propia iniciativa, información directamente a los prestadores de servicios como <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a>, nosotros nos comprometemos a recopilar,
            tratar y conservar esta información de acuerdo con nuestras políticas de seguridad y siempre teniendo un
            solo objetivo en mente, maximizar sus oportunidades comerciales en los mercados donde operamos. En <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a> somos responsables por el debido y correcto
            uso de sus datos personales lo cual sucede dentro de nuestro portal web, cualquier uso indebido fuera de
            nuestro portal web está ajeno a nuestro control.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={7}
        title={'7. ¿DÓNDE ALMACENAMOS Y CÓMO PROTEGEMOS LA INFORMACIÓN RECOPILADA?'}
        content={<Typography>
          <p>Toda la información recolectada de los clientes y
            usuarios es almacenada en el sistema Google Cloud previamente mencionado. Este sistema cloud realiza
            varios pasos de cara a mantener su información segura. Desde un inicio encripta la información, la
            desdobla almacenando la misma en diferentes servidores a lo largo del globo (a solicitud de <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a>) e incluso la mantiene en diferentes capas
            (layers) a fin de preservar la misma y que ésta no pueda ser accesada, manipulada, intervenida, vulnerada
            y/o eliminada por agentes externos.</p>
          <p>Asimismo, Google Cloud posee una activa presencia de
            firewalls rules (inbound, outbound, connection y monitoring) y un sistema constante de autenticación de
            usuarios una vez se accede a los datos, entre otros principales controles. El sistema Google Cloud cumple
            con la normativa internacional más exigente la misma que es descrita en el punto 3 de este documento.</p>
          <p>Usted presta consentimiento inequívoco para que el
            portal web <a href="https://alaxs.com/" target="_blank" rel="noreferrer">www.alaxs.com</a> pueda solicitar la transferencia
            de sus datos con destino a cualquier locación del mundo con el ánimo de garantizar la mayor seguridad de
            su información y de mejorar su experiencia de usuario. Si desea más información puede revisar el siguiente
            link</p>
          <p><a href="https://cloud.google.com/security/infrastructure/design/"
                target="_blank" rel="noreferrer">https://cloud.google.com/security/infrastructure/design/</a></p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={8}
        title={'8. SUS DERECHOS'}
        content={<Typography>
          <p>Su información está amparada por una serie de
            derechos legales con respecto a los datos propios y personales que retenemos sobre su empresa y personas.
            Estos derechos le dan control sobre cómo se utilizan y manejan sus datos.</p>
          <ul>
            <li>Derecho de acceso</li>
            <li>Derecho de rectificación o supresión</li>
            <li>Derecho a las notificaciones de incidentes</li>
            <li>Derecho a la limitación del tratamiento</li>
            <li>Derecho a la portabilidad</li>
            <li>Derecho de oposición</li>
            <li>Derecho a revocar el consentimiento</li>
            <li>Derecho a la eliminación de su información</li>
          </ul>
          <p>De acuerdo con lo anterior, tiene derecho a acceder,
            actualizar, incluir, rectificar o suprimir sus datos personales, así como solicitar que corrijamos sus
            datos o que los completemos si opina que los datos personales que retenemos sobre su empresa y personas
            son inexactos o están incompletos. En algunas circunstancias, también tiene derecho a oponerse a nuestro
            tratamiento de sus datos y puede solicitarnos que restrinjamos nuestro uso de sus datos y eliminarlos</p>
          <p>El derecho de incluir, rectificar, suprimir o eliminar
            sus datos lo puede realizar a través de:</p>
          <ol>
            <li>nuestro sistema.</li>
            <li>formalmente solicitando lo propio a nuestras oficinas
              (ver punto 3)
            </li>
            <li>vía correo electrónico a legal@alaxs.com</li>
          </ol>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={9}
        title={'9. CAMBIOS A LA POLÍTICA DE PRIVACIDAD DE DATOS'}
        content={<Typography>
          Es posible que modifiquemos la información contenida en esta “Política de Privacidad de Datos” cuando lo estimemos conveniente y a fin de dar un mejor servicio a nuestros clientes. En caso de que lo hagamos, se lo notificaremos por distintas vías a través de la plataforma (por ejemplo, a través de un banner, un pop-up, una notificación push u otro modo digital), o incluso se lo comunicaremos vía correo electrónico al o los correos registrados por su empresa en nuestro sistema, cuando el cambio en cuestión sea significativo para con su privacidad, de manera que pueda revisar los cambios, valorarlos, pedir más información e incluso discrepar, oponerse o incluso darse de baja en algún o varios servicios, de todos los servicios o de la completa funcionalidad de nuestro sistema.
          <br/><br/>
          En cualquier caso, les sugerimos que revisen esta “Política de Privacidad de Datos” unas dos (02) veces al año a fin de revisar posibles cambios, mejoras, nuevas consideraciones, mayores servicios que impactan en los mismos, mayores mercados con los cuales interactuemos, introducción de mejoras interactivas, cambios en las leyes y regulaciones de las autoridades en los mercados donde operamos y cualquier otra posible observación que podrán siempre encontrar como punto permanente de información en nuestro portal web.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={10}
        title={'10. CONTACTA CON NOSOTROS'}
        content={<Typography>
          Cualquier consulta y/o alguna inquietud no contemplada en este documento es de nuestro mayor interés saberla toda vez que así podemos interactuar mejor con nuestros clientes y mejorar no sólo nuestra comunicación, sino cualquier proceso al interno de ALAXS.com. Si tiene alguna pregunta adicional sobre cómo ALAXS.com utiliza sus datos, si quiere hacernos una recomendación, reclamación, proponer una mejora u obtener información de cómo gestionamos sus datos, nos puede enviar una carta a las direcciones antes indicadas (punto 3) o un correo electrónico a <a href="mailto:legal@alaxs.com" target="_blank" rel="noreferrer">legal@alaxs.com</a>.
        </Typography>}
      />
    </Container>
  </Fragment>
}

export default DataPrivacy;
