import SubHeader from "../components/common/SubHeader";
import {
    Accordion, AccordionDetails,
    AccordionSummary, Box,
    Card,
    CardContent,
    CardHeader, CircularProgress,
    Container,
    Grid,
    Typography
} from "@mui/material";
import {Fragment, useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CompanyDetails from "../components/company/CompanyDetails";
import firebaseApp from '../firebase/firebaseApp';
import ListSubIndustries from "../components/company/ListSubIndustries";
import ListAssociations from "../components/association/ListAssociations";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const UpdateProfile = () => {
    const [hasCompanyInfo, setHasCompanyInfo] = useState(false);
    const [loadingCompanyInfo, setLoadingCompanyInfo] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [countries, setCountries] = useState([]);
    const [cities, setCities] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [employeeRanges, setEmployeeRanges] = useState([]);
    const [incomes, setIncomes] = useState([]);
    const [positions, setPositions] = useState([]);

    const [hasIndustryInfo, setHasIndustryInfo] = useState(false);
    const [loadingIndustryInfo, setLoadingIndustryInfo] = useState(false);
    const [industryInfo, setIndustryInfo] = useState(null);
    const [subIndustries, setSubIndustries] = useState([]);
    const [selectedSubIndustries, setSelectedSubIndustries] = useState(null);

    const [hasAssociationInfo, setHasAssociationInfo] = useState(false);
    const [loadingAssociationInfo, setLoadingAssociationInfo] = useState(false);
    const [associations, setAssociations] = useState([]);
    const [selectedAssociations, setSelectedAssociations] = useState(null);

    const handleCompanyDetails = async (event, isExpanded) => {
        if (isExpanded && !hasCompanyInfo)
        {
            setLoadingCompanyInfo(true);

            const getCompanyInfoFunction = httpsCallable(functions, 'getCompanyInfo');
            const citiesFunction = httpsCallable(functions, 'listCitiesByCountry', );
            getCompanyInfoFunction().then(result => {
                setCompanyInfo(result.data);
                setHasCompanyInfo(true);
                setLoadingCompanyInfo(false);

                return citiesFunction({
                    country: result.data.company.city.country.id
                });
            }).then(result => {
                setCities(result.data);
            });

            const countriesFunction = httpsCallable(functions, 'listCountriesMarket');
            countriesFunction().then(result => {
                setCountries(result.data);
            });

            const industriesFunction = httpsCallable(functions, 'listIndustries');
            industriesFunction().then(result => {
                setIndustries(result.data);
            })

            const listEmployeesRangeFunction = httpsCallable(functions, 'listEmployeeRanges');
            listEmployeesRangeFunction().then(result => {
                setEmployeeRanges(result.data);
            })

            const listIncomeFunction = httpsCallable(functions, 'listIncomes');
            listIncomeFunction().then(result => {
                setIncomes(result.data);
            });

            const listPositionFunction = httpsCallable(functions, 'listRoles');
            listPositionFunction().then(result => {
                setPositions(result.data);
            });
        }
    }

    const handleIndustryDetails = async (event, isExpanded) => {
        if (isExpanded && !hasIndustryInfo)
        {
            setLoadingIndustryInfo(true);
            const getCompanyInfoFunction = httpsCallable(functions, 'getCompanyInfo');
            const companyInfo = await getCompanyInfoFunction();
            const industry = companyInfo.data.company.industry;
            const listSubIndustriesFunction = httpsCallable(functions, 'listSubIndustries');
            const listSubIndustries = await listSubIndustriesFunction({industryId: industry.id});
            const selectedSubIndustries = companyInfo.data.company.subIndustries;
            // console.log("Selected Sub Industries: ", selectedSubIndustries);
            setIndustryInfo(industry);
            setSubIndustries(listSubIndustries.data);
            setSelectedSubIndustries(selectedSubIndustries === undefined ? {} : selectedSubIndustries);
            setLoadingIndustryInfo(false);
            setHasIndustryInfo(true);
        }
    }

    const handleAssociationsDetails = async (event, isExpanded) => {
        if (isExpanded && !hasAssociationInfo)
        {
            setLoadingAssociationInfo(true);
            const getCompanyInfoFunction = httpsCallable(functions, 'getCompanyInfo');
            const companyInfo = await getCompanyInfoFunction();
            const country = companyInfo.data.company.city.country;

            const listAssociationsByCountryFunction = httpsCallable(functions, 'listGremiosByCountry');
            const listAssociations = await listAssociationsByCountryFunction({country: country.id});
            const selectedAssociations = companyInfo.data.company.associations;

            setAssociations(listAssociations.data);
            setSelectedAssociations(selectedAssociations === undefined ? {} : selectedAssociations);
            setLoadingAssociationInfo(false);
            setHasAssociationInfo(true);
        }
    }

    return <Fragment>
        <SubHeader title="Actualizar Mis Datos"/>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title="Actualizar Mis Datos" />
                        <CardContent>
                            <Fragment>
                                <Accordion onChange={handleCompanyDetails}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>} >
                                        <Typography>Empresa</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {loadingCompanyInfo && <Fragment>
                                            <CircularProgress />
                                        </Fragment>}
                                        {!loadingCompanyInfo && hasCompanyInfo &&
                                            <CompanyDetails companyInfo={companyInfo} countries={countries}
                                                            cities={cities} industries={industries}
                                                            employeeRanges={employeeRanges}
                                                            incomes={incomes} positions={positions}
                                            />}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion onChange={handleIndustryDetails}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Industrias</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {loadingIndustryInfo && <CircularProgress/>}
                                        {!loadingIndustryInfo && hasIndustryInfo && <Fragment>
                                            <Box>
                                                <Typography variant="body2" gutterBottom>Usted, o alguien de su empresa, ha seleccionado
                                                    la siguiente actividad como actividad principal de su empresa.</Typography>
                                                <Typography variant="subtitle2" gutterBottom color="primary">{industryInfo.name}</Typography>
                                                <Typography variant="body2" gutterBottom>Las industrias publicadas líneas abajo son las
                                                    industrias que se relacionan a la actividad que ustedes han seleccionado</Typography>
                                                <Typography variant="body2" gutterBottom>Marque aquellas industrias que se ajustan al
                                                    modelo de negocio de su empresa.</Typography>
                                                <Grid container spacing={3}>
                                                    <ListSubIndustries subIndustries={subIndustries} selectedSubIndustries={selectedSubIndustries}/>
                                                </Grid>
                                            </Box>
                                        </Fragment>}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion onChange={handleAssociationsDetails}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Cámaras / Asociaciones / Gremios</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {loadingAssociationInfo && <CircularProgress />}
                                        {!loadingAssociationInfo && hasAssociationInfo && <Fragment>
                                            <Box>
                                                <Typography variant="body2" gutterBottom>Las cámaras / asociaciones /
                                                    gremios publicados líneas abajo son aquellos relacionados con el
                                                    país asociado a su compañía.</Typography>
                                                <Grid container spacing={3}>
                                                    <ListAssociations associations={associations} selectedAssociations={selectedAssociations} />
                                                </Grid>
                                            </Box>
                                        </Fragment>}
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion disabled>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Permisos</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                    </AccordionDetails>
                                </Accordion>
                                <Accordion disabled>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Typography>Otros Detalles</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>

                                    </AccordionDetails>
                                </Accordion>
                            </Fragment>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Fragment>
}

export default UpdateProfile;
