import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import ProviderInfo from "../components/bi/ProviderInfo";

const BiProvider = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Providers by City"/>
        <Container fixed>
            <ProviderInfo/>
        </Container>
    </Fragment>
}

export default BiProvider;