import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    LinearProgress
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';


const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%"
    }
}));

const functions = getFunctions(firebaseApp)

const IndustryElement = (props) => {
    const classes = useStyles();
    const [subIndustries, setSubIndustries] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    const handleChange = (event, isExpanded) => {
        if (isExpanded)
        {
            if (!isFetched)
            {
                setIsLoading(true);
                const listSubIndustries = httpsCallable(functions, 'listSubIndustries');
                listSubIndustries({
                    industryId: props.industry.id
                }).then(result => {
                    setIsLoading(false);
                    setSubIndustries(result.data);
                    setIsFetched(true);
                });
            }
        }
    }

    return <Accordion onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
            <Typography>{props.industry.code} - {props.industry.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.wrapper}>
                {isLoading && <LinearProgress/>}
                {!isLoading && subIndustries.map(c => {
                        return <Typography>{c.code} - {c.name}</Typography>
                    })
                }
            </div>
        </AccordionDetails>
    </Accordion>
}

export default IndustryElement;