import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {useState} from 'react';

const Alert = (props) => {
    const [open, setOpen] = useState(props.open);

    const handleClose = () => {
        props.onClose();
        setOpen(false);
    }
    return <div>
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>{props.message}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" fullWidth>Cerrar</Button>
            </DialogActions>
        </Dialog>
    </div>
}

export default Alert;