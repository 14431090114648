import {TableContainer, Table, TableHead, Paper, TableRow, TableCell, TableBody} from "@mui/material";
import { makeStyles } from '@mui/styles';
import RowEvent from "./RowEvent";
import {useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
    },
    tableHead: {
        backgroundColor: '#F8F9FD'
    }
}));

const ListEvents = (props) => {
    const classes = useStyles();
    const userAdmin = useSelector(state => state.user.admin);

    const updateEventHandler = (eventInfo) => {
        props.onUpdateEvent(eventInfo);
    }

    const deleteEventHandler = (eventInfo) => {
        props.onDeleteEvent(eventInfo);
    }

    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>Tema</TableCell>
                    <TableCell>Promotor</TableCell>
                    <TableCell></TableCell>
                    {userAdmin && <TableCell></TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.events.map(event => {return <RowEvent key={event.id} event={event} onUpdateEvent={updateEventHandler} onDeleteEvent={deleteEventHandler}/>})}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListEvents;
