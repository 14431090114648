import {createSlice} from '@reduxjs/toolkit';

const postSlice = createSlice({
    name: 'post',
    initialState: {
        listThematics: [],
        error: false,
        message: ''
    },
    reducers: {
        setPostThematics: (state, action) => {
            state.listThematics = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload.existError;
            state.message = action.payload.message;
        }
    }
});

export const {setPostThematics, setError} = postSlice.actions;

export default postSlice.reducer;
