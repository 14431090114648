import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useState} from "react";

const Confirm = (props) => {
    const [open, setOpen] = useState(props.open);

    const confirmHandler = () => {
        setOpen(false);
        props.onConfirm();
    }

    const cancelHandler = () => {
        setOpen(false);
        props.onCancel();
    }

    return <Dialog open={open} >
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.message}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={confirmHandler} color="primary" variant="contained">
                {props.confirmButtonText}
            </Button>
            <Button onClick={cancelHandler} color="secondary" variant="outlined">
                {props.cancelButtonText}
            </Button>
        </DialogActions>
    </Dialog>
}

export default Confirm;