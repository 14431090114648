import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import OpportunityInfo from "../components/bi/OpportunityInfo";

const BiOpportunity = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Oportunidades"/>
        <Container fixed>
            <OpportunityInfo/>
        </Container>
    </Fragment>
}

export default BiOpportunity;