import React, {useEffect, useState} from "react";
import {getLocacion} from '../components/thinktank/utils';
import {Breadcrumbs, CircularProgress, Container} from "@mui/material";
import Typography from "@mui/material/Typography";
import firebaseApp from '../firebase/firebaseApp';
import GridPosts from '../components/thinktank/GridPosts';
import {Link, useParams} from 'react-router-dom';
import MenuThinktank from '../components/thinktank/menuThinktank';
import {useSelector} from 'react-redux';
import {getFunctions, httpsCallable} from 'firebase/functions';

const functions = getFunctions(firebaseApp)

export const getYear = (datePost) => {
  if(datePost){
    const date = new Date(datePost);
    return date.getUTCFullYear();
  }
}

const PostsByTag = () => {
  const {tag, id} = useParams();
  const [tagName, setTagName] = useState('');
  const [dataPost, setDataPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const listThematics = useSelector(state => state.post.listThematics)
  
  useEffect(() => {
    const fetchPostsByTag = async (endpoint) => {
      const getPost = httpsCallable(functions, endpoint);
      getPost({[tag]: id}).then(response => {
        setDataPost(response.data);
        setIsLoading(false)
      })
    }
    
    if(tag === 'locacion'){
      setTagName(getLocacion(id));
      fetchPostsByTag('listPostsByLocation')
    }
    if(tag === 'tematica'){
      fetchPostsByTag('listPostsByThematic')
    }
    
    if(tag === 'date'){
      setTagName(id)
      const listPosts = httpsCallable(functions, 'listPosts');
      listPosts().then(response => {
        const filterData = response.data.filter(e => getYear(e.fecha) ===  parseInt(id));
        setDataPost(filterData);
        setIsLoading(false)
      })
    }
  }, [tag, id]);
  
  useEffect(() => {
    if(tag === 'tematica' && listThematics.length > 0){
      const name = listThematics.filter(e => e.order === parseInt(id));
      setTagName(name[0].name);
    }
  },[listThematics, id, tag])
  
  const posts = () => <div className="featured-grid-all post-mansory">
    {dataPost.map((post, i) => <GridPosts key={`gp-${i}`} data={dataPost[i]}></GridPosts>)}
  </div>
  
  return <div className='think-tank' style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Link to="/think-tank">THINK TANK</Link>
      <Link to="/posts">POSTS</Link>
      <Typography>{tag === 'date' ? 'año' : tag }</Typography>
      <Typography>{tagName}</Typography>
    </Breadcrumbs><br/><br/>
    <Container fixed>
      <MenuThinktank/>
      {isLoading ? <CircularProgress/> :
        dataPost.length > 0 ? posts() : 
          <Typography sx={{textAlign: 'center'}}>No hay posts de {tag === 'date' ? 'año' : tag} {tagName}</Typography>
      }
      <br/><br/><br/>
    </Container>
  </div>
}

export default PostsByTag;
