import {
    Breadcrumbs, 
    Button, 
    Container,
    Typography
} from '@mui/material';
import {useEffect, useState} from 'react';
import firebaseApp from '../firebase/firebaseApp';
import OpenedOrders from '../components/orders/OpenedOrders';
import Share from '../components/common/Share';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {getQuantity} from '../components/orders/utilsOrders';
import { getFunctions, httpsCallable } from 'firebase/functions';
import LayoutClient from '../components/layout/LayoutClient';
const functions = getFunctions(firebaseApp)

const useStyles = makeStyles((theme) => ({
    main: {
        height: 'calc(100vh - 96px)',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '-24px'
    },
    container: {
        height: '100%',
        display: 'flex !important',
        flexFlow: 'column nowrap',
    },
    root: {
        display: 'flex',
        flexFlow: 'column nowrap',
        marginBottom: 'auto'
    }
}));

const Dashboard = () => {
    const classes = useStyles();
    const [openedOrders, setOpenedOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [quantity, setQuantity] = useState([]);
    const [update, setUpdate] = useState('');
    
    useEffect(() => {
        if(openedOrders?.length > 0){
            let dataQuantity = [];
            openedOrders?.forEach(order => {
                const {currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, totalQuantity} = getQuantity(order.monthlyOrder);
                dataQuantity.push({currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, totalQuantity})
            })
            setQuantity(dataQuantity)
        }
    }, [openedOrders])

    useEffect(() => {
        getOpenedOrders();
    }, []);
    
    const getOpenedOrders = async () => {
        setOpenedOrders(undefined);
        setIsLoading(true);
        const listOpenedOrders = httpsCallable(functions,'listOpenedOrders');
        listOpenedOrders().then(result=> {
            setOpenedOrders(result.data);
            setIsLoading(false);
        })
    }
    
    useEffect(() => {
        if(update === 'openOrder'){
            getOpenedOrders();
        }
    },[update] )
    
    return <LayoutClient><div className={classes.main}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>Dashboard </Typography>
        </Breadcrumbs>
        <Container fixed className={classes.container}>
            <div className={classes.root} style={{marginTop: '20px'}}>
                <OpenedOrders isLoading={isLoading} orders={openedOrders} quantity={quantity} setUpdate={setUpdate}/>
                <div style={{marginTop: '20px'}}>
                    <Button variant='outlined' color='success' component={Link} to="/trading">Seguimiento de pedido <br/>Ir al Trading Platform</Button>
                </div>
            </div>
            <Share variant="fixed"/>
        </Container>
        
    </div></LayoutClient>
}
export default Dashboard;
