import { Card, CardContent, CardHeader, } from "@mui/material";
import {makeStyles} from '@mui/styles';
import { Fragment, useState } from 'react';

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%"
    },
    card: {
        marginBottom: theme.spacing(4)
    },
    iframe: {
        border: 0
    },
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    messageLoading: {
        position: "absolute",
        top: "30%",
        left: "50%",
        fontSize: "1.5rem",
        color: "#7e87ea"
    }
}));

const CustomInfo = () => {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);

    const onLoadIFrame = e => {
        setLoaded(true);
    };

    return <Fragment>
        <Card className={classes.card}>
            <CardHeader title="Importaciones x Partida" />
            <CardContent>
                {!loaded && (
                    <span className={classes.messageLoading}>Cargando...</span>
                )}
                <iframe title="Importaciones x Partida" width="100%" height="900" onLoad={onLoadIFrame} 
                    className={classes.iframe} src="https://datastudio.google.com/embed/reporting/54643d94-15a4-483f-b72e-180e83fb83b4/page/Wt2lC" frameborder="0" allowfullscreen></iframe>
            </CardContent>
        </Card>
    </Fragment>
}

export default CustomInfo;