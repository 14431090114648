import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import {Button} from '@mui/material';
import {Dialog, DialogTitle, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {Link} from 'react-router-dom'

const ModalCreateOrder = (props) => {
  const {showModal, closeModal} = props;
  
  return (
    <Dialog open={showModal}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent><br/>
        <p>
          Su pedido fue registrado con éxito en nuestra plataforma
        </p>
      </DialogContent>
      <DialogActions>
        <Link to="/trading" style={{textDecoration: 'none'}}>
          <Button variant="outlined">Ver mis pedidos</Button>
        </Link>
        <Button sx={{marginLeft: '25px'}} variant="contained" onClick={closeModal}> Seguir comprando</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalCreateOrder;
