export const getDateOrder = (i) => {
  const currentDate = new Date();
  currentDate.setDate(1);
  const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + i));
  const monthNumber = nextDate.getMonth();
  let monthAndYear = new Intl.DateTimeFormat('es-ES', {month: 'short'}).format(nextDate);
  monthAndYear += '-' + nextDate.getFullYear();
  return {nextDate, monthAndYear, monthNumber};
}

const monthsPerQuarter = {
  0: {month: 'ene', quarter: 1},
  1: {month: 'feb', quarter: 1},
  2: {month: 'mar', quarter: 1},
  3: {month: 'abr', quarter: 2},
  4: {month: 'may', quarter: 2},
  5: {month: 'jun', quarter: 2},
  6: {month: 'jul', quarter: 3},
  7: {month: 'ago', quarter: 3},
  8: {month: 'sep', quarter: 3},
  9: {month: 'oct', quarter: 4},
  10: {month: 'nov', quarter: 4},
  11: {month: 'dic', quarter: 4}
}

const listQuarters = {
  1: [0, 1, 2],
  2: [3, 4, 5],
  3: [6, 7, 8],
  4: [9, 10, 11]
}


const getQuarterData = (monthInitial) => {
  const currentDate = new Date();
  const quarterDate = new Date(currentDate.setMonth(currentDate.getMonth() + monthInitial));
  const quarterYear = quarterDate.getFullYear();
  const quarterMonth = quarterDate.getMonth();
  const q = monthsPerQuarter[quarterMonth].quarter
  return {year: quarterYear, month: quarterMonth, q}
}

export const getQuarterEnabled = () => {
  const currentQuarterE = getQuarterData(0);
  const nextQuarterE = getQuarterData( 3)
  return {currentQuarterE, nextQuarterE}
}

export const getQuarter = () => {
  const currentQuarter = getQuarterData(0);
  const nextQuarter = getQuarterData(3);
  const lastQuarter = getQuarterData(6);
  const proxQuarter = getQuarterData(9);
  return {currentQuarter, nextQuarter, lastQuarter, proxQuarter}
}

const {currentQuarter, nextQuarter, lastQuarter, proxQuarter} = getQuarter();

export const getQuantity = (monthlyOrder) => {
  let currentQuarterQuantity = 0;
  let nextQuarterQuantity = 0;
  let lastQuarterQuantity = 0;
  let proxQuarterQuantity = 0;
  let totalQuantity = 0;
  let newMonthyOrden = [];
  let isQuarterDisabled = true;
  for (let i = 0; i < 12; i++) {
    const {nextDate, monthAndYear, monthNumber} = getDateOrder(i);
    let quantity = 0;
    let label = monthAndYear;
    let state = '';
    
    for(let j = 0; j < monthlyOrder.length; j++){
      if(monthAndYear === monthlyOrder[j].label){
        if(monthlyOrder[j].quantity && monthlyOrder[j].state === "enabled") {
      
          if (listQuarters[currentQuarter.q].includes(monthNumber) && monthNumber >= currentQuarter.month) {
            currentQuarterQuantity += monthlyOrder[j].quantity;
          }
          if (listQuarters[nextQuarter.q].includes(monthNumber)) {
            nextQuarterQuantity += monthlyOrder[j].quantity;
          }
          if (listQuarters[lastQuarter.q].includes(monthNumber)) {
            lastQuarterQuantity += monthlyOrder[j].quantity;
          }
          if (listQuarters[proxQuarter.q].includes(monthNumber)) {
            proxQuarterQuantity += monthlyOrder[j].quantity;
          }
          totalQuantity += monthlyOrder[j].quantity;
        }
        label = monthlyOrder[j].label;
        quantity = monthlyOrder[j].quantity;
        state = monthlyOrder[j].state
      }
    }
    newMonthyOrden.push({'date': nextDate, quantity, state, label})
  }
  if(currentQuarterQuantity && nextQuarterQuantity) {
    lastQuarterQuantity = 0;
    proxQuarterQuantity = 0;
  }
  if(currentQuarterQuantity || nextQuarterQuantity) {
    isQuarterDisabled = false;
  }
  if(nextQuarterQuantity && lastQuarterQuantity) proxQuarterQuantity = 0;
  return {currentQuarterQuantity, nextQuarterQuantity, lastQuarterQuantity, totalQuantity, newMonthyOrden, proxQuarterQuantity, isQuarterDisabled};
}

export const getQuantityModificated = (monthlyOrder) => {
  let newMonthyOrden = [];
  let flat = 0;
  for (let i = 0; i < 12; i++) {
    const {nextDate, monthAndYear, monthNumber} = getDateOrder(i);
    let quantity = 0;
    let label = monthAndYear;
    let state = 'enabled';
    
    for(let j = 0; j < monthlyOrder.length; j++){
      if(monthAndYear === monthlyOrder[j].label){
        if(monthlyOrder[j].quantity && monthlyOrder[j].state === "enabled") {
          label = monthlyOrder[j].label;
          quantity = monthlyOrder[j].quantity;
          state = monthlyOrder[j].state;
          if (listQuarters[currentQuarter.q].includes(monthNumber) && monthNumber >= currentQuarter.month && (flat === 0 || flat === 1)) {
            quantity = 0;
            state = 'disabled';
            flat = 1;
          }
          if (listQuarters[nextQuarter.q].includes(monthNumber) && (flat === 0 || flat === 2)) {
            quantity = 0;
            state = 'disabled';
            flat = 2;
          }
          if (listQuarters[lastQuarter.q].includes(monthNumber) && (flat === 0 || flat === 3)) {
            quantity = 0;
            state = 'disabled';
            flat = 3;
          }
          if (listQuarters[proxQuarter.q].includes(monthNumber) && (flat === 0 || flat === 4)) {
            quantity = 0;
            state = 'disabled';
            flat = 4;
          }
        }
      }
    }
    newMonthyOrden.push({'date': nextDate, quantity, state, label})
  }
  return {newMonthyOrden};
}
