import {
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    LinearProgress,
    Paper,
    Typography,
    Button
} from "@mui/material";
import {useEffect, useState, Fragment} from 'react';
import firebaseApp from "../firebase/firebaseApp";
import ListUniversities from "../components/universities/ListUniversities";
import SubHeader from "../components/common/SubHeader";
import { getFunctions, httpsCallable } from 'firebase/functions';
import IconButton from '@mui/material/IconButton';

const functions = getFunctions(firebaseApp)

const Universities = () => {
    const [universities, setUniversities] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [universitiesLoaded, setUniversitiesLoaded] = useState(false);
    const [selectedUniversitiesLoaded, setSelectedUniversitiesLoaded] = useState(false);
    const [selectedUniversities, setSelectedUniversities] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const listUniversities = httpsCallable(functions, 'listUniversities');
            listUniversities().then(result => {
                setUniversities(result.data);
                setUniversitiesLoaded(true);
            });

            const listSelectedUniversities = httpsCallable(functions, 'listSelectedUniversities');
            listSelectedUniversities().then(result => {
               setSelectedUniversities(result.data);
               setSelectedUniversitiesLoaded(true);
            });
        }
        fetchData().then(data => {
            console.log('Fetch was executed');

        });
    }, []);

    if (universitiesLoaded && selectedUniversitiesLoaded && isLoading) {
        setIsLoading(false);
    }

    return <Fragment>
        <SubHeader title="asia / Universidades"/>
        <Container fixed>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12}>
                    <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
                        <Typography>
                            Aquí podrás ver la información completa de las más importantes casas de estudio del Asia. Asimismo, en caso lo requieras, el equipo ALAXS podrá ayudarte a entrar en contacto con representantes de las universidades para posibles alianzas y/u oportunidades comerciales. Haz click en las universidades de tu interés (máximo 3) para recibir más información.
                        </Typography>
                    </Paper>
                </Grid>
            </Grid>
        <Card>
            <CardHeader title="Universidades del Asia" action={
                <IconButton aria-label="settings" onClick={() => console.log('Enviando info')}>
                    <Button color='primary' variant='contained'>Solicitar información</Button>
                </IconButton>
            }/>
            <Divider/>
            <CardContent>
                {isLoading && <LinearProgress />}
                {!isLoading && <Fragment>
                    <Typography gutterBottom>Especifique las universidades con las que desea contactar</Typography>
                    <ListUniversities universities={universities}
                    selectedUniversities={selectedUniversities === null ? {} : selectedUniversities }/>
                </Fragment>}
            </CardContent>
        </Card>
    </Container>
    </Fragment>
}

export default Universities;
