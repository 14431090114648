import React from 'react'
import {makeStyles} from '@mui/styles';
import {
    Grid,
    Button,
    LinearProgress,
    Paper,
    FormControl,
    Link,
    TableContainer, InputLabel, Select,
    TablePagination,
    DialogTitle, DialogContent, DialogActions, Dialog, TextField, CircularProgress, MenuItem
} from "@mui/material";
import {useState, useEffect} from 'react';
import firebaseApp from '../../firebase/firebaseApp';
import {ChevronLeft, Feed, Image} from '@mui/icons-material';
import OtherProducts from './OtherProducts';
import TechnicalSupport from './TechnicalSupport';
import {getFunctions, httpsCallable} from 'firebase/functions';
import styles from './Categories.module.css'
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import ConfirmProduct from './ConfirmProduct';
import ListImages from './ListImages';
import ModalCreateOrder from '../orders/ModalCreateOrder';
import ListDocs from "./ListDocs";

const useStyles = makeStyles((theme) => ({
    familyTitle: {
        fontSize: '1.5em',
        fontWeight: 'bold',
        marginTop: '1rem',
    },
    arrows: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    actionButton: {
        marginBottom: '1rem',
        width: '90%'
    },
    lateralButton: {
        height: '70px'
    },
    tableHead: {
        backgroundColor: '#F8F9FD'
    }
}));

const functions = getFunctions(firebaseApp)

const Categories = (props) => {
    const {family} = props;
    const classes = useStyles();
    const [currentProduct, setCurrentProduct] = useState(null)
    const [loading, setLoading] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [productIndex, setProductIndex] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0)
    const [open, setOpen] = useState(false);
    const [techOpen, setTechOpen] = useState(false);
    const [products, setProducts] = useState([])
    const [loadingAlgolia, setLoadingAlgolia] = useState(false)
    const [showProduct, setShowProduct] = useState(false);
    const [showProductImg, setShowProductImg] = useState(0);
    const [page, setPage] = useState(0);
    const [tableView, setTableView] = useState([]);
    const [typeSearch, setTypeSearch] = useState(null);
    const [showModalLoad, setShowModalLoad] = useState(false);
    const [showCreateOrder, setShowCreateOrder] = useState(false);
    const [optionSelected, setOptionSelected] = useState([])
    const [loadingVolumen, setLoadingVolumen] = useState(true)
    const [regionalCumulative, setRegionalCumulative] = useState("")
    const [orders, setOrders] = useState([])
    const categories = family?.categories || [];
    const filterAll = family?.filters || []

    useEffect(() => {
        getOpenedOrders();
    }, [selectedCategory])

    const getOpenedOrders = async () => {
        setRegionalCumulative("---");
        setLoadingVolumen(true);
        let cumulativetotalAmount;
        let cumulativeByFamily;
        const listOpenedOrders = httpsCallable(functions,'listOpenedOrders');
        const filters = {allOrders: true}

        listOpenedOrders(filters).then(result=> {
            if(selectedCategory !== null){
                cumulativeByFamily = result.data?.filter(order => order.product.family === family.name && order.product.category === selectedCategory)
                cumulativetotalAmount = cumulativeByFamily.reduce((acc, order) => acc + order.quantity, 0)
            }
            if(selectedCategory === null){
                cumulativeByFamily = result.data?.filter(order => order.product.family === family.name)
                cumulativetotalAmount = cumulativeByFamily.reduce((acc, order) => acc + order.quantity, 0)
            }
            setRegionalCumulative(cumulativetotalAmount ? new Intl.NumberFormat('es-MX').format(cumulativetotalAmount) : "---");
            setOrders(cumulativeByFamily);
            setLoadingVolumen(false);
        })
    }

    const getAcumulative = (option) => {
        let acumulative;
        if(option === "Todos"){
            acumulative = orders?.reduce((acc, order) => {
                if (order.product.filters[1].name === "Sub Tipo") {
                    return acc + order.quantity;
                }
                return acc;
            }, 0);
        }
        else{
            acumulative = orders?.reduce((acc, order) => {
                if (order.product.filters[1].name === "Sub Tipo" && order.product.filters[1].option === option) {
                    return acc + order.quantity;
                }
                return acc;
            }, 0);
        }
        setRegionalCumulative(acumulative ? new Intl.NumberFormat('es-MX').format(acumulative) : 0)
    }

    const handleChangeFilterOption = (e, action) => {
        const filterIndex = action?.props?.data;
        const newFilters = [...filterAll]
        newFilters[filterIndex].name = filterAll[filterIndex].name;
        newFilters[filterIndex].option = e.target.value;
        setOptionSelected(newFilters)
        const selectedFilters = newFilters.filter(filterS => filterS.option && filterS.option !== "Todos")
        if(filterAll[filterIndex].name === "Sub Tipo"){
            getAcumulative(e.target.value)
        }
        const filteredProducts = products.filter(product => {
            return selectedFilters.every(filter => {
                const found = product.filters.find(f => f.name === filter.name && f.option === filter.option);
                return found !== undefined;
            });
        });
        setTableView(filteredProducts)
        setTotalProducts(filteredProducts.length)
    }

    const deleteOptionSelected = () =>  optionSelected?.forEach(item => delete item.option)

    const FilterCards = () => {
        return filterAll.map((filter, filterIndex) => (
            <FormControl size="small" sx={{width: "100%", marginTop: '24px'}} key={"filter" + filterIndex}>
                <InputLabel id={filter.name + filterIndex} size="small">{filter.name}</InputLabel>
                <Select
                    value={optionSelected[filterIndex]?.option || ''}
                    label="Familia"
                    onChange={handleChangeFilterOption}
                    size="small"
                >
                    <MenuItem data={filterIndex} value={'Todos'}>Todos</MenuItem>
                    {filter.options.map((option, optionIndex) =>
                        <MenuItem key={'filterName' + filterIndex + optionIndex} data={filterIndex}
                                  value={option}>{option}</MenuItem>
                    )}
                </Select>
            </FormControl>
        ))
    }

    useEffect(() => {
        if (props.family.categories.length > 0) {
            fetchProducts();
        }
    }, [props.family.categories]);

    const fetchProducts = () => {
        setLoading(true);
        const listProducts = httpsCallable(functions, 'listProductsByCategoryPag');
        listProducts({productId: null, category: null, limit: 20, family: family.name}).then(result => {
            const productsResult = result.data.products;
            const productsResultVisible = productsResult.filter(product => !product.supplier.hide);
            const productsLength = productsResultVisible.length;
            if (productsLength > 0) {
                setCurrentProduct(productsResultVisible[productsLength - 1])
                setProductIndex(productsLength - 1);
                setTotalProducts(result.data.totalProductsByCat)
                setProducts(productsResultVisible)
                setTableView(productsResultVisible)
            }
            setLoading(false);
        });
    }

    const showProductsHandler = (event) => {
        const cat = event.target.value
        setTableView([])
        setProducts([])
        deleteOptionSelected()
        setTotalProducts(0)
        setLoading(true);
        setSelectedCategory(cat);
        setTypeSearch('byCategory')
        setPage(0)
        const listProducts = httpsCallable(functions, 'listProductsByCategoryPag');
        listProducts({productId: null, category: cat, limit: 20, family: family.name}).then(result => {
            const productsResult = result.data.products;
            const productsResultVisible = productsResult.filter(product => !product.supplier?.hide);
            const productsLength = productsResultVisible.length;
            if (productsLength > 0) {
                setCurrentProduct(productsResultVisible[productsLength - 1])
                setProductIndex(productsLength - 1);
                setTotalProducts(result.data.totalProductsByCat)
                setProducts(productsResultVisible)
                setTableView(productsResultVisible)
            }
            setLoading(false);
        });
    }

    const goNextHandler = () => {
        if (!loading && products.length < totalProducts) {
            setLoading(true)
            const listProducts = httpsCallable(functions, 'listProductsByCategoryPag')
            listProducts({
                productId: currentProduct.id,
                position: 'after',
                limit: 20,
                category: selectedCategory
            }).then(result => {
                const productsResult = result.data.products;
                const productsResultVisible = productsResult.filter(product => !product.supplier?.hide);
                const productsLength = productsResultVisible.length;
                if (productsLength > 0) {
                    const copyProducts = [...products]
                    copyProducts.push(...productsResultVisible)
                    setProducts(copyProducts)
                    setCurrentProduct(productsResultVisible[productsLength - 1])
                    setProductIndex(productsLength - 1);
                    setTableView(productsResultVisible)
                }
            }).catch(error => {
                console.log(error)
            }).finally(() => {
                setLoading(false)
            })
        } else {
            const pageCurrent = (page + 1) * 20;
            const nextData = products.slice(pageCurrent, pageCurrent + 20);
            setTableView(nextData);
            setLoading(false)
        }
    }

    const handleChangePage = (event, newPage) => {
        if (newPage > page) {
            goNextHandler();
        }
        if (newPage < page) {
            const pageCurrent = (page - 1) * 20;
            const prevData = products.slice(pageCurrent, pageCurrent + 20);
            setTableView(prevData);
        }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = () => {};

    const handleClearFilter = () => {
        setSelectedCategory(null)
        setOptionSelected([])
        fetchProducts()
    }

    const searchProductKeyPressHandler = (e) => {
        if (e.code === 'Enter' && e.target.value.length >= 3) {
            setLoadingAlgolia(true)
            setTypeSearch('algolia')
            setLoading(true)
            setPage(0)
            console.log(`Enter was pressed with the value of ${e.target.value}`)
            const listProducts = httpsCallable(functions, 'listProductsByNameAndCategory')
            listProducts({
                name: e.target.value,
                category: selectedCategory
            }).then(result => {
                setTableView(result.data)
                setProducts(result.data)
                setTotalProducts(result.data.length)
            }).finally(() => {
                setLoadingAlgolia(false)
                setLoading(false)
            })
                .catch(error => console.log(error))
        }
    }

    const handleModalProduct = (product, setModal) => {
        if (typeSearch === 'algolia') {
            setShowModalLoad(true);
            const getProduct = httpsCallable(functions, 'getProduct')
            getProduct({id: product.objectID})
                .then(result => {
                    if (result.data) {
                        setCurrentProduct(result.data)
                        setModal(true)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    setShowModalLoad(false)
                })
        } else {
            setModal(true);
            setCurrentProduct(product)
        }
    }

    return <Grid container spacing={4}>
        <Grid item xs={12}>
            <Button size={'small'} onClick={props.goBackHandler}><ChevronLeft/>Regresar</Button>
            <div className={classes.familyTitle}>Requerimiento por Catálogo - Familia {props.family.name}</div>
        </Grid>
        <Grid item xs={4}>
            <Paper variant="outlined" sx={{padding: '20px', background: "#e3f2fd"}}>
                <div><strong>Acumulado <br/>Regional: </strong>
                    {loadingVolumen ? <CircularProgress size={20}/> : regionalCumulative} TM
                </div>
            </Paper>
            <Paper variant="outlined" sx={{padding: '20px', marginTop: '24px'}}>
                <div><strong>Categoría: {" "}</strong><br/>
                    {selectedCategory?.indexOf('(') >= 0 ? selectedCategory.substring(0, selectedCategory.indexOf('(') - 1) : selectedCategory}
                </div>
                <div>Buscar por nombre de producto:</div>
                <TextField size={'small'} fullWidth disabled={loading} type="text"
                           placeholder={`Ingrese producto`}
                           onKeyPress={searchProductKeyPressHandler}/>
            </Paper>
            <Paper variant="outlined" sx={{padding: '20px', marginTop: '24px'}}>
                <FormControl size="small" sx={{width: "100%"}}>
                    <InputLabel size="small">Categoría</InputLabel>
                    <Select
                        label={"Categoría"}
                        size="small"
                        value={selectedCategory || ''}
                        onChange={showProductsHandler}
                    >
                        {categories.map(cat =>
                            (<MenuItem key={cat}
                                       value={cat}>{cat.indexOf('(') >= 0 ? cat.substring(0, cat.indexOf('(') - 1) : cat}</MenuItem>)
                        )}
                    </Select>
                </FormControl>
                {FilterCards()}
                <Button sx={{marginTop: '24px'}} variant='outlined' fullWidth onClick={() => {handleClearFilter()}}>Limpiar filtros</Button>
            </Paper>
            <br/>
            <div className={classes.actionButton}>
                <Button variant='outlined' fullWidth color='success' onClick={() => {
                    setOpen(true)
                }}
                        className={classes.lateralButton}>¿Qué otro producto de esta familia estás buscando?</Button>
                <OtherProducts open={open} handleClose={() => setOpen(false)} family={props.family}
                               category={selectedCategory}/>
            </div>
            <div className={classes.actionButton}>
                <Button variant='outlined' fullWidth color='success' onClick={() => setTechOpen(true)}
                        className={classes.lateralButton}>¿Requieres soporte técnico?</Button>
                <TechnicalSupport open={techOpen} handleClose={() => setTechOpen(false)}/>
            </div>
        </Grid>
        <Grid item xs={8}>
            {loadingAlgolia && <span className={styles.info}>Obteniendo la información...</span>}
            {!loading && !loadingAlgolia && <div className={classes.arrows}>
            </div>}
            {loading ? <LinearProgress/> :
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead className={classes.tableHead}>
                                <TableRow>
                                    <TableCell>Nº</TableCell>
                                    <TableCell>Producto</TableCell>
                                    <TableCell>Especificaciones</TableCell>
                                    <TableCell>Imágenes</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableView?.length > 0 ? tableView.map((prod, i) =>
                                    <TableRow key={'prod' + productIndex + i}>
                                        <TableCell>{page * 20 + i + 1}</TableCell>
                                        <TableCell><Link href="#"
                                                         onClick={() => handleModalProduct(prod, setShowProduct)}>{prod.name}</Link></TableCell>
                                        <TableCell align={'center'}><Link href="#"
                                                                          onClick={() => handleModalProduct(prod, setShowProduct)}><Feed
                                            style={{color: 'black'}}/></Link></TableCell>
                                        <TableCell align={'center'}><Link href="#"
                                                                          onClick={() => handleModalProduct(prod, setShowProductImg)}><Image
                                            style={{color: 'black'}}/></Link></TableCell>
                                    </TableRow>
                                ) : <TableRow>No se encontraron resultados</TableRow>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={totalProducts}
                        rowsPerPage={20}
                        page={page}
                        rowsPerPageOptions={[20]}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            }
        </Grid>
        {showProduct &&
            <ConfirmProduct
                product={currentProduct}
                open={showProduct}
                handleClose={() => setShowProduct(false)}
                setShowCreateOrder={setShowCreateOrder}
            />
        }
        {showProductImg &&
            <Dialog open={showProductImg} onClose={() => setShowProductImg(false)}>
                <DialogTitle>Producto:<br/> {currentProduct.name}</DialogTitle>
                <DialogContent>
                    <ListImages productDetail={currentProduct}/>
                    <ListDocs productDetail={currentProduct}/>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} onClick={() => setShowProductImg(false)}
                            color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>}
        {showModalLoad &&
            <Dialog open={showModalLoad} onClose={() => setShowModalLoad(false)}>
                <DialogTitle>Obteniendo información...</DialogTitle>
                <DialogContent>
                    <CircularProgress/>
                </DialogContent>
                <DialogActions>
                    <Button variant={'outlined'} onClick={() => setShowModalLoad(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>}
        {showCreateOrder &&
            <ModalCreateOrder showModal={showCreateOrder} closeModal={() => setShowCreateOrder(false)}/>
        }
    </Grid>
}

export default Categories;
