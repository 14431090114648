import {Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {Link} from 'react-router-dom';
import {makeStyles} from "@mui/styles";
import {Fragment} from 'react';
import SubHeader from "../components/common/SubHeader";
import StarIcon from "@mui/icons-material/Star";
import Categoria from "./../img/categoria.jpeg";
import Target from "./../img/target.jpeg";

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    },
    cardDisabled: {
        position: "relative",
        '&::before': {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100vh",
            background: "linear-gradient(0deg, rgba(2,0,36,0.5984768907563025) 0%, rgb(255 255 255 / 60%) 34%)",
            zIndex: "1",
            cursor: "no-drop"
        }
    }
}));

const SelectPurchase = () => {
    const classes = useStyles();
    return <Fragment>
        <SubHeader title="trading / requerimiento / Nuevo"/>
        <Container fixed>
            <Grid container columnSpacing={10} rowSpacing={6}
                  justifyContent="center">
                <Grid item xs={10} md={5}>
                    <Link to="/newbuying" style={{textDecoration: 'none'}}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                  component="img"
                                  height="180"
                                  image={Categoria}
                                  title="Compras por Catálogo"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Requerimiento por Catálogo
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary"> Ingresar</Button>
                                <StarIcon sx={{fontSize: "13px"}} color="error"/>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={10} md={5}>
                    <Link to="/target" style={{textDecoration: 'none'}}>
                        <Card className={classes.card}>
                            <CardActionArea>
                                <CardMedia
                                    component="img"
                                    height="180"
                                    image={Target}
                                    title="Requerimiento Target"
                                />
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        Requerimiento Target
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">Ingresar</Button>
                            </CardActions>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card className={classes.cardDisabled}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="180"
                                image="https://storage.googleapis.com/alaxs-0003.appspot.com/compra_anual_recurrente.jpg"
                                title="Compras por Catálogo"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Compras Anuales Recurrentes
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Elija esta opción para programar comprar anuales recurrentes y tener sus pedidos garantizados.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary">
                                Ingresar
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Card className={classes.cardDisabled}>
                        <CardActionArea>
                            <CardMedia
                                component="img"
                                height="180"
                                image="https://storage.googleapis.com/alaxs-0003.appspot.com/compra_proyecto.jpg"
                                title="Compras por Catálogo"
                            />
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h2">
                                    Proyectos / Licitaciones
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Elija esta opción si desea adquirir productos para un proyecto en particular.
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                        <CardActions>
                            <Button size="small" color="primary">
                                Ingresar
                            </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    </Fragment>
}

export default SelectPurchase;
