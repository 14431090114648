import {
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material';
import {Button} from '@mui/material';
import Dialog from '@mui/material/Dialog';

const STORAGE_BASE_URL = 'https://storage.googleapis.com/';
const BUCKET_NAME = 'myalaxs-images';

const ModalLiquitationDocs = (props) => {
  const {open, docs, closeModal} = props;
  
  return (
    <Dialog open={open}>
      <DialogTitle>Documentos adjuntos:</DialogTitle>
      <DialogContent>
          {
            docs.map((doc, i) =>
                <div  className="card-text-icon" key={"docs"+i}>
                    <Typography variant="caption"><strong>Nombre archivo: </strong>
                        <a rel="noreferrer" target="_blank" href={`${STORAGE_BASE_URL}${BUCKET_NAME}/${doc}`} download className="file-name">{doc}</a>
                    </Typography>
                </div>
            )
          }
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} variant="contained">Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalLiquitationDocs;
