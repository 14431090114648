import {Paper, Typography, Box} from "@mui/material";
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#fff"
    },
    paperDark: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#eee"
    }    
}));

const Supplier = ({supplier}) => {
    const classes = useStyles();

    return <Box className={classes.paperDark}>
        <Typography variant="button">II. Supplier</Typography>
        <Paper className={classes.paper}>
            <Typography variant="subtitle2">Nombre:</Typography>
            <Typography variant="body2" gutterBottom>{supplier?.name}</Typography>
            <Typography variant="subtitle2">URL:</Typography>
            <Typography variant="body2" gutterBottom>{supplier?.url}</Typography>
        </Paper>
    </Box>
}

export default Supplier;