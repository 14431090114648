import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Typography,
    LinearProgress
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: "100%"
    }
}));

const functions = getFunctions(firebaseApp)

const CustomElement = (props) => {
    const classes = useStyles();
    const [customs, setCustoms] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetched, setIsFetched] = useState(false);

    const handleChange = (event, isExpanded) => {
        if (isExpanded)
        {
            if (!isFetched)
            {
                if (props.custom.level === 2 || props.custom.level === 4)
                {
                    setIsLoading(true);
                    const listCustoms = httpsCallable(functions, 'listCustoms');
                    listCustoms({
                        level: props.custom.level + 2,
                        code: props.custom.code
                    }).then(result => {
                        setIsLoading(false);
                        setCustoms(result.data);
                        setIsFetched(true);
                    });
                }
            }
        }
    }

    return <Accordion onChange={handleChange} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
            <Typography>{props.custom.code} - {props.custom.name}</Typography>
        </AccordionSummary>
        <AccordionDetails>
            <div className={classes.wrapper}>
            {isLoading && <LinearProgress/>}
            {!isLoading && props.custom.level !== 4 && customs.map(c => {
                return <CustomElement key={c.id} custom={c} />
            })}
            {!isLoading && props.custom.level === 4
                && customs.map(c => {
                    return <Typography>{c.code} - {c.name}</Typography>
                })
            }
            </div>
        </AccordionDetails>
    </Accordion>
}

export default CustomElement;