import {
  Container,
  Grid,
  Paper
} from "@mui/material";
import {Fragment} from 'react';
import SubHeader from '../components/common/SubHeader';

const Principles = () => {
  return <Fragment>
    <SubHeader parentUrl="/loyalty-club" parentTitle="Loyalty Club" title='Principios'/>
    <Container fixed>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <ul>
              <li><strong>Integridad:</strong> operamos negocios transparentes, honestos, confiables y eficientes.</li>
              <li><strong> Compromiso: </strong>nuestro compromiso y prioridad es construir valor para nuestros clientes, los cuales operan en múltiples industrias, en zonas próximas y distantes y teniendo capacidades similares y únicas. Nosotros ganamos si nuestros clientes ganan.</li>
              <li><strong>Adaptabilidad:</strong>  nos adaptamos al mercado en donde operamos entendiendo su idiosincrasia, cultura y formas de operar y atendiendo a los diversos actores que en éste interactúan.</li>
              <li><strong>Empatía: </strong> entendemos y adaptamos nuestra plataforma y nuestros servicios a las diversas necesidades de los distintos niveles de empresas con las cuales interactuamos.</li>
              <li><strong>Consistencia: </strong> trabajamos de manera abierta, manteniendo la misma propuesta de valor, misión y valores con cada empresa con la cual interactuamos y en todos los frentes con quien mira y aspira a su propio crecimiento.</li>
              <li><strong>Seriedad: </strong>damos lo mejor y el máximo de nosotros por nuestros clientes.</li>
            </ul>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  </Fragment>
}

export default Principles;
