import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import {makeStyles} from "@mui/styles";
import ListIndustries from "./ListIndustries";
import {useState, useEffect, Fragment} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import Alert from "../common/Alert";
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(theme => ({
    formControl: {
        width: '100%'
    },
    section: {
        marginBottom: theme.spacing(3)
    },
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const functions = getFunctions(firebaseApp)

const CompanyDetails = (props) => {
    const [code, setCode] = useState(props.companyInfo.company.code);
    const [name, setName] = useState(props.companyInfo.company.name);
    const [countryId, setCountryId] = useState(props.companyInfo.company.city.country.id);
    const [cityId, setCityId] = useState(props.companyInfo.company.city.id);
    const [cities, setCities] = useState([]);
    const [employeeRangeId, setEmployeeRangeId] = useState(props.companyInfo.company.employeerange.id);
    const [incomeId, setIncomeId] = useState(props.companyInfo.company.income.id);
    const [userName, setUserName] = useState(props.companyInfo.user.name);
    const [email, setEmail] = useState(props.companyInfo.user.email);
    const [positionId, setPositionId] = useState(props.companyInfo.user.position.id);
    const [isSaving, setIsSaving] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const [industryId, setIndustryId] = useState(props.companyInfo.company.industry.id);

    useEffect(() => {
        setCities(props.cities);
    }, [props.cities]);

    const setCodeHandler = (e) => {
        setCode(e.target.value);
    }

    const setCountryHandler = async e => {
        setCountryId(e.target.value);
        setCities([]);
        setCityId('');
        const listCitiesFunction = httpsCallable(functions, 'listCitiesByCountry');
        listCitiesFunction({country: e.target.value}).then(result => {
            setCities(result.data);
            if (result.data.length > 0)
            {
                setCityId(result.data[0].id);
            }
        });
    }

    const setCityHandler = e => {
        setCityId(e.target.value);
    }

    const setNameHandler = e => {
        setName(e.target.value);
    }

    const setEmployeeRangeHandler = e => {
        setEmployeeRangeId(e.target.value);
    }

    const setIncomeHandler = e => {
        setIncomeId(e.target.value);
    }

    const setUserNameHandler = e => {
        setUserName(e.target.value);
    }

    const setEmailHandler = e => {
        setEmail(e.target.value);
    }

    const setPositionHandler = e => {
        setPositionId(e.target.value);
    }

    const saveChangesHandler = async e => {
        e.preventDefault();
        setIsSaving(true);
        if (props.companyInfo.company.code !== code || props.companyInfo.company.city.country.id !== countryId)
        {
            const listCompaniesFunction = httpsCallable(functions, 'listCompanies');
            const companiesInfo = await listCompaniesFunction({code: code, country: countryId});
            if (companiesInfo.data.length > 0)
            {
                setTitle('Mensaje del Sistema');
                setMessage('Ya existe una empresa con este código para el país especificado');
                setShowAlert(true);
                setIsSaving(false);
                return;
            }
        }
        if (props.companyInfo.user.email !== email)
        {
            const userInfoFunction = httpsCallable(functions, 'getUserByEmail');
            const userInfo = await userInfoFunction({email: email});
            if (userInfo !== null)
            {
                setTitle('Mensaje del Sistema');
                setMessage('Ya existe un usuario con el email especificado');
                setShowAlert(true);
                setIsSaving(false);
                return;
            }
        }
        // Save company data
        const updateCompanyFunction = httpsCallable(functions, 'updateCompany');
        await updateCompanyFunction({
            id: props.companyInfo.company.id,
            name: name,
            code: code,
            city: cityId,
            employeerange: employeeRangeId,
            income: incomeId,
            industry: industryId
        });

        const updateUserFunction = httpsCallable(functions, 'updateUser');
        await updateUserFunction({
            id: props.companyInfo.user.id,
            name: userName,
            email: email,
            position: positionId
        });
        setIsSaving(false);

        setTitle('Mensaje del Sistema');
        setMessage('Los datos fueron actualizados satisfactoriamente');
        setShowAlert(true);
    }

    const closeHandler = e => {
        setShowAlert(false);
    }

    const classes = useStyles();

    const listCountries = props.countries.map(c => {
        return <MenuItem value={c.id}>{c.name}</MenuItem>;
    });

    const listCities = cities.map(c => {
        return <MenuItem value={c.id}>{c.name}</MenuItem>
    })

    const listEmployeeRanges = props.employeeRanges.map(e => {
        return <MenuItem value={e.id}>{e.name}</MenuItem>
    })

    const listIncomes = props.incomes.map(i => {
       return <MenuItem value={i.id}>{i.name}</MenuItem>
    });

    const listPositions = props.positions.map(p => {
        return <MenuItem value={p.id}>{p.name}</MenuItem>
    })

    const setIndustryHandler = (industryId) => {
        setIndustryId(industryId);
    }

    return <Fragment>
        <form className={classes.formControl} onSubmit={saveChangesHandler}>
        <div className={classes.section}>
            <TextField className={classes.formControl} required label="Codigo Único Tributario"
                       value={code} onChange={setCodeHandler}/>
        </div>
        <div className={classes.section}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Oficina Principal</InputLabel>
                        <Select value={countryId} onChange={setCountryHandler} required>
                            {listCountries}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Ciudad</InputLabel>
                        <Select value={cityId} onChange={setCityHandler} required>
                            {listCities}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
        <div className={classes.section}>
            <TextField className={classes.formControl} required label="Nombre de la Empresa"
                       value={name} onChange={setNameHandler}/>
        </div>
        <div className={classes.section}>
            <Typography variant="caption">A Qué se Dedica su Empresa</Typography>
            <Typography variante="body1" gutterBottom>Industrias</Typography>
            <ListIndustries selectedIndustry={industryId} industries={props.industries}
                onClick={setIndustryHandler}/>
        </div>
        <div className={classes.section}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Número de Personal</InputLabel>
                        <Select value={employeeRangeId} onChange={setEmployeeRangeHandler} required>
                            {listEmployeeRanges}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl className={classes.formControl}>
                        <InputLabel>Millones US$</InputLabel>
                        <Select value={incomeId} onChange={setIncomeHandler} required>
                            {listIncomes}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
        <div className={classes.section}>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField className={classes.formControl} required label="Persona de Contacto (Nombre)"
                               value={userName} onChange={setUserNameHandler}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField className={classes.formControl} required label="Correo"
                               value={email} onChange={setEmailHandler}/>
                </Grid>
            </Grid>
        </div>
        <div className={classes.section}>
            <FormControl className={classes.formControl}>
                <InputLabel>¿Que cargo cumple usted?</InputLabel>
                <Select value={positionId} onChange={setPositionHandler} required>
                    {listPositions}
                </Select>
            </FormControl>
        </div>
        <div className={classes.section}>
            <Button className={classes.button} type="submit" variant="contained" color="primary"
                    fullWidth>Guardar Cambios</Button>
            {isSaving && <LinearProgress/>}
        </div>
    </form>
        {showAlert && <Alert open={showAlert} title={title} message={message} onClose={closeHandler}/>}
    </Fragment>
}

export default CompanyDetails;