export const getFormatDateObject = (dateObject, locale = 'es') => new Date(dateObject._seconds * 1000).toLocaleDateString(locale, {
    // you can use undefined as first argument
    year: "numeric", month: "2-digit", day: "2-digit",
});

export const getDate = dateObject => new Date(dateObject._seconds * 1000);

export const getInitials = name => {
    if (name) {
        const parts = name.split(' ');
        if (parts.length === 1)
        {
            return parts[0][0].toUpperCase();
        }
        else if (parts.length >= 2)
        {
            return parts[0][0].toUpperCase() + parts[1][0].toUpperCase();
        }
        else {
            return 'NN';
        }
    } else {
        return 'NN'
    }
    
}
