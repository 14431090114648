import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import ImportInfo from "../components/bi/ImportInfo";

const BiImport = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Importaciones"/>
        <Container fixed>
            <ImportInfo/>
        </Container>
    </Fragment>
}

export default BiImport;