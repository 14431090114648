import {useState} from "react";
import firebaseApp from "../../firebase/firebaseApp";
import { getFunctions, httpsCallable } from 'firebase/functions';
import {Checkbox, FormControlLabel, Grid} from "@mui/material";

const functions = getFunctions(firebaseApp)

const ListAssociations = (props) => {
    const [selectedAssociations, setSelectedAssociations] = useState(props.selectedAssociations);

    const changeSelectedAssociationHandler = async e => {
        setSelectedAssociations({...selectedAssociations, [e.target.name] : e.target.checked});

        const updateSelectedAssociationFunction = httpsCallable(functions, 'updateSelectedGremio');
        await updateSelectedAssociationFunction({
            associationId: e.target.name,
            checked: e.target.checked
        });
    };

    return props.associations.map(si => {
        return <Grid item xs={12} md={12}><FormControlLabel control={<Checkbox name={si.id}/>} label={si.name}
                                                           checked={(selectedAssociations !== null && !!selectedAssociations[si.id]) ? selectedAssociations[si.id] : false}
                                                           onChange={changeSelectedAssociationHandler}/></Grid>
    });
}

export default ListAssociations;