import React, {useState} from 'react';
import clsx from 'clsx';
import {makeStyles} from '@mui/styles';
import {Box, Accordion, AccordionSummary, AccordionDetails} from '@mui/material';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import ShowChartOutlinedIcon from '@mui/icons-material/ShowChartOutlined';
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import ChromeReaderModeOutlinedIcon from '@mui/icons-material/ChromeReaderModeOutlined';
import InsertInvitationOutlinedIcon from '@mui/icons-material/InsertInvitationOutlined';
import TimelapseOutlinedIcon from '@mui/icons-material/TimelapseOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AirplanemodeActiveOutlinedIcon from '@mui/icons-material/AirplanemodeActiveOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import StarIcon from '@mui/icons-material/Star';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import {useSelector} from "react-redux";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import Icon from '@mui/material/Icon';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import {Link, NavLink} from 'react-router-dom';
import LockIcon from '@mui/icons-material/Lock';
import CardTravelIcon from '@mui/icons-material/CardTravel';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import DoneIcon from '@mui/icons-material/Done';
import {getInitials} from "../../util/util";
import YouTubeIcon from '@mui/icons-material/YouTube';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fab from '@mui/material/Fab';
import {
    AdminPanelSettingsOutlined,
    DiamondOutlined,
    FactoryOutlined,
    GroupsOutlined,
    StarOutline,
    SouthAmericaOutlined,
    TempleBuddhistOutlined,
    SearchOutlined
} from '@mui/icons-material';
import balanced from '../../img/balanced.svg';
import dashboard from '../../img/dashboard.png';

const drawerWidth = 260;

const randomImage = Math.floor(Math.random() * 7);

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& .MuiListItem-button.active':{
            background: 'rgb(126 131 241 / 13%)'
        }
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        overflowY: 'scroll !important',
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3, 0, 0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        overflowY: 'scroll !important'
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    listSubHeader: {
        backgroundColor: '#ffffff'
    },
    toolbar: {
        width: `calc(100% - ${drawerWidth}px)`,
        backgroundColor: '#112091',
        marginLeft: "auto"
    },
    title: {
        flexGrow: 1,
    },
    containerLogo: {
        display: "flex",
        justifyContent: "center",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem"
    },
    logoAlaxs: {
        width: "200px"
    },
    userInfo: {
        height: "176px",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url('https://storage.googleapis.com/alaxs-0003.appspot.com/${randomImage}.png')`,
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        justifyContent: "center"
    },
    initials: {
        color: "primary",
        width: "50px",
        height: "50px",
        backgroundColor: "white",
        borderRadius: "50%",
        fontWeight: "bold",
        fontSize: "1rem",
        marginBottom: "5px",
        display:"flex",
        justifyContent: "center",
        alignItems: "center"
    },
    companyName: {
        color: "white",
        fontSize: "0.8rem"
    },
    userEmail: {
        color: "white",
        fontSize: "0.8rem"
    },
    menuTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    accordionSummary:{
        '& .MuiAccordionSummary-content':{
            margin: 0,
            justifyContent: "space-between",
            alignItems: "center"
        }
    },
    icon:{
        height: '22px',
    }
}));

export default function LayoutClient(props) {
    const classes = useStyles();
    const open = true;
    const [openVentas, setOpenVentas] = useState(false);
    const [openEventos, setOpenEventos] = useState(false);
    const [openPartners, setOpenPartners] = useState(false);

    // Top Menu Travel
    const [anchorTravel, setAnchorTravel] = useState(false);

    // Top Menu Business
    const [anchorBusiness, setAnchorBusiness] = useState(false);

    // Top Menu Style
    const [anchorStyle, setAnchorStyle] = useState(false);

    // Top Menu Balance
    const [anchorBalance, setAnchorBalance] = useState(false);

    // Top Menu Account
    const [anchorAccount, setAnchorAccount] = useState(false);

    const userAdmin = useSelector(state => state.user.admin);
    const userEmail = useSelector(state => state.user.email);
    const companyName = useSelector(state => state.user.companyName);
    const userName = useSelector(state => state.user.name);

    const initials = getInitials(userName);
    const [expanded, setExpanded] = useState("panel1"); //Accordion

    const handleChangeAccordion = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        setOpenVentas(false);
        setOpenEventos(false);
        setOpenPartners(false);
    };

    const handleVentas = () => {
        setOpenVentas(!openVentas);
    }

    const handleOpenTravel = event => {
        setAnchorTravel(event.currentTarget);
    }

    const handleCloseTravel = () => {
        setAnchorTravel(null);
    }

    const handleOpenStyle = event => {
        setAnchorStyle(event.currentTarget);
    }

    const handleCloseStyle = () => {
        setAnchorStyle(null);
    }

    const handleOpenBusiness = event => {
        setAnchorBusiness(event.currentTarget);
    }

    const handleCloseBusiness = () => {
        setAnchorBusiness(null);
    }

    const handleOpenBalance = event => {
        setAnchorBalance(event.currentTarget);
    }

    const handleCloseBalance = () => {
        setAnchorBalance(null);
    }

    const handleOpenAccount = (event) => {
        setAnchorAccount(event.currentTarget);
    };

    const handleCloseAccount = () => {
        setAnchorAccount(null);
    };

    const handleQuestion = () => {

    }

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <AppBar position="fixed">
                <Toolbar className={classes.toolbar}>
                    <Typography variant="h6" noWrap className={classes.title}>
                        MyALAXS
                    </Typography>
                    <Box style={{display: 'flex', alignItems: 'center'}}>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            component={Link}
                            to={'/home'}
                        >
                            <HomeOutlinedIcon/>
                        </IconButton>
                        <IconButton
                          component={Link}
                          to={'/dashboard'}
                        >
                            <img className={classes.icon} src={dashboard} alt='icon'/>
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            component={Link}
                            to="/zendesk"
                        >
                            <Icon>support_agent</Icon>
                        </IconButton>
                        <IconButton
                            color="inherit"
                            component={Link}
                            to="/think-tank"
                        >
                            <Icon>psychology</Icon>
                        </IconButton>
                        <Divider style={{background: "white"}} orientation="vertical" flexItem />
                        <IconButton
                          onClick={handleOpenBusiness}
                          color="inherit"
                        >
                            <BusinessCenterOutlinedIcon/>
                        </IconButton>
                        <Menu
                          id="menu-business"
                          anchorEl={anchorBusiness}
                          anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                          }}
                          keepMounted
                          transformOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                          }}
                          open={anchorBusiness}
                          onClose={handleCloseBusiness}
                        >
                            <MenuItem onClick={handleCloseBusiness} component={NavLink} to="/buying">
                                <ListItemIcon>
                                    <FactCheckOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Requerimiento"/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBusiness} component={NavLink} to="/sale/new">
                                <ListItemIcon>
                                    <RecordVoiceOverOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Ventas"/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBusiness} component={NavLink} to="/trading">
                                <ListItemIcon>
                                    <ShoppingCartOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Trading Platform"/>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBusiness} component={NavLink} to="/partners">
                                <ListItemIcon>
                                    <GroupsOutlined/>
                                </ListItemIcon>
                                <ListItemText primary="Partners"/>
                            </MenuItem>
                        </Menu>
                        <IconButton
                            onClick={handleOpenTravel}
                            color="inherit"
                        >
                            <TempleBuddhistOutlined/>
                        </IconButton>
                        <Menu
                            id="menu-travel"
                            anchorEl={anchorTravel}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={anchorTravel}
                            onClose={handleCloseTravel}
                        >
                            <MenuItem onClick={handleCloseTravel} component={NavLink} to="/ferias/proximos">
                                <ListItemIcon><AccessAlarmOutlinedIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Ferias
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseTravel} component={NavLink} to="/search">
                                <ListItemIcon><SearchOutlined/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Búsqueda
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseTravel} component={NavLink} to="/suppliers">
                                <ListItemIcon><FactoryOutlined/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Suppliers
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseTravel} component={NavLink} to="/universities">
                                <ListItemIcon> <SchoolOutlinedIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Universidades
                                </Typography>
                            </MenuItem>
                        </Menu>
                        <IconButton
                            onClick={handleOpenStyle}
                            color="inherit"
                        >
                            <SouthAmericaOutlined/>
                        </IconButton>
                        <Menu
                            id="menu-style"
                            anchorEl={anchorStyle}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={anchorStyle}
                            onClose={handleCloseStyle}
                        >
                            <MenuItem onClick={handleCloseStyle} component={NavLink} to="/assets">
                                <ListItemIcon><DiamondOutlined/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Assets
                                </Typography>
                            </MenuItem>
                            <MenuItem component={NavLink} to="/webinars">
                                <ListItemIcon>
                                    <AccountBalanceWalletOutlinedIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Webinars"/>
                            </MenuItem>
                            <MenuItem component={NavLink} to="/talent">
                                <ListItemIcon>
                                    <StarOutline/>
                                </ListItemIcon>
                                <ListItemText primary="Talento"/>
                            </MenuItem>
                        </Menu>
                        <Divider style={{background: "white"}} orientation="vertical" flexItem />
                        <IconButton
                            onClick={handleOpenBalance}
                            color="inherit"
                        >
                            <img className={classes.icon} src={balanced} alt='icon'/>
                        </IconButton>
                        <Menu
                            id="menu-group"
                            anchorEl={anchorBalance}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={anchorBalance}
                            onClose={handleCloseBalance}
                        >
                            <MenuItem onClick={handleCloseBalance} component={Link} to="/legal/terms-conditions">
                                <ListItemIcon><CardTravelIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    T & C <i className="fa-regular fa-scale-balanced"></i>
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBalance} component={Link} to="/legal/data-privacy">
                                <ListItemIcon><LockIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Data Privacy
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBalance} component={Link} to="/legal/conduct">
                                <ListItemIcon><HowToRegIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Conduct
                                </Typography>
                            </MenuItem>
                            <MenuItem onClick={handleCloseBalance}><ListItemIcon><DoneIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Compliance (pronto)
                                </Typography>
                            </MenuItem>
                        </Menu>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleQuestion}
                            color="inherit"
                            component={Link}
                            to="/videos"
                        >
                            <YouTubeIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            component={Link}
                            to="/faq"
                        >
                            <HelpOutlineOutlinedIcon/>
                        </IconButton>
                        <Divider style={{background: "white"}} orientation="vertical" flexItem />
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenAccount}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorAccount}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={anchorAccount}
                            onClose={handleCloseAccount}
                        >
                            <MenuItem onClick={handleCloseAccount} component={Link} to="/profile"><ListItemIcon><PersonOutlineOutlinedIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Mi Perfil
                                </Typography>
                            </MenuItem>
                            {userAdmin &&
                            <MenuItem button>
                                <ListItemIcon><AdminPanelSettingsOutlined/></ListItemIcon>
                                <Typography variant="inherit" noWrap>Administrador</Typography>
                            </MenuItem>
                            }
                            <MenuItem onClick={handleCloseAccount} component={Link} to="/updateprofile"><ListItemIcon><EditOutlinedIcon/></ListItemIcon> <Typography
                                variant="inherit" noWrap>
                                Actualizar Mis Datos
                            </Typography></MenuItem>
                            <MenuItem onClick={handleCloseAccount}><ListItemIcon><AttachMoneyOutlinedIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Cámaras & Asoc.
                                </Typography></MenuItem>
                            <MenuItem onClick={handleCloseAccount} component={Link} to="/changepassword"><ListItemIcon><VpnKeyOutlinedIcon/></ListItemIcon>
                                <Typography variant="inherit" noWrap>
                                    Cambiar Password
                                </Typography></MenuItem>
                            <Divider/>
                            <MenuItem onClick={handleCloseAccount} component={Link} to="/logout"><ListItemIcon><ExitToAppOutlinedIcon color="secondary"/></ListItemIcon>
                                <Typography variant="inherit" noWrap color="secondary">
                                    Salir del Sistema
                                </Typography></MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                id='menuVertical'
                sx={{position: 'relative'}}
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={open}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Divider/>
                <div className={classes.containerLogo}>
                    <img className={classes.logoAlaxs} src="https://storage.googleapis.com/alaxs-0003.appspot.com/logo.jpg" alt="Logo ALAXS"/>
                </div>
                <Divider/>
                <div>
                    <div className={classes.userInfo}>
                        <div className={classes.initials}>{initials}</div>
                        <div className={classes.companyName}>{companyName}</div>
                        <div className={classes.userEmail}>{userEmail}</div>
                    </div>
                </div>
                <Accordion style={{margin: 0}} expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon color='primary'/>}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                        <div className="flex">
                            <Typography variant="button" color="primary" className={classes.listSubHeader}>
                                MARKETPLACE
                            </Typography>
                            <StarIcon sx={{fontSize: "14px", marginLeft:"8px"}} color="error"/>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <ListItem button component={NavLink} to="/buying">
                                <ListItemIcon>
                                    <FactCheckOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Requerimiento"/>
                                <StarIcon sx={{fontSize: "14px"}} color="error"/>
                            </ListItem>
                            {/*
                            <ListItem button onClick={handleCompras}>
                                <ListItemIcon>
                                    <ShoppingCartOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Requerimiento"/>
                                {openCompras ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
                            </ListItem>
                            <Collapse sx={{paddingLeft: '20px'}} in={openCompras} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button component={NavLink} className={classes.nested} exact to="/buying">
                                        <ListItemIcon>
                                            <AddShoppingCartOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Nuevo"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/buying/process">
                                        <ListItemIcon>
                                            <DonutLargeOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="En Proceso"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/buying/historical">
                                        <ListItemIcon>
                                            <ShowChartOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Históricos"/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            */}
                            <ListItem button onClick={handleVentas}>
                                <ListItemIcon>
                                    <RecordVoiceOverOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Ventas"/>
                                {openVentas ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
                            </ListItem>
                            <Collapse sx={{paddingLeft: '20px'}} in={openVentas} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/sale/new">
                                        <ListItemIcon>
                                            <AddShoppingCartOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Nueva"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/sale/process">
                                        <ListItemIcon>
                                            <DonutLargeOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="En Proceso"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/sale/historical">
                                        <ListItemIcon>
                                            <ShowChartOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Históricas"/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button component={NavLink} to="/trading">
                                <ListItemIcon>
                                    <ShoppingCartOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Trading Platform"/>
                            </ListItem>
                            <ListItem button onClick={() => setOpenPartners(!openPartners)}>
                                <ListItemIcon>
                                    <GroupsOutlined color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Partners"/>
                                {openPartners ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
                            </ListItem>
                            <Collapse sx={{paddingLeft: '20px'}} in={openPartners} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/banks">
                                        <ListItemIcon>
                                            <AccountBalanceOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Bancos"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/transportation">
                                        <ListItemIcon>
                                            <AirplanemodeActiveOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Transportes"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/insurance">
                                        <ListItemIcon>
                                            <HttpsOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Seguros"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/agents">
                                        <ListItemIcon>
                                            <PolicyOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Agentes de Aduana"/>
                                    </ListItem>
                                </List>
                            </Collapse>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Divider style={{background: '#fefefe'}}/>
                <Accordion style={{margin: 0}}  expanded={expanded === 'panel2'} onChange={handleChangeAccordion('panel2')}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon color='primary'/>}
                      aria-controls="panel2a-content"
                      id="panel2a-header">
                        <Typography variant="button" color="primary" className={classes.listSubHeader} >ASIA Y EL MUNDO</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <ListItem button onClick={() => setOpenEventos(!openEventos)}>
                                <ListItemIcon>
                                    <EventNoteIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Ferias"/>
                                {openEventos ? <ExpandLess color="primary"/> : <ExpandMore color="primary"/>}
                            </ListItem>
                            <Collapse sx={{paddingLeft: '20px'}} in={openEventos} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/ferias/proximos">
                                        <ListItemIcon>
                                            <ChromeReaderModeOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Próximas"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/events/todos">
                                        <ListItemIcon>
                                            <InsertInvitationOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Todas"/>
                                    </ListItem>
                                </List>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested} component={NavLink} to="/events/historical">
                                        <ListItemIcon>
                                            <TimelapseOutlinedIcon color="primary"/>
                                        </ListItemIcon>
                                        <ListItemText primary="Históricos"/>
                                    </ListItem>
                                </List>
                            </Collapse>
                            <ListItem button component={NavLink} to="/search">
                                <ListItemIcon>
                                    <SearchOutlined color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Búsqueda"/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/suppliers">
                                <ListItemIcon>
                                    <FactoryOutlined color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Suppliers"/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/universities">
                                <ListItemIcon>
                                    <SchoolOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Universidades"/>
                            </ListItem>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Divider style={{background: '#fefefe'}}/>
                <Accordion style={{margin: 0}}  expanded={expanded === 'panel3'} onChange={handleChangeAccordion('panel3')}>
                    <AccordionSummary
                      className={classes.accordionSummary}
                      expandIcon={<ExpandMoreIcon color='primary'/>}
                      aria-controls="panel3a-content"
                      id="panel3a-header">
                        <Typography variant="button" color="primary" className={classes.listSubHeader} >LATINOAMERICA</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <ListItem button component={NavLink} to="/assets">
                                <ListItemIcon>
                                    <DiamondOutlined color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Assets"/>
                            </ListItem>
                            <ListItem button component={NavLink} to="/webinars">
                                <ListItemIcon>
                                    <AccountBalanceWalletOutlinedIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Webinars"/>
                            </ListItem>
                            <ListItem component={NavLink} to="/talent">
                                <ListItemIcon>
                                    <StarOutline color="primary"/>
                                </ListItemIcon>
                                <ListItemText primary="Talento"/>
                            </ListItem>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Divider style={{background: '#fefefe'}}/>
                <ListItem sx={{top: 20, marginBottom: 'auto'}}>
                    <Fab button color="primary" component={Link} to="/loyalty-club">
                        <LoyaltyIcon color="white"/>
                    </Fab>
                </ListItem>
                <ListItem sx={{ marginTop: '30px' }} component={Link} to="/logout">
                    <ListItemIcon>
                        <ExitToAppOutlinedIcon color="secondary"/>
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={<Typography type="body2" color="secondary">Salir del Sistema</Typography>}
                    />
                </ListItem>
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open,
                })}
            >
                <div className={classes.drawerHeader}/>
                {props.children}
            </main>
        </div>
    );
}
