import {Paper, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell} from "@mui/material";

import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#fff"
    },
    paperDark: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        backgroundColor: "#eee"
    }
}));

const FamilyCategory = ({product}) => {
    const {family, category, filters} = product
    const classes = useStyles();

    return <Box className={classes.paperDark}>
        <Typography variant="button">III. FAMILIA / CATEGORÍA</Typography>
        <Paper className={classes.paper}>
            <Typography variant="subtitle2">Familia:</Typography>
            <Typography variant="body2" gutterBottom>{family ?? "-"}</Typography>
            <Typography variant="subtitle2">Category:</Typography>
            <Typography variant="body2" gutterBottom>{category ?? "-"}</Typography>
            <Typography variant="subtitle2">Filtros:</Typography>
            {filters?.map((filter, filterIndex) => (
                <TableContainer component={Paper} >
                    {(filter?.show === undefined ? true : filter?.show) &&
                        <Table key={"filter" + filterIndex}>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{width: "40%"}}>
                                        {filter.name}
                                    </TableCell>
                                    <TableCell>
                                        <Typography sx={{textAlign: "left"}} variant="body2">{filter?.option}</Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                        </Table>
                    }
                    {filter?.subfilters?.map((subFilter, subFilterIndex) => (
                        <>
                            <Table key={"subfilter" + subFilterIndex + filterIndex}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{width: "40%"}}>
                                            {"Filtro: " + filter.name} <br/>
                                            {"Subfiltro: " + subFilter.name}
                                        </TableCell>
                                        <TableCell>
                                            <Typography sx={{textAlign: "left"}} variant="body2">{subFilter?.option}</Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                            </Table>
                            {subFilter?.subfilters?.map((nestedSubFilter, nestedSubFilterIndex) => (
                                <Table key={"subfil" + filterIndex + nestedSubFilterIndex + subFilterIndex}>
                                    <TableHead key={'subFilterName' + filterIndex + nestedSubFilterIndex + subFilterIndex}>
                                        <TableRow>
                                            <TableCell sx={{width: "40%"}}>
                                                {"Filtro: " + filter.name}
                                                {"Subfiltro de " + subFilter.name + ": " + nestedSubFilter.name}
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body2">{nestedSubFilter?.option}</Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                </Table>
                            ))}
                        </>
                        )
                    )}
                </TableContainer>
            ))}
        </Paper>
    </Box>
}

export default FamilyCategory;