import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState, Fragment} from "react";
import SubHeader from '../components/common/SubHeader';

const LoyaltyClubFaq = () => {
  const [expanded, setExpanded] = useState('panel1');
  
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const ComponentAccordion = (props) => {
    const {numPanel = '', title = '' , content = ''} = props;
    
    return <Accordion expanded={expanded === numPanel} onChange={handleChangeAccordion(numPanel)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
        <Typography variant="button" color="primary">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>{content}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }
  
  return <Fragment>
    <SubHeader parentUrl="/loyalty-club" parentTitle="Loyalty club" title='faq'/>
    <Container fixed>
      <ComponentAccordion numPanel={1}
        title={'1. ¿QUÉ ES UN LOYALTY CLUB PARA ALAXS?'}
        content={
          <Typography>
            Nuestro Loyalty Club tiene como objetivo establecer una relación estrecha entre el cliente y
            nuestra empresa. Es por esto que el usuario a medida es recompensado con una serie de
            ventajas que son dadas por ser miembro exclusivo de ALAXS.
            <p>
              Es necesario aclarar que nuestro Loyalty Club no entrega dinero ni reserva abonos a sus
              usuarios, sino que registra operaciones de trading y servicios para la empresa así como Puntos
              ALAXS para sus usuarios. 
            </p>
            
          </Typography>
        }
      />
      <ComponentAccordion 
        numPanel={2}
        title={'2. ¿QUÉ REGISTRA NUESTRO LOYALTY CLUB?'}
        content={
          <Typography>
            Nuestro Loyalty Club registra la actividad de los clientes en la plataforma y en los servicios que ofrecemos. Por cada uso de servicios registramos diferentes parámetros (todos de muy fácil medición y comprobación) para intercambiar los mismos por Puntos ALAXS. Dichos puntos funcionan de manera muy similar a las millas de programas de lealtad de bancos y aerolíneas; cuando se acumula un cierto lote de puntos, éstos se pueden cambiar por diversos premios.
          </Typography>
        }
      />
      <ComponentAccordion 
        numPanel={3}
        title={'3. ¿QUÉ CATEGORIAS TIENE EL PROGRAMA? '}
        content={
          <Typography>
            <p>El Loyalty Club de ALAXS tiene cinco (5) niveles: gold, platinum, diamond, sapphire, centurion. Cada uno otorga diferentes ventajas a nuestros usuarios.</p>
            Los Puntos ALAXS son de los usuarios y tanto sus acciones, el trading de productos y la compra de servicios los benefician. Por su parte, las empresas obtienen beneficios directos a través de mayores descuentos en su trading y/o servicios, así como acceso a otros servicios, reuniones de trabajo, webinars e información comercial relevante, entre otros.
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={4}
        title={'4. ¿CÓMO SUBO DE CATEGORIA?'}
        content={
          <Typography>
            Tanto los usuarios como las empresas pueden subir de categoría aumentando el consumo de servicios de nuestra plataforma, realizando mayor trading y compartiendo el link de nuestro sitio web con compañeros de trabajo, profesionales afines, grupos de trabajo, asociaciones y cámaras, colegas de trabajo, industrias afines, familiares y amigos.
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={5}
        title={'5. ¿CUÁNDO INTERCAMBIO MIS PUNTOS?'}
        content={
        <Typography>
          Durante la primera etapa en el Loyalty Club, unos 6 a 8 meses aproximadamente, se registrará su actividad de manera orgánica. Nos referimos a que en estos meses no debe esperar mayores cambios, sorpresas, premios u otros. Una vez cumplido este plazo, el sistema se comunicará con todos los usuarios para darles mayores detalles de los puntos, del intercambio de los mismos, los tiempos a tener en cuenta y premios esperados.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={6}
        title={'6. ¿CÓMO INTERCAMBIO MIS PUNTOS?'}
        content={<Typography>
          Después de transcurridos los meses iniciales indicados -desde el inicio en nuestra plataforma- todos los usuarios recibirán una comunicación de nuestra parte para indicarles qué pasos siguen, los tiempos para los mismos y qué pueden esperar nuestros clientes de nosotros.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={7}
        title={'7. ¿EXPIRAN MIS PUNTOS?'}
        content={<Typography>
          Los puntos no expiran. De todas formas nuestra empresa siempre estará interesada en que nuestros clientes vayan usando y/o consumiendo regularmente sus puntos y puedan gozar de los beneficios que ofrece nuestro Loyalty Club.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={8}
        title={'8. ¿CÓMO CONTABILIZAN MIS PUNTOS VS. LOS DE MI EMPRESA?'}
        content={<Typography>
          Los Puntos ALAXS sólo son adjudicados a los usuarios, no a las empresas. El beneficio de las empresas se dará en mayores porcentajes de beneficios a retener como parte de nuestro modelo de negocio. Ese detalle se les dará a conocer en próximos meses y en función al avance de su empresa en nuestra plataforma.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={9}
        title={'9. ¿QUÉ PASA CON MIS PUNTOS SI CAMBIO DE EMPRESA?'}
        content={<Typography>
          Se trasladan como usuario, tomando como referencia su documento de identidad, a la nueva empresa. Bajo la premisa del punto siete (7) de este listado, siempre desearemos que pueda usar previamente sus puntos, pero habiendo tramitado este cambio con tiempo podrá trasladar estos puntos y sus beneficios bajo un proceso interno que le haremos saber.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={10}
        title={'10. ¿PUEDO VER MIS PUNTOS EN CONTEXTO DE OTROS USUARIOS?'}
        content={<Typography>
          Como parte de los reportes de sus Puntos ALAXS, todo usuario podrá ver de qué fuentes obtiene sus puntos, ver el progreso de los mismos y tener un contexto de cómo van los usuarios de industrias y empresas similares.
        </Typography>}
      />
    </Container>
  </Fragment>
}

export default LoyaltyClubFaq;
