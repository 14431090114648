import {
    Breadcrumbs,
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Container,
    Grid
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
    }
}));

const SearchInnovation = () => {
    const classes = useStyles();
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>asia </Typography>
            <Link to="/search">búsqueda</Link>
            <Typography>innovación</Typography>
        </Breadcrumbs><br/>
        <Container fixed>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <Card className={classes.card}>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://storage.googleapis.com/alaxs-0003.appspot.com/en_mi_linea_negocio3.png"
                            title="En mi línea de negocio"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                En mi línea de negocio
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary">
                            Ingresar
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://storage.googleapis.com/alaxs-0003.appspot.com/en_otras_lineas2.png"
                            title="En otras líneas"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                En otras líneas, productos y servicios.
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary">
                            Ingresar
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://storage.googleapis.com/alaxs-0003.appspot.com/via_ferias2.png"
                            title="Vía ferías"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Vía ferias, congresos y eventos
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary">
                            Ingresar
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
            <Grid item xs={12} md={6}>
                <Card>
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="140"
                            image="https://storage.googleapis.com/alaxs-0003.appspot.com/via_capacitacion2.png"
                            title="Vía capacitación"
                        />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                Vía capacitación
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary">
                            Ingresar
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </Container>
    </div>
}

export default SearchInnovation;
