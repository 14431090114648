import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  TextField,
  Typography
} from '@mui/material';
import {useState, useEffect} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import {makeStyles} from '@mui/styles';
import clsx from 'clsx';
import iconShared from './../../img/icon_shared.svg';
import firebaseApp from '../../firebase/firebaseApp';
import {useSelector} from 'react-redux';
import { getFunctions, httpsCallable } from 'firebase/functions';

const useStyles = makeStyles(() => ({
  fixed: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '30px',
    '& button': {
      marginBottom: '30px'
    }
  },
  icon: {
    height: '28px',
    marginRight: '12px'
  },
  button: {
    background: '#6200ea !important',
    padding: '16px 18px !important',
    borderRadius: '40px !important'
  }
}));

const functions = getFunctions(firebaseApp)

const Share = (props) => {
  const {className, variant} = props;
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogThank, setShowDialogThank] = useState(false);
  const [emailsReferidos, setEmails] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const email = useSelector(state => state.user.email);
  const name = useSelector(state => state.user.name);
  
  const handleAdd = () => {
    setEmails([...emailsReferidos, inputValue])
    setInputValue("");
  };
  
  const handleChange = (value) => {
    setInputValue(value);
  }
  
  const handleClose = () => {
    setShowDialog(false);
    setEmails([]);
  }
  
  useEffect(() => {
    if(showDialogThank){
      const timer = setTimeout(() => setShowDialogThank(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showDialogThank]);
  
  
  const sendMailing = e => {
    const sendMessage = httpsCallable(functions, 'sendMessage');
    sendMessage({
      to: emailsReferidos,
      cc: email,
      subject: 'Mensaje de MyALAXS',
      replyTo: email,
      html: `<p>Hola, </p>
                <p>Llegamos a Uds. gracias a una recomendación de ${name}.</p>
                <p>Somos ALAXS, el marketplace B2B de productos industriales entre nuestra región y Asia.</p>
                <p>Los invitamos a conocer más sobre nosotros y nuestros beneficios en este <a target="_blank" href="https://www.youtube.com/watch?v=JvpsMIskFtA&t=7s">video</a>.</p>
                <p>Visítennos en <a target="_blank" href="https://alaxs.com/">ALAXS.com</a>; estaremos gustosos de responder sus preguntas y que su empresa pueda crecer con nosotros.</p><p></p>
                <p>ALAXS Team</p>
                `
    }).then(() => {
      handleClose();
      setShowDialogThank(true);
    });
  }
  
  return <div className={clsx(className, {[classes.fixed]: variant === 'fixed'})}>
    <Button variant="contained" className={classes.button} onClick={() => setShowDialog(true)}>
      <img alt="shared" className={classes.icon} src={iconShared}/>
      <Typography sx={{fontSize: '14px'}}><strong> Refiere nuestra web</strong></Typography>
    </Button>
    <Dialog open={showDialog}>
      <DialogTitle>
        Comparte nuestro portal con <br/>tus referidos
        <IconButton
          onClick={() => handleClose()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Agrega los correos a los que deseas compartir
        </DialogContentText>
        <Paper
          elevation={0}
          component="form"
          sx={{p: '18px 4px', display: 'flex', alignItems: 'center', width: 400}}>
          <TextField
            fullWidth
            autoFocus
            id="name"
            label="Correo electrónico"
            type="email"
            size="small"
            value={inputValue}
            onChange={(e) => handleChange(e.target.value)}
          />
          <Button variant="outlined" onClick={() => handleAdd()}>Agregar</Button>
        </Paper>
        {emailsReferidos?.map((email, i) => (
          <FormGroup key={`fg-${i}`}>
            <FormControlLabel control={<Checkbox defaultChecked/>} label={email}/>
          </FormGroup>
        ))}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => sendMailing()} autoFocus>
          Referir
        </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={showDialogThank}>
      <DialogTitle><br/>
        <IconButton>
          <CheckCircleOutlineOutlinedIcon color="primary"/>
        </IconButton>
        Gracias por referir
        <IconButton
          onClick={() => setShowDialogThank(false)}
          sx={{
            position: 'absolute',
            right: 4,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          El envío de correo fue exitoso.<br/>
        </DialogContentText><br/>
      </DialogContent>
    </Dialog>
  </div>
}

Share.defaultProps = {
  variant: '',
  className: ''
}

export default Share;
