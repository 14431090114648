import { setCompany } from './companySlice';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../firebase/firebaseApp';

const functions = getFunctions(firebaseApp);

export const getCompany = () => {
    return async (dispatch) => {
        const getCompanyInfoFunction = httpsCallable(functions, 'getCompanyInfo');
        getCompanyInfoFunction().then(result => {
            dispatch(setCompany(result.data))
        })
        .catch(error => {
            console.log(error);
        })
    }
}
