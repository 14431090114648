import React, {useEffect, useState} from "react";
import {
  Breadcrumbs,
  Container,
  CircularProgress
} from "@mui/material";
import Typography from "@mui/material/Typography";
import firebaseApp from '../firebase/firebaseApp';
import { getFunctions, httpsCallable } from 'firebase/functions'
import GridPosts from '../components/thinktank/GridPosts';
import {Link} from 'react-router-dom';
import MenuThinktank from '../components/thinktank/menuThinktank';

const functions = getFunctions(firebaseApp)

const Posts = () => {
  const [dataPost, setDataPost] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchPosts = async () => {
      const listPosts = httpsCallable(functions, 'listPosts');
      const {data} = await listPosts();
      setDataPost(data);
      setIsLoading(false)
    }
    fetchPosts();
  }, []);
  
  return <div className='think-tank' style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Link to="/think-tank">THINK TANK</Link>
      <Typography>POSTS</Typography>
    </Breadcrumbs><br/><br/>
    <Container fixed>
      <MenuThinktank/>
      <div className="featured-grid-all post-mansory">
        {isLoading ? <CircularProgress/> :
          dataPost.map((post, i) =>
            <GridPosts key={`gp-${i}`} data={dataPost[i]}/>)
        }
      </div>
        <br/><br/><br/>
    </Container>
  </div>
}

export default Posts;
