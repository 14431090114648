import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import companyReducer from './companySlice';
import postReducer from './postSlice';
import authSlice from "./authSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        company: companyReducer,
        post: postReducer,
        auth: authSlice
    }
})
