import {Card, CardContent, CardHeader} from "@mui/material";
import {makeStyles} from '@mui/styles';
import {Fragment} from "react";

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%"
    },
    card: {
        marginBottom: theme.spacing(4)
    }
}));

const OpportunityInfo = () => {
    const classes = useStyles();
    return <Fragment><Card className={classes.card}>
        <CardHeader title="Oportunidades de exportaciones Rubro: Alimentos (2016 - 2018)" />
        <CardContent>
            <img className={classes.image} src="https://storage.googleapis.com/alaxs-0003.appspot.com/tt_5.png"
                 alt="Ratios"/>
        </CardContent>
    </Card>
        <Card>
            <CardHeader title="Oportunidades de exportaciones Rubro: Alimentos (2016 - 2018)" />
            <CardContent>
                <img className={classes.image} src="https://storage.googleapis.com/alaxs-0003.appspot.com/tt_6.png"
                     alt="Ratios"/>
            </CardContent>
        </Card>
    </Fragment>
}

export default OpportunityInfo;