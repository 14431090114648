import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import Menu from '../components/bi/Menu';
import {Fragment} from "react";

const Bi = () => {
    return <Fragment>
        <SubHeader title="Business Intelligence"/>
        <Container fixed>
            <Menu/>
        </Container>
    </Fragment>
}

export default Bi;