import React from 'react';
import styles from './LayoutAuth.module.css';
import alaxs from '../../img/alaxs.gif';

const LayoutAuth = (props) => {
  return <div className={styles.hero}>
    <div className={styles.container}>
      <div className={styles.logo}>
        <img src={alaxs} alt="Logo"/>
      </div>
    </div>
    <div className={styles.container}>
      <div className={styles.left}>
        <h2 className={styles.text}>
          CONECTAMOS <br/> OPORTUNIDADES B2B
          <span>ASIA &amp; LATAM IN ONE PORTAL</span>
        </h2>
      </div>
      <div className={styles.right}>
        <div className={styles.form}>
          {props.children}
        </div>
      </div>
    </div>
  </div>
}

export default LayoutAuth;
