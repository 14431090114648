import {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import firebaseApp from '../firebase/firebaseApp';
import {getFunctions, httpsCallable} from 'firebase/functions';
import {
  Grid,
  Container,
  Typography,
  Breadcrumbs,
  Paper,
  FormControl,
  LinearProgress,
  Divider
} from "@mui/material";
import {makeStyles} from '@mui/styles';
import React from 'react';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

const functions = getFunctions(firebaseApp)

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    padding: '10px 20px',
    '& span': {
      fontSize: '14px'
    }
  },
  table: {
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '10px 8px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.08)',
      verticalAlign: 'top'
    },
    '& th': {
      background: '#e3f2fd',
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 500
    }
  }
}));

const SuppliersByFamily = () => {
  const {id} = useParams();
  const [isLoading, setIsLoading] = useState(true)
  const [supplier, setSupplier] = useState([]);
  const [supplierCurrent, setSupplierCurrent] = useState(null);
  const [selectedSupplier, setSelectedSupplier] = useState('');
  const classes = useStyles();
  
  useEffect(() => {
    const fetchData = async () => {
      const listSuppliers = httpsCallable(functions, 'listSuppliersByFamily');
      const {data} = await listSuppliers({name: id.toUpperCase()});
      setSupplier(data);
      setSupplierCurrent(data[0] || null);
      setSelectedSupplier(data[0]?.name || []);
      setIsLoading(false)
    }
    fetchData();
  }, [id]);
  
  const showSupplierHandler = (e) => {
    const {value, dataset} = e.target;
    const indexSupplier = dataset.index;
    setSelectedSupplier(value);
    setSupplierCurrent(supplier[indexSupplier]);
  }
  
  return <div style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Link to="/suppliers">Suppliers</Link>
      <Typography>{id}</Typography>
    </Breadcrumbs><br/><br/>
    <Container fixed>
      <Grid container item xs={12} spacing={4}>
        {isLoading &&
          <div>
            Obteniendo información ...
            <LinearProgress/>
          </div>
        }
        {selectedSupplier.length !== 0 &&
          <>
            <Grid item xs={4}>
              <Paper variant="outlined">
                <FormControl>
                  <FormLabel id="demo-controlled-radio-buttons-group">
                      <div style={{padding: '20px 20px 15px'}}><b>Familia: </b>{id.toUpperCase()}</div>
                      <Divider/><br/>
                    <div style={{padding: '0 20px 10px'}}>Elige un Supplier:</div>
                  </FormLabel>
                  <RadioGroup
                    className={classes.radioGroup}
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={selectedSupplier.indexOf('(') >= 0 ? selectedSupplier.substring(0, selectedSupplier.indexOf('(') - 1) : selectedSupplier}
                    onChange={showSupplierHandler}>
                    {supplier.map((s, i) =>
                      (<FormControlLabel data={i} key={s.name} value={s.name} id={i} label={s.name} control={
                        <Radio
                          inputProps={{'data-index': i}}/>}>{s.name.indexOf('(') >= 0 ? s.name.substring(0, s.name.indexOf('(') - 1) : s.name}</FormControlLabel>)
                    )}
                  </RadioGroup>
                </FormControl>
              </Paper>
              <br/>
            </Grid>
            <Grid item xs={8}>
              <Paper variant='outlined'>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <th>SUPPLIER</th>
                      <td>{supplierCurrent?.name}</td>
                    </tr>
                    <tr>
                      <th>PROVINCE</th>
                      <td>{supplierCurrent?.province}</td>
                    </tr>
                    <tr>
                      <th>CITY</th>
                      <td>{supplierCurrent?.city}</td>
                    </tr>
                    <tr>
                      <th>ZIP CODE</th>
                      <td>{supplierCurrent?.zipCode}</td>
                    </tr>
                    <tr>
                      <th>PORTS</th>
                      <td>{supplierCurrent?.ports}</td>
                    </tr>
                    <tr>
                      <th>ADDRESS</th>
                      <td>{supplierCurrent?.address}</td>
                    </tr>
                    <tr>
                      <th>WEB</th>
                      <td><a href={supplierCurrent?.url} target='_blank' rel="noreferrer" >{supplierCurrent?.url}</a></td>
                    </tr>
                    <tr>
                      <th>EMAIL</th>
                      <td>{supplierCurrent?.email}</td>
                    </tr>
                    <tr>
                      <th>YEAR</th>
                      <td>{supplierCurrent?.year}</td>
                    </tr>
                    <tr>
                      <th>PHONE</th>
                      <td>{supplierCurrent?.phone}</td>
                    </tr>
                    <tr>
                      <th>FAX</th>
                      <td>{supplierCurrent?.fax}</td>
                    </tr>
                    <tr>
                      <th>OVERVIEW</th>
                      <td>{supplierCurrent?.overview}</td>
                    </tr>
                    <tr>
                      <th>FAMILIES</th>
                      <td>{supplierCurrent?.families.map(f => <div>- {f.name}</div>)}</td>
                    </tr>
                    <tr>
                      <th>ANNUAL REVENUE</th>
                      <td>{supplierCurrent?.annualRevenue}</td>
                    </tr>
                    <tr>
                      <th>LANGUAGES</th>
                      <td>{supplierCurrent?.languages}</td>
                    </tr>
                    <tr>
                      <th>No. EMPLOYEES</th>
                      <td>{supplierCurrent?.employees}</td>
                    </tr>
                  </tbody>
                </table>
              </Paper>
            </Grid>
          </>
        }
        {selectedSupplier.length === 0 && !isLoading &&
          <div>
            No hay Suppliers
          </div>
        }
      </Grid>
    </Container>
    <br/>
  </div>
}

export default SuppliersByFamily;
