import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Typography
} from "@mui/material";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useState, Fragment} from "react";
import SubHeader from '../components/common/SubHeader';

const TermsConditions = () => {
  const [expanded, setExpanded] = useState('panel1');
  
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  
  const ComponentAccordion = (props) => {
    const {numPanel = '', title = '' , content = ''} = props;
    
    return <Accordion expanded={expanded === numPanel} onChange={handleChangeAccordion(numPanel)}>
      <AccordionSummary expandIcon={<ExpandMoreIcon color="primary"/>}>
        <Typography variant="button" color="primary">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={1}/>
          <Grid item xs={10}>{content}</Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }
  
  return <Fragment>
    <SubHeader title='legal / TÉRMINOS DE USO'/>
    <Container fixed>
      <ComponentAccordion numPanel={1}
        title={'I. ACEPTACIÓN DE LOS TÉRMINOS DE USO'}
        content={
          <Typography>
            Usted y ALAXS.com suscriben los presentes términos de uso. ALAXS.com es un portal digital cuya representatividad, derechos de uso, operatividad e intereses funcionales y comerciales para los efectos de trading entre Asia y Latino América son exclusividad de LatAm Trading Ltd., empresa de responsabilidad limitada constituida y domiciliada en Hong Kong, Asia, (en adelante la “Empresa”, “ALAXS”, “nosotros” o “nos”).
            <p>
            Los siguientes términos y condiciones de uso, así como cualquier documento incorporado como referencia de forma expresa (denominados en conjunto los “Términos de Uso”), rigen su acceso a www.alaxs.com (incluyendo los sitios web de soporte y digitalmente relacionados a www.alaxs.com de manera directa) y el uso que le dé a dicho sitio, incluyendo cualquier contenido, funcionalidad y servicio ofrecido en, o a través de, www.alaxs.com (el “Sitio Web”), ya sea como invitado o como usuario registrado. Sin perjuicio de cualquier disposición que establezca lo contrario en el presente documento, estos Términos de Uso también regirán el acceso y uso del operador principal (el “Operador Principal”) en cuya representación usted, en su calidad de agente, empleado y/o contratista independiente del Operador Principal, accediera y utilizara cualquier contenido, funcionalidad y servicios ofrecidos en el Sitio Web, o a través de él; por consiguiente, usted declara y garantiza tener la autoridad para vincular al Operador Principal, quien también estará sujeto a los presentes Términos de Uso. El pronombre “usted” deberá interpretarse como si hiciera referencia tanto a usted como al Operador Principal.
            </p>
            <p>
            Por favor, lea los Términos de Uso detenidamente antes de empezar a utilizar el Sitio Web. Al usar el Sitio Web o hacer clic para aceptar los Términos de Uso cuando se le presenta esta opción, usted acepta someterse a, y acatar, dichos Términos, nuestra Política de Privacidad de Datos (que se encuentra en www.alaxs.com/data-privacy), y la Declaración de la Política de Privacidad de la Hong Kong Latin America Business Association (“HKLABA”) y el Hong Kong Trade Development Council (“HKTDC”) (que se encuentra en http://www.hktdc.com/mis/pps/en/Privacy-Policy-Statement.html) (denominadas en conjunto las “Políticas de Privacidad de Datos”), incorporados como referencia al presente documento. Si usted no desea acatar estos Términos de Uso o las Políticas de Privacidad de Datos, no debe acceder al Sitio Web ni utilizarlo.
            </p>
            <p>
              Este Sitio Web se ofrece y está disponible para usuarios de trece años de edad o más, y ha sido diseñado con el objeto de proporcionar información importante para los miembros de ALAXS, HKLABA y otras agencias del Asia que realicen negocios entre Asia y Latino América. Al utilizar este Sitio Web, usted declara y garantiza que cumple con todos los requisitos de elegibilidad descritos líneas arriba. En caso contrario, no debe acceder a este Sitio Web ni utilizarlo.
            </p>
          </Typography>
        }
      />
      <ComponentAccordion 
        numPanel={2}
        title={'II. CAMBIOS A LOS TÉRMINOS DE USO'}
        content={
          <Typography>
            Podemos modificar y actualizar los presentes Términos de Uso cada cierto tiempo a nuestra entera discreción. Todas las modificaciones entrarán en vigor inmediatamente después de su publicación, y aplicarán a todos los accesos y usos del Sitio Web a partir de ese momento.
            <p>
            Al seguir utilizando el Sitio Web tras la publicación de los Términos de Uso modificados, usted acepta tales cambios y expresa su conformidad. Se espera que usted revise esta página cada cierto tiempo para ponerse al tanto de cualquier cambio, ya que estos tienen carácter vinculante. De conformidad con los presentes Términos de Uso, ALAXS le otorga el derecho de acceder al Sitio Web y utilizarlo para sus propios fines internos. Usted acuerda no eliminar los derechos de autor u otros avisos contenidos en el Sitio Web durante su uso. Salvo lo expresamente autorizado en este documento, usted no puede usar, copiar, reproducir, transmitir, traducir, ofrecer en venta, vender, mostrar, realizar, distribuir, publicar, transmitir, circular, modificar, crear trabajos derivados, difundir o explotar comercialmente el Sitio Web sin el consentimiento por escrito de ALAXS. De igual forma, usted acuerda no utilizar el Sitio Web con fines ilícitos.
            </p>
          </Typography>
        }
      />
      <ComponentAccordion 
        numPanel={3}
        title={'III. ACCESO AL SITIO WEB Y SEGURIDAD DE LA CUENTA'}
        content={
          <Typography>
            Nos reservamos el derecho de retirar o modificar este Sitio Web, así como cualquier servicio o material ofrecido en el mismo, a nuestra entera discreción y sin previo aviso. No asumimos responsabilidad alguna si, por cualquier motivo, la totalidad o parte del Sitio Web no estuviera disponible en cualquier momento o durante un período determinado. Cada cierto tiempo, podemos restringir el acceso a algunas partes del Sitio Web, o a todo el Sitio Web, para los usuarios, incluidos los usuarios registrados.
            <p>
            Usted es responsable de:
            </p>
            Realizar todas las coordinaciones necesarias para tener acceso al Sitio Web.
            <p>
            Para acceder al Sitio Web, o a algunos de los recursos que ofrece, se le pedirá proporcionar ciertos detalles de registro u otra información, o complementar su formulario de registro ingresado en REGISTERASIALATAM.com, una plataforma digital que proporciona un formulario de registro único y unificado que tiene por objeto ayudar a los usuarios a brindar información para entender mejor a sus empresas y ofrecerles servicios que se adapten mejor a sus necesidades. Como condición para utilizar el Sitio Web, toda la información que proporcione en este debe ser correcta, actual y completa. Usted acepta que toda la información proporcionada para registrarse en este Sitio Web, o de otra forma, incluyendo, entre otros medios, el uso de cualquier funcionalidad interactiva del mismo, se rige por las Políticas de Privacidad de Datos, y acepta todas las acciones que tomemos con respecto a su información de conformidad con las Políticas de Privacidad de Datos.
            </p>
            Si usted elige, o se le proporciona, un nombre de usuario, contraseña o cualquier otra información como parte de nuestros procedimientos de seguridad, debe tratar dicha información como confidencial, y no debe revelarla a cualquier otra persona o entidad. Asimismo, reconoce que su cuenta es personal y acuerda no brindar acceso a terceros a este Sitio Web, o a partes del mismo, con su nombre de usuario, contraseña u otra información de seguridad. Usted acuerda notificarnos de inmediato sobre cualquier acceso no autorizado o uso de su nombre de usuario o contraseña o cualquier otra violación de seguridad. De igual forma, acuerda asegurarse de salir de su cuenta al final de cada sesión. Debe tener especial cuidado al acceder a su cuenta desde una computadora pública o compartida para que otros no puedan ver o registrar su contraseña u otra información personal.
            <p>
            Tenemos el derecho de deshabilitar cualquier nombre de usuario, contraseña u otro identificador, elegido por usted o proporcionado por nosotros, en cualquier momento y a nuestra entera discreción, por cualquier motivo o sin razón alguna, incluso si, desde nuestra perspectiva, usted ha violado cualquier disposición contenida en estos Términos de Uso.
            </p>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={4}
        title={'IV. DERECHOS DE PROPIEDAD INTELECTUAL'}
        content={
          <Typography>
            El Sitio Web y todo su contenido, características y funcionalidades (que incluyen, entre otros aspectos, información, software, texto, presentaciones, imágenes, video y audio, así como el diseño, selección y disposición de los mismos), son propiedad de la Empresa, sus licenciantes u otros proveedores de dicho material, y se encuentran protegidos por las leyes de derechos de autor, marcas registradas, patentes, secretos comerciales y otros derechos de propiedad intelectual o propiedad de los Estados Unidos e internacionales.
            <p>
            Estos Términos de Uso le permiten usar el Sitio Web solo para su uso personal, no comercial o para fines comerciales legítimos vinculados a su rol como miembro actual o potencial de ALAXS. Usted no debe reproducir, distribuir, modificar, crear trabajos derivados, mostrar públicamente, presentar públicamente, volver a publicar, descargar, almacenar o transmitir cualquier material de nuestro Sitio Web, salvo como se indica a continuación:
            </p>
            <ul>
              <li>
                Su computadora puede almacenar temporalmente copias de dichos materiales en la memoria RAM cuando usted accede a ellos y los visualiza.
              </li>
              <li>
                Usted puede guardar archivos que su navegador web almacena en caché automáticamente para mejorar la visualización.
              </li>
              <li>
                A menos que se indique claramente lo contrario, usted puede imprimir o descargar una copia de un número razonable de páginas del Sitio Web para su uso personal no comercial, y no para su posterior reproducción, publicación o distribución.
              </li>
              <li>
                Si proporcionamos aplicaciones de escritorio, móviles o de otro tipo para descargar, usted puede descargar una sola copia a su computadora o dispositivo móvil únicamente para su uso personal y no comercial, siempre que acepte cumplir con nuestro contrato de licencia de usuario final para tales aplicaciones.
              </li>
              <li>
                Si proporcionamos funcionalidades de medios sociales con determinado contenido, puede ejecutar las acciones permitidas por estas.
              </li>
            </ul>
            Usted no debe:
            <br/>
            <ul>
              <li>
                Modificar las copias de cualquier material de este sitio a menos que se indique claramente algo distinto.
              </li>
              <li>
                Utilizar ilustraciones, fotografías, secuencias de video o audio o cualquier gráfico separados del texto que los acompañan.
              </li>
              <li>
                Eliminar o alterar los avisos de derechos de autor, marcas registradas u otros derechos de propiedad de las copias de los materiales de este sitio.
              </li>
            </ul>
            A menos que se indique claramente algo distinto, usted no debe acceder a, o utilizar, cualquier parte del Sitio Web, o cualquiera de los servicios o materiales disponibles a través de él, con fines comerciales. Solo los miembros de ALAXS pueden usar los materiales de este Sitio Web para fines comerciales legítimos.
            <p>
            Si desea utilizar el material del Sitio Web de alguna forma distinta a las que se establecen en esta sección, por favor, envíe una solicitud a: <a href="mailto:legal@alaxs.com" target="_blank" rel="noreferrer">legal@alaxs.com</a>.
            </p><p>
            Si usted imprime, copia, modifica, descarga o usa de otra manera cualquier parte del Sitio Web, o permite que otra persona acceda a ella, en contra de los Términos de Uso, se revocará de inmediato su derecho a utilizar el Sitio Web y usted deberá, a nuestro criterio, devolver o destruir cualquier copia que hubiera realizado de dichos materiales. No se le transfiere derecho, título o interés alguno sobre el Sitio Web, o cualquier contenido del mismo, y la Empresa mantiene todos los derechos que no hubieran sido otorgados de forma expresa. Cualquier uso del Sitio Web que no haya sido expresamente autorizado por estos Términos de Uso constituye una violación de los mismos y puede infringir las leyes en materia de derechos de autor y marcas registradas, así como leyes de otra índole.
            </p>
          </Typography>
        }
      />
      <ComponentAccordion
        numPanel={5}
        title={'V. MARCAS REGISTRADAS'}
        content={<Typography>El nombre de la Empresa, los términos “Asia Latin America Xperience Solutions”, “ALAXS” y todos los nombres, logotipos, nombres de productos y servicios, diseños y eslóganes relacionados son marcas registradas de la Empresa. Usted no debe utilizar dichas marcas sin el previo consentimiento por escrito de la Empresa. Todos los demás nombres, logotipos, nombres de productos y servicios, diseños y eslóganes contenidos en este Sitio Web son marcas registradas de sus respectivos propietarios.<br/><br/></Typography>}
      />
      <ComponentAccordion
        numPanel={6}
        title={'VI. USOS PROHIBIDOS'}
        content={<Typography>
          Usted puede usar el Sitio Web únicamente con fines legales y de conformidad con estos Términos de Uso. Usted acuerda no usar el Sitio Web como se indica a continuación:
          <br/><br/>
          De alguna forma que viole cualquier ley o norma federal, estatal, local o internacional pertinente (incluyendo, entre otras, cualquier ley vinculada a la exportación de datos o software hacia y desde los Estados Unidos u otros países).
          <br/><br/>
          Además, usted acuerda no llevar a cabo las siguientes acciones:
          <ul>
            <li>
              Utilizar el Sitio Web de cualquier forma que pudiera deshabilitarlo, sobrecargarlo, dañarlo o deteriorarlo; o interferir en el uso de este por parte de terceros, incluyendo su capacidad para participar en actividades en tiempo real a través del Sitio Web.
            </li>
            <li>
              Utilizar cualquier robot, araña (spider) u otro dispositivo automático, proceso o medio para acceder al Sitio Web con cualquier fin, incluyendo el monitoreo o la copia de cualquier material del mismo.
            </li>
            <li>
              Utilizar algún proceso manual para monitorear o copiar cualquier material del Sitio Web o para cualquier otro propósito no autorizado sin nuestro consentimiento previo por escrito.
            </li>
            <li>
              Utilizar cualquier dispositivo, software o rutina que interfiera con el buen funcionamiento del Sitio Web.
            </li>
            <li>
              Introducir virus, troyanos, gusanos, bombas lógicas u otro material malicioso o tecnológicamente dañino.
            </li>
            <li>
              Intentar obtener acceso no autorizado a, interferir con, dañar o alterar cualquier parte del Sitio Web, el servidor en el que se encuentra almacenado o cualquier servidor, computadora o base de datos conectada al Sitio Web.
            </li>
            <li>
              Atacar al Sitio Web mediante un ataque de denegación de servicio o un ataque de denegación de servicio distribuido.
            </li>
            <li>
              Tratar de interferir de otra forma con el buen funcionamiento del Sitio Web.
            </li>
          </ul>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={7}
        title={'VII. CONTRIBUCIONES DE USUARIOS'}
        content={<Typography>
          El Sitio Web puede contener paneles de mensajes, salas de chat, páginas web o perfiles personales, foros, tableros de anuncios, un centro de recursos, un mercado y otras funcionalidades interactivas (denominadas en conjunto <strong>“Servicios Interactivos”</strong>) que permiten a los usuarios publicar, enviar, difundir, mostrar o transmitir a otros usuarios u otras personas (en adelante <strong>“publicar”</strong>) contenido o materiales (denominados en conjunto <strong>“Contribuciones de Usuarios”</strong>) en el Sitio Web, o a través del mismo.
          <br/><br/>
          Todas las Contribuciones de Usuarios deben cumplir con los Estándares de Contenido establecidos en estos Términos de Uso.
          <br/><br/>
          Cualquier Contribución de Usuario que usted publique en el sitio no se considerará confidencial o como propia. Al publicar cualquier Contribución de Usuario en el Sitio Web, usted otorga a nuestra empresa, así como a nuestras filiales y proveedores de servicios, y a cada uno de sus licenciatarios, sucesores y cesionarios, y a los nuestros, el derecho a usar, reproducir, modificar, presentar, mostrar o divulgar de forma alguna a terceros dicho material para uso personal o con cualquier fin comercial legítimo.
          <br/><br/>
          Usted declara y garantiza que:
          <ul>
            <li>
              Es dueño o controla todos los derechos sobre las Contribuciones de Usuarios, y tiene el derecho de otorgarnos, y otorgar a nuestras filiales y proveedores de servicios, así como a cada uno de sus respectivos licenciatarios, sucesores y cesionarios, y a los nuestros, la licencia otorgada líneas arriba.
            </li>
            <li>
              Todas sus Contribuciones de Usuario cumplen y cumplirán con estos Términos de Uso.
            </li>
          </ul>
          Usted entiende y reconoce su responsabilidad por cualquier Contribución de Usuario que presente o proporcione, y usted, no la Empresa, asume la responsabilidad total por dicho contenido, incluyendo su legalidad, confiabilidad, precisión e idoneidad.
          <br/><br/>
          No asumimos responsabilidad alguna frente a terceros por el contenido o la precisión de cualquier Contribución de Usuario que usted, u otro usuario, publique en el Sitio Web.
          <br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={8}
        title={'VIII. MONITOREO Y CUMPLIMIENTO; TERMINACIÓN'}
        content={<Typography>
          Tenemos derecho a:
          <p>
            eliminar, o rehusarnos a publicar, cualquier Contribución de Usuario por cualquier motivo, o sin razón alguna, a nuestra entera discreción.
          </p>
          <p>
            Sin perjuicio de lo anterior, tenemos derecho a cooperar plenamente con las fuerzas del orden o cualquier orden judicial que nos solicite u obligue a revelar la identidad u otra información de cualquier persona que publique algún material en el Sitio Web, o a través de este. USTED EXIME DE RESPONSABILIDAD A LA EMPRESA Y A SUS FILIALES, LICENCIATARIOS Y PROVEEDORES DE SERVICIOS POR CUALQUIER RECLAMO DERIVADO DE ALGUNA ACCIÓN EJECUTADA POR LAS PARTES DETALLADAS LÍNEAS ARRIBA DURANTE, O COMO CONSECUENCIA DE, LAS INVESTIGACIONES REALIZADAS POR ESTAS O POR LAS FUERZAS DEL ORDEN.
          </p>
          <p>
            Sin embargo, no podemos revisar todo el material antes de que sea publicado en el Sitio Web, y no podemos garantizar la eliminación inmediata de material cuestionable después de que haya sido publicado. Por consiguiente, no asumimos responsabilidad alguna por cualquier acción o inacción con respecto a las transmisiones, comunicaciones o contenido proporcionado por cualquier usuario o tercero. Asimismo, no asumimos responsabilidad alguna ante cualquier persona por la ejecución, o no ejecución, de las actividades descritas en esta sección.
          </p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={9}
        title={'IX. ESTÁNDARES DE CONTENIDO'}
        content={<Typography>
          Los estándares de contenido establecidos en el presente documento aplican a todas y cada una de las Contribuciones de Usuarios, así como al uso de los Servicios Interactivos. Las Contribuciones de Usuarios deben cumplir a cabalidad con las leyes y normas federales, estatales, locales e internacionales pertinentes. Sin perjuicio de lo anterior, las Contribuciones de Usuarios no deben:
          <p>
          contener material que sea difamatorio, obsceno, indecente, abusivo, ofensivo, acosador, violento, hostil, incendiario o cuestionable por otros motivos.
          </p>
          <ul>
            <li>
              promover material sexualmente explícito o pornográfico, violencia o discriminación por raza, sexo, religión, nacionalidad, discapacidad, orientación sexual o edad.
            </li>
            <li>
              infringir cualquier patente, marca registrada, secreto comercial, derecho de autor u otros derechos de propiedad intelectual o de otra índole de otras personas.
            </li>
            <li>
              violar los derechos legales (incluyendo los derechos de publicidad y privacidad) de otros o contener cualquier material que pudiera dar lugar a responsabilidad civil o penal en virtud de las leyes o reglamentos aplicables, o que pudiera estar en conflicto con estos Términos de Uso y las Políticas de Privacidad de Datos.
            </li>
            <li>
              contemplar la probabilidad de engañar a las personas.
            </li>
            <li>          
              promover cualquier actividad ilegal; o fomentar, promover o contribuir a cualquier acto ilícito.
            </li>
            <li>
              causar molestia, inconveniencia o ansiedad innecesaria; o contemplar la probabilidad de alterar, avergonzar, alarmar o molestar a cualquier otra persona.
            </li>
            <li>
              suplantar a cualquier persona, o falsear su identidad o afiliación con alguna persona u organización.
            </li>
            <li>
              dar la impresión de que proceden o cuentan con el respaldo de nuestra empresa o de cualquier otra persona o entidad, de no ser este el caso.
            </li>
          </ul>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={10}
        title={'X. INFRACCIÓN DE DERECHOS DE AUTOR'}
        content={<Typography>
          <strong>Reporte de reclamos por infracciones de derechos de autor.</strong> Nos tomamos en serio los reclamos por infracciones de derechos de autor. Responderemos a las notificaciones de presuntas infracciones de derechos de autor que cumplan con la legislación pertinente. Si usted considera que algún material disponible en o desde nuestro sitio (el <strong>“Sitio Web”</strong>) infringe sus derechos de autor, puede solicitar que lo retiren del Sitio Web (o eliminen el acceso al mismo) mediante la presentación de una notificación por escrito a nuestro agente de derechos de autor designado más adelante. De conformidad con la Ley de Limitación de Responsabilidad por Infracción de Derechos de Autor En Línea de la Ley de Derechos de Autor de la Era Digital (17 U.S.C § 512) (<strong>“DMCA”</strong>), la notificación por escrito (la <strong>“Notificación de DMCA”</strong>) debe incluir básicamente lo siguiente:
          <p>su firma física o electrónica;</p>
          Nuestro agente de derechos de autor designado para recibir Notificaciones de DMCA es: <a href="mailto:legal@alaxs.com" target="_blank" rel="noreferrer">legal@alaxs.com</a>.
          <p>
          Si usted no cumple con todos los requisitos establecidos en la Sección 512(c)(3) de la DMCA, puede que su Notificación de DMCA carezca de validez.
          </p>
          Por favor, tenga en cuenta que, si declara falsamente, y de manera deliberada, que algún material o actividad del Sitio Web infringe sus derechos de autor, se le podrá responsabilizar por daños y perjuicios (incluyendo costos y honorarios de los abogados) en virtud de la Sección 512(f) de la DMCA.
          <p>
            <strong>Procedimientos de contranotificación.</strong> Si usted considera que el material que publicó en el Sitio Web fue retirado, o se deshabilitó el acceso al mismo, por error o identificación errónea, puede presentar una contranotificación (<strong>“Contranotificación”</strong>) mediante un aviso por escrito a nuestro agente de derechos de autor designado líneas arriba. De conformidad con la DMCA, la Contranotificación debe incluir básicamente lo siguiente:
          </p>
          <ul>
            <li>
              su firma física o electrónica;
            </li>
            <li>
              una identificación del material que fue retirado, o cuyo acceso se deshabilitó, y la ubicación en la que aparecía antes de que esto ocurriera;
            </li>
            <li>
              información adecuada que nos permita ponernos en contacto con usted (incluyendo su nombre, dirección postal, número telefónico y correo electrónico, de contar con uno);
            </li>
            <li>
              una declaración, bajo pena de perjurio, en la que indique que usted considera, de buena fe, que el material identificado líneas arriba fue retirado o deshabilitado producto de un error o identificación errónea;
            </li>
            <li>
              una declaración en la que indique que se someterá a la jurisdicción del Tribunal del Distrito Federal del distrito judicial donde se ubica su dirección (o si reside fuera de los Estados Unidos, cualquier distrito judicial donde se pueda encontrar el Sitio Web) y aceptará la notificación de la persona (o un representante de la misma) que presentó el reclamo en cuestión al Sitio Web.
            </li>
          </ul>
          La DMCA nos permite restaurar el contenido eliminado si la parte que presenta la Notificación de DMCA original no entabla una acción judicial en su contra dentro de los diez días útiles posteriores a la recepción de la copia de su Contranotificación.
          <p>
          Por favor, tenga en cuenta que, si declara falsamente, y de manera deliberada, que algún material o actividad del Sitio Web fue retirado o deshabilitado por error o identificación errónea, se le podrá responsabilizar por daños y perjuicios (incluyendo costos y honorarios de los abogados) en virtud de la Sección 512(f) de la DMCA.
          </p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={11}
        title={'XI. INFRACTORES RECURRENTES'}
        content={<Typography>
          En circunstancias apropiadas, nuestra política nos permite deshabilitar y/o dar por terminadas las cuentas de usuarios que sean infractores recurrentes.<br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={12}
        title={'XII. CONFIANZA EN LA INFORMACIÓN PUBLICADA'}
        content={<Typography>
          El contenido presentado en el Sitio Web, o a través de este, se pone a disposición del público únicamente con fines informativos. No garantizamos la precisión, integridad o utilidad de dicha información. El grado de confianza que otorgue a dicha información depende exclusivamente de usted. Renunciamos a toda responsabilidad que surja de la confianza depositada en dichos materiales por usted o cualquier otro visitante del Sitio Web, o por cualquier persona que pudiera estar al tanto de su contenido.
          <p>
            Este Sitio Web incluye contenido proporcionado por terceros, tal como materiales proporcionados por otros usuarios, blogueros y licenciantes externos, sindicadores, recopiladores de contenido y/o servicios de informes. Todas las declaraciones y/u opiniones expresadas en estos materiales, y todos los artículos y respuestas a preguntas y otros contenidos, aparte del contenido proporcionado por la Empresa, son únicamente opiniones y responsabilidad de la persona o entidad que proporciona dichos materiales. Estos materiales no reflejan necesariamente la opinión de la Empresa. No nos hacemos responsables, ante usted o cualquier otra persona, por el contenido o la precisión de los materiales proporcionados por terceros.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={13}
        title={'XIII. MODIFICACIONES AL SITIO WEB'}
        content={<Typography>
          Podemos actualizar el contenido de este Sitio Web cada cierto tiempo, pero no significa que el mismo se encuentre completo o actualizado necesariamente. Cualquier material del Sitio Web puede estar desactualizado en un momento dado, y no tenemos obligación alguna de actualizarlo.<br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={14}
        title={'XIV. INFORMACIÓN SOBRE USTED Y SUS VISITAS AL SITIO WEB'}
        content={<Typography>
          Toda la información que recopilamos en este Sitio Web está sujeta a las Políticas de Privacidad de Datos. Al utilizar el Sitio Web, usted acepta todas las acciones que tomemos con respecto a su información de conformidad con las Políticas de Privacidad de Datos. Puede visitar nuestra Política de Privacidad de Datos, que se incluye en la sección Legal de nuestro Sitio Web, para revisar todos los detalles sobre cómo protegemos su información.<br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={15}
        title={'XV. ENLACE AL SITIO WEB Y A LAS FUNCIONES DE MEDIOS SOCIALES'}
        content={<Typography>
          Puede establecer un enlace a nuestra página de inicio, siempre que lo haga de forma adecuada y legal, sin dañar nuestra reputación o aprovechándose de ella; y no debe establecer un enlace de forma tal que sugiera algún tipo de asociación, aprobación o respaldo de nuestra parte sin nuestro expreso consentimiento por escrito.
          <p>
          Este Sitio Web puede ofrecer ciertas funcionalidades de medios sociales que le permitan:
          </p>
          Establecer enlaces a determinado contenido de este Sitio Web desde su propio sitio o desde algunos sitios de terceros.
          <p>
          Puede usar estas funcionalidades únicamente como las proporcionamos, y exclusivamente con relación al contenido con el que se muestran. De conformidad con lo anterior, usted no debe:
          </p>
          Establecer un enlace desde cualquier sitio web que no le pertenezca.
          <p>
          El sitio web desde donde establece enlaces, o en el que usted brinda acceso a determinado contenido, debe cumplir a cabalidad con los Estándares de Contenido dispuestos en estos Términos de Uso.
          </p>
          Usted acuerda cooperar con nosotros para detener de inmediato los marcos o enlaces no autorizados. Nos reservamos el derecho de retirar nuestro permiso para establecer enlaces sin previo aviso.
          <p>Podemos deshabilitar todas o algunas de las funciones de medios sociales y cualquier enlace en cualquier momento sin previo aviso a nuestra discreción.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={16}
        title={'XVI. ENLACES DESDE EL SITIO WEB'}
        content={<Typography>
          Los enlaces que contenga el Sitio Web a otros sitios y recursos de terceros se proporcionan únicamente para su comodidad. Esto incluye enlaces contenidos en anuncios, entre ellos, anuncios publicitarios digitales y enlaces patrocinados. No tenemos control alguno sobre el contenido de dichos sitios o recursos, y no asumimos responsabilidad alguna por ellos o por cualquier pérdida o daño que pudiera derivarse de su uso. Si usted decidiera acceder a cualquiera de los sitios web de terceros enlazados a este Sitio Web, lo hace bajo su exclusiva responsabilidad y sujeto a los términos y condiciones de uso de tales sitios.<br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={17}
        title={'XVII. CUMPLIMIENTO DE LAS LEYES APLICABLES; LEYES DE CONTROL DE EXPORTACIÓN'}
        content={<Typography>
          Usted cumplirá con todas las leyes, reglas y reglamentos aplicables con respecto a su participación en nuestros programas y el uso de nuestro Sitio Web, y no se involucrará en actos que tengan un impacto adverso en el desempeño o disponibilidad de nuestros programas o nuestro Sitio Web.
          <p>
          Este Sitio Web puede estar sujeto a las leyes del país donde reside y/o donde opera su empresa. Ningún software de este Sitio Web podrá ser descargado o exportado (i) a cualquier país al que Estados Unidos haya embargado bienes, o (ii) a cualquier persona que figure en la Lista de Órdenes Denegadas del Departamento del Tesoro de los Estados Unidos. Al descargar o utilizar el software, usted declara y garantiza que no se encuentra en, o bajo el control de, dichos países, ni es ciudadano o residente de los mismos, y tampoco figura en dicha lista.
          </p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={18}
        title={'XVIII. EXENCIÓN DE GARANTÍAS'}
        content={<Typography>
          Usted entiende que no podemos garantizar, y no garantizamos, que los archivos disponibles para descarga de la internet o del Sitio Web estarán libres de virus u otros códigos destructivos. Usted es responsable de implementar suficientes procedimientos y controles en función de sus requisitos específicos de protección antivirus y precisión de datos de entrada y salida, así como de mantener un medio externo a nuestro sitio para la recuperación de cualquier información perdida. SEGÚN LO MÁXIMO PERMITIDO CONFORME A LEY, NO ASUMIREMOS RESPONSABILIDAD ALGUNA POR CUALQUIER PÉRDIDA O DAÑO PROVOCADO POR UN ATAQUE DE DENEGACIÓN DE SERVICIO DISTRIBUIDO, VIRUS U OTRO MATERIAL TECNOLÓGICAMENTE PERJUDICIAL QUE PUEDA INFECTAR SU COMPUTADORA, PROGRAMAS INFORMÁTICOS, DATOS U OTRO MATERIAL PROTEGIDO COMO RESULTADO DEL USO DEL SITIO WEB, O CUALQUIER SERVICIO O ÍTEM OBTENIDO A TRAVÉS DE ESTE, O PRODUCTO DE LA DESCARGA DE CUALQUIER MATERIAL PUBLICADO EN ÉL, O EN CUALQUIER SITIO WEB ENLAZADO AL MISMO.
          <p>
          AL UTILIZAR EL SITIO WEB Y SU CONTENIDO, ASÍ COMO CUALQUIER SERVICIO O ÍTEM OBTENIDO A TRAVÉS DE ESTE, USTED LO HACE BAJO SU PROPIO RIESGO. EL SITIO WEB, SU CONTENIDO Y CUALQUIER SERVICIO O ÍTEM OBTENIDO A TRAVÉS DE ÉL SE PROPORCIONAN “TAL COMO SE ENCUENTRAN” Y “SEGÚN ESTÉN DISPONIBLES”, SIN NINGÚN TIPO DE GARANTÍAS, YA SEAN EXPRESAS O IMPLÍCITAS. NI LA EMPRESA, NI PERSONA ALGUNA VINCULADA A ELLA, ESTABLECE ALGUNA GARANTÍA O DECLARACIÓN CON RESPECTO A LA INTEGRIDAD, SEGURIDAD, CONFIABILIDAD, CALIDAD, PRECISIÓN O DISPONIBILIDAD DEL SITIO WEB. SIN PERJUICIO DE LO ANTERIOR, NI LA EMPRESA, NI PERSONA ALGUNA VINCULADA A ELLA, DECLARA O GARANTIZA QUE: EL SITIO WEB, SU CONTENIDO, O CUALQUIER SERVICIO O ÍTEM OBTENIDO A TRAVÉS DE ÉL, SERÁN PRECISOS, CONFIABLES, LIBRES DE ERRORES O SIN INTERRUPCIONES; SE CORREGIRÁN LOS DEFECTOS; NUESTRO SITIO WEB O EL SERVIDOR QUE LO ALBERGA SE ENCUENTRAN LIBRES DE VIRUS U OTROS COMPONENTES DAÑINOS; O EL SITIO WEB O CUALQUIERA DE LOS SERVICIOS O ÍTEMS OBTENIDOS A TRAVÉS DE ÉL CUMPLIRÁN SUS NECESIDADES O EXPECTATIVAS.
          </p>
          SEGÚN LO MÁXIMO PERMITIDO CONFORME A LEY, LA EMPRESA RENUNCIA A CUALQUIER TIPO DE GARANTÍA, SEA EXPRESA O IMPLÍCITA, REGLAMENTARIA O DE OTRA ÍNDOLE, INCLUYENDO, ENTRE OTRAS, CUALQUIER GARANTÍA DE COMERCIABILIDAD, NO INFRACCIÓN E IDONEIDAD PARA FINES ESPECÍFICOS.
          <p>
            LO DISPUESTO EN LOS PÁRRAFOS ANTERIORES NO AFECTA A AQUELLAS GARANTÍAS QUE NO PUEDEN SER EXCLUIDAS O LIMITADAS DE CONFORMIDAD CON LA LEGISLACIÓN APLICABLE.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={19}
        title={'XIX. LIMITACIÓN DE RESPONSABILIDAD'}
        content={<Typography>
          SEGÚN LO MÁXIMO PERMITIDO CONFORME A LEY, BAJO NINGUNA CIRCUNSTANCIA, LA EMPRESA, SUS FILIALES, O SUS LICENCIATARIOS, PROVEEDORES DE SERVICIOS, EMPLEADOS, AGENTES, FUNCIONARIOS O DIRECTORES SE RESPONSABILIZARÁN POR CUALQUIER DAÑO O PERJUICIO, EN VIRTUD DE ALGUNA TEORÍA LEGAL, PRODUCTO DE, O EN RELACIÓN CON, EL USO, O LA INCAPACIDAD DE USAR, EL SITIO WEB, CUALQUIER SITIO WEB ENLAZADO A ESTE, CUALQUIER CONTENIDO DEL SITIO WEB U OTROS SITIOS WEB ENLAZADOS, INCLUYENDO CUALQUIER EXPECTATIVA; DAÑOS O PERJUICIOS DIRECTOS, INDIRECTOS, ESPECIALES, INCIDENTALES, CONSECUENCIALES O PUNITIVOS, INCLUYENDO, ENTRE OTROS, LESIONES PERSONALES, DOLOR Y SUFRIMIENTO, ANGUSTIA EMOCIONAL, PÉRDIDA DE INGRESOS, LUCRO CESANTE, PÉRDIDA DE NEGOCIOS O AHORROS PREVISTOS, PÉRDIDA DE USO, PÉRDIDA DE BUENA REPUTACIÓN, PÉRDIDA DE DATOS, INDEPENDIENTEMENTE DE SI FUERON PROVOCADOS POR ALGÚN HECHO ILÍCITO (INCLUYENDO NEGLIGENCIA), INCUMPLIMIENTO DE CONTRATO, O POR OTRA CAUSA, AUNQUE FUERA PREVISIBLE.
          <p>LO DISPUESTO EN EL PÁRRAFO ANTERIOR NO AFECTA A AQUELLAS RESPONSABILIDADES QUE NO PUEDAN SER EXCLUIDAS O LIMITADAS DE CONFORMIDAD CON LA LEGISLACIÓN APLICABLE.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={20}
        title={'XX. EXCLUSIÓN DE RESPONSABILIDAD'}
        content={<Typography>
          Usted acuerda defender y eximir de responsabilidad a la Empresa, sus filiales, licenciatarios y proveedores de servicios, así como a sus respectivos funcionarios, directores, empleados, contratistas, agentes, licenciatarios, proveedores, sucesores y cesionarios, frente a cualquier reclamo, responsabilidad, daños o perjuicios, sentencias, laudos, pérdidas, costos, gastos u honorarios (incluyendo los honorarios razonables de abogados) producto de, o en relación con, la violación de estos Términos de Uso o el uso que le dé al Sitio Web, incluyendo, entre otros aspectos, sus Contribuciones de Usuario, cualquier uso del contenido, servicios y productos del Sitio Web de forma distinta a la que se autoriza expresamente en estos Términos de Uso; o el uso que le dé a cualquier información obtenida del Sitio Web.<br/><br/>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={21}
        title={'XXI. LEY APLICABLE Y JURISDICCIÓN'}
        content={<Typography>
          Todos los asuntos vinculados al Sitio Web y estos Términos de Uso, así como cualquier controversia o reclamo que se derive de o tenga relación con ellos (incluyendo, en cada caso, controversias o reclamos no contractuales), se regirán e interpretarán de acuerdo con las leyes internas del Estado de Delaware, sin dar lugar a elección o conflicto de disposición legal o norma.
          <p>
            Cualquier demanda, acción o procedimiento legal que se derive de estos Términos de Uso o del Sitio Web, o se relacione con ellos, se entablará exclusivamente en los tribunales federales de Estados Unidos ubicados en Miami, Florida, aunque nos reservamos el derecho de entablar cualquier demanda, acción o procedimiento en su contra por el incumplimiento de estos Términos de Uso en su país de residencia o en cualquier otro país pertinente. Usted renuncia a todas y cada una de las objeciones con respecto al ejercicio de la jurisdicción y el fuero de dichos tribunales.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={22}
        title={'XXII. ARBITRAJE'}
        content={<Typography>
          A discreción exclusiva de la Empresa, cualquier controversia o reclamo derivado de, o relacionado con, estos Términos de Uso o el incumplimiento de los mismos se resolverá de inmediato mediante arbitraje confidencial con un (1) árbitro en Miami, Florida, de conformidad con las normas vigentes de JAMS, Inc.; siempre que el árbitro no tenga autoridad para agregar, modificar, cambiar o ignorar las condiciones legales de estos Términos de Uso. La decisión del árbitro será definitiva y vinculante, y la sentencia sobre el laudo arbitral se puede presentar ante cualquier tribunal que tenga jurisdicción sobre el tema de controversia. Usted (a) se somete de manera irrevocable e incondicional al arbitraje descrito en el presente documento en caso de cualquier demanda, acción u otro procedimiento derivado de estos Términos de Uso, o basado en los mismos; (b) acepta no iniciar demanda, acción o procedimiento alguno derivado de estos Términos de Uso, o basado en los mismos, salvo según se estipule en este documento, y (c) renuncia y acepta no hacer valer, a manera de recurso, como defensa, o de otro modo, en cualquier demanda, acción o procedimiento, cualquier reclamo bajo el sustento de que no se encuentra sujeto a dicho arbitraje; que su propiedad está exenta de, o es inmune al, embargo o ejecución; que la demanda, acción o procedimiento se presentó en un foro inadecuado; que el fuero de la demanda, acción o procedimiento es incorrecto; o que estos Términos de Uso o el objeto del presente documento no se pueden hacer cumplir en dicho arbitraje.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={23}
        title={'XXIII. LIMITACIÓN DEL TIEMPO PARA PRESENTAR RECLAMOS'}
        content={<Typography>
          CUALQUIER CAUSA DE ACCIÓN O RECLAMO DERIVADO DE ESTOS TÉRMINOS DE USO O DEL SITIO WEB, O QUE TENGA RELACIÓN CON ELLOS, DEBE INICIARSE EN EL PLAZO DE UN (1) AÑO A PARTIR DEL MOMENTO EN QUE SURGE DICHA CAUSA; DE LO CONTRARIO, ESTA O EL RECLAMO SERÁN DESESTIMADOS DE FORMA PERMANENTE.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={24}
        title={'XXIV. RENUNCIA Y DIVISIBILIDAD'}
        content={<Typography>
          Ninguna renuncia por parte de la Empresa a cualquier término o condición establecidos en estos Términos de Uso se considerará como una renuncia posterior o continua a dicho término o condición, o una renuncia a cualquier otro término o condición, y aquellos casos en que la Empresa no hiciera valer un derecho o disposición en virtud de estos Términos de Uso no constituirán una renuncia a tal derecho o disposición.
          <p>Si una corte u otro tribunal de jurisdicción competente considera que alguna de las disposiciones contenidas en estos Términos de Uso es inválida, ilegal o inejecutable por cualquier razón, dicha disposición se eliminará o limitará a su mínimo alcance, de tal forma que las demás disposiciones de estos Términos de Uso sigan manteniéndose en pleno vigor y efecto.</p>
        </Typography>}
      />
      <ComponentAccordion
        numPanel={25}
        title={'XXV. ACUERDO COMPLETO'}
        content={<Typography>
          Los Términos de Uso y nuestra Política de Privacidad de Datos constituyen el único acuerdo integral suscrito entre usted y la Empresa con relación al Sitio Web, y reemplazan a todos los acuerdos, convenios, declaraciones y garantías previos y contemporáneos, tanto escritos como verbales, con respecto al Sitio Web.
        </Typography>}
      />
      <ComponentAccordion
        numPanel={26}
        title={'XXVI. SUS COMENTARIOS E INQUIETUDES'}
        content={<Typography>
          Todos los avisos de reclamos por infracciones de derechos de autor, otros comentarios, observaciones, solicitudes de soporte técnico y otras comunicaciones relacionadas con el Sitio Web deben dirigirse a:
          <p>
            Latam Trading Ltd. <a href="mailto:legal@alaxs.com" target="_blank" rel="noreferrer">legal@alaxs.com</a></p>
        </Typography>}
      />
      <Paper sx={{padding: '30px 0'}}>
        <Grid container spacing={2}>
          <Grid item xs={1}/>
          <Grid item xs={10}>
            <Typography>AL SEGUIR UTILIZANDO EL SITIO WEB <a href="https://alaxs.com/" target="_blank" rel="noreferrer">WWW.ALAXS.COM</a>, USTED ACEPTA CUMPLIR CON ESTOS TÉRMINOS DE USO.</Typography>
            </Grid>
        </Grid>
      </Paper>
      
{/*      
      
      <ComponentAccordion
        numPanel={}
        title={''}
        content={<Typography>
          
        </Typography>}
      />
      
      
      
      
 
      */}
    </Container>
  </Fragment>
}

export default TermsConditions;
