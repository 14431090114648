import {
  Breadcrumbs,
  Container,
  Grid,
  Paper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from '@mui/styles';
import {Link} from 'react-router-dom';
import {Box, IconButton, Stack} from '@mui/material';
import {
  GroupsOutlined,
  StorefrontOutlined, 
  WarehouseOutlined,
} from '@mui/icons-material';
import location from '../img/location-white.svg';
import Share from '../components/common/Share';

const useStyles = makeStyles((theme) => ({
  buttonIcon: {
    borderRadius: '50%',
    backgroundColor: '#ff3036 !important',
    width: '86px',
    height: '86px',
    padding: 0,
    color: 'white !important',
    marginBottom: '14px !important',
    '& img': {
      height: '50px'
    }
  },
  container: {
    display: 'flex !important',
    flexFlow: 'column nowrap',
    minHeight: 'calc(100vh - 136px)'
  },
  disabled: {
    '& a': {
      backgroundColor: '#FFB9B6 !important',
      pointerEvents: 'none',
    },
    '& span': {
      color: 'gray'
    }
  }
}));

const Assets = () => {
  const classes = useStyles();
  return <div style={{marginTop: '-24px'}}>
    <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
      <Typography>LATINOAMERICA </Typography>
      <Typography>Assets</Typography>
    </Breadcrumbs>
    <Container fixed className={classes.container}>
      <Grid container sx={{marginBottom: 'auto'}}>
        <Grid item xs={12} md={12}>
          <Paper style={{padding: '40px 40px 26px', margin: '20px auto 30px'}}>
            <Typography>Esta sección es para cualquier necesidad de productos industriales dentro del mercado latinoamericano.</Typography>
            <br/>
            <Typography>Podrás acceder a las siguientes opciones:</Typography>
            <ul>
              <li><strong>Marketplace: </strong>Comprar, vender, rematar o donar algo seminuevo o de 2do uso.</li>
              <li><strong>Warehouse: </strong>
                <ul>
                  <li>Mapeo de repuestos críticos.</li>
                  <li>Revisar nuestros catálogos de productos estandarizados.</li>
                  <li>Planear adquisiciones y/o ventas.</li>
                </ul>
              </li>
              <li><strong>Partnership: </strong> Buscar un partner u oportunidad de alianza para escalar o mejorar tu propuesta de valor.</li>
              <li><strong>Visitas: </strong> Realizar visitas de trabajo dentro de nuestro mercado.</li>
            </ul>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Stack direction="row" spacing={8}>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/marketplace">
                  <StorefrontOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Marketplace</Typography>
              </Box>
              <Box>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/warehouse">
                  <WarehouseOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Warehouse</Typography>
              </Box>
              <Box className={classes.disabled}>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/partnerships">
                  <GroupsOutlined sx={{fontSize: '56px'}}/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Partnerships</Typography>
              </Box>
              <Box className={classes.disabled}>
                <IconButton gutterBottom className={classes.buttonIcon} color="primary" component={Link} to="/assets/visitas">
                  <img src={location} alt="location"/>
                </IconButton>
                <Typography align='center' variant="button" display="block">Visitas</Typography>
              </Box>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Share variant="fixed"/>
    </Container>
  </div>
}

export default Assets;
