import { login, logout, setInfo, setError, setGettingUserInfo } from './userSlice';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';
import firebaseApp from '../firebase/firebaseApp';
import {getCompany} from "./CompanyActions";

const auth = getAuth(firebaseApp);
const functions = getFunctions(firebaseApp);

export const userLogin = (payload) => {
    return async (dispatch) => {
        signInWithEmailAndPassword(auth, payload.email, payload.password)
        .then(() => {
            dispatch(setError({error: false}));
            dispatch(userComeBack(auth.currentUser))
        })
        .catch(error => {
            dispatch(setError({error: true}));
            console.log(error.message);
        })
    }
}

export const userLogout = () => {
    return async (dispatch) => {
        signOut(auth);
        dispatch(logout());
    }
}

export const userComeBack = (payload) => {
    return async (dispatch) => {
        dispatch(setGettingUserInfo({gettingUserInfo: true}))
        dispatch(login({id: payload.uid, email: payload.email, emailVerified: payload.emailVerified}));
        const getUserCallable = httpsCallable(functions, 'getUserInfo');
        getUserCallable({data: null}).then(({data:userInfo}) => {
            if (userInfo.role)
            {
                const userInformation = {role: userInfo.role, profile: userInfo.profile, active: userInfo.active, name: userInfo.name, companyName: userInfo.company.name}
                dispatch(setInfo(userInformation));
            }
            else {
                throw new Error(`User ${payload.email} does not have role.`);
            }
        })
        .catch(error => {
            console.log(error);
        })
        .finally(() => {
            dispatch(setGettingUserInfo({gettingUserInfo: false}))
            dispatch(getCompany())
        })
    }
}
