import {Breadcrumbs, Container} from "@mui/material";
import ListIndustries from "../components/catalogs/ListIndustries";
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';

const Industries = () => {
    return <div style={{marginTop: '-24px'}}>
        <Breadcrumbs aria-label="breadcrumb" className='breadcrumb-custom'>
            <Typography>LATINOAMERICA </Typography>
            <Link to="/assets">assets</Link>
            <Link to="/assets/warehouse">warehouse</Link>
            <Link to="/assets/warehouse/catalog">Catálogos ALAXS</Link>
            <Typography>Industrias</Typography>
        </Breadcrumbs>
        <Container style={{marginTop: '24px'}}>
            <ListIndustries/>
        </Container>
    </div>
}

export default Industries;
