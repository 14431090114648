import {useState, useEffect} from "react";
import firebaseApp from '../../firebase/firebaseApp';
import IndustryElement from "./IndustryElement";
import {Card, CardContent, CardHeader, LinearProgress} from "@mui/material";
import { getFunctions, httpsCallable } from 'firebase/functions';

const functions = getFunctions(firebaseApp)

const ListIndustries = () => {
    const [industries, setIndustries] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchIndustries = () => {
            const listIndustries = httpsCallable(functions, 'listIndustries');
            listIndustries().then(result => {
                setIndustries(result.data);
                setIsLoading(false);
            });
        }
        fetchIndustries();
    }, []);

    return <Card>
        <CardHeader title="Industrias" />
        <CardContent>
            {isLoading && <LinearProgress/>}
            {industries.map(c => {
                return <IndustryElement key={c.id} industry={c} />
            })}
        </CardContent>
    </Card>
}

export default ListIndustries;