import React, {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {pathImageStorage, formatDateShort} from './utils';
import {useSelector} from 'react-redux';

const GridPosts = (props) => {
  const {id, image, tematica, title, fuente, fecha} = props.data;
  const [thematic, setThematic] = useState('');
  const listThematics = useSelector(state => state.post.listThematics);
  
  useEffect(() => {
    if(listThematics.length > 0 && tematica){
      const name = listThematics.filter(e => e.order === parseInt(tematica));
      setThematic(name[0].name);
    }
  },[listThematics, tematica])
  
  return <>
    <div className="featured-box">
      <Link className="post-filter-inner" to={'/post/' + id}>
          <span className="post-filter-link">
            <img alt={'post - imagen'} src={pathImageStorage + image}/>
          </span>
        <div className="entery-category-fly">
          <div className='posts-tag'>
            <span className="post-tag">{thematic}</span>
          </div>
          <h2 className="entry-title">{title}</h2>
          <div className="post-snip">
            <span className="post-author">{fuente}</span>
            <span className="post-date">{formatDateShort(fecha)}</span>
          </div>
        </div>
      </Link>
    </div>
  </>
}

export default GridPosts;
