import SubHeader from "../components/common/SubHeader";
import {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Typography, Grid
} from "@mui/material";
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    image: {
        maxWidth: "100%"
    },
    card: {
        marginBottom: theme.spacing(4)
    },
    iframe: {
        border: 0
    },
}));


const Suppliers = () => {
    const classes = useStyles();
    
    return <Fragment><SubHeader title="asia / suppliers"/>
        <Container fixed>
            <Card>
                <CardHeader title="Current Suppliers" />
                <Divider/>
                <CardContent sx={{padding: '40px'}}>
                    <Grid container spacing={2} sm={12}>
                       <Grid direction="row" sx={{justifyContent:"space-between", flexWrap:"wrap", display: 'flex'}}
                             spacing={2} item xs={6} md={10} lg={8}>
                           <Button component={Link} to={'/suppliers/pumps'} variant="outlined" color='success' sx={{width: '142px'}}>
                               <Typography sx={{fontSize: '20px'}}>PUMPS</Typography>
                           </Button>
                           <Button component={Link} to={'/suppliers/steel'} variant="outlined" color='success' sx={{width: '142px'}}>
                               <Typography sx={{fontSize: '20px'}}>STEEL</Typography>
                           </Button>
                           <Button component={Link} to={'/suppliers/hardware'} variant="outlined" color='success' sx={{width: '142px'}}>
                               <Typography sx={{fontSize: '20px'}}>HARDWARE</Typography>
                           </Button>
                       </Grid>
                        <Grid direction="row" sx={{justifyContent:"space-between", flexWrap:"wrap", display: 'flex'}}
                              spacing={2} item xs={6} md={10} lg={8}>
                            <Button component={Link} to={'/suppliers/plastics'} variant="outlined" color='success' sx={{width: '142px'}}>
                                <Typography sx={{fontSize: '20px'}}> PLASTICS</Typography>
                            </Button>
                            <Button component={Link} to={'/suppliers/valves'} variant="outlined" color='success' sx={{width: '142px'}}>
                                <Typography sx={{fontSize: '20px'}}> VALVES</Typography>
                            </Button>
                            <Button component={Link} to={'/suppliers/other'} variant="outlined" color='success' sx={{width: '142px'}}>
                                <Typography sx={{fontSize: '20px'}}> OTHER</Typography>
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                </CardContent>
            </Card><br/>
            <Card>
                <CardHeader title="Total Suppliers" />
                <Divider/>
                <CardContent>
                    <iframe title="Revenues by Industry" width="100%" height="900"
                        className={classes.iframe} src="https://datastudio.google.com/embed/reporting/2c4b81d5-dd31-4250-b9a6-7b63bfcbc61d/page/NvyiC" frameborder="0" allowfullscreen></iframe>

                    <iframe title="Providers by City" width="100%" height="500"
                        className={classes.iframe} src="https://datastudio.google.com/embed/reporting/f4416ef7-3bdf-4542-8522-6f79f7655f77/page/hfyiC" frameborder="0" allowfullscreen></iframe>

                    <iframe title="Importaciones x Partida" width="100%" height="900"
                        className={classes.iframe} src="https://datastudio.google.com/embed/reporting/54643d94-15a4-483f-b72e-180e83fb83b4/page/Wt2lC" frameborder="0" allowfullscreen></iframe>
                </CardContent>
            </Card>
        </Container>
    </Fragment>
}

export default Suppliers;
