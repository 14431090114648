import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import RowUniversity from "./RowUniversity";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(theme => ({
    table: {
        width: '100%',
    },
    tableHead: {
        backgroundColor: '#F8F9FD'
    }
}));

const ListUniversities = (props) => {
    const classes = useStyles();

    return <TableContainer component={Paper}>
        <Table className={classes.table}>
            <TableHead className={classes.tableHead}>
                <TableRow>
                    <TableCell>Ranking Global</TableCell>
                    <TableCell>Ranking Asia</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Ciudad</TableCell>
                    <TableCell>País</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.universities.map(university => {return <RowUniversity key={university.id} universityInfo={university}
                              checked={!!props.selectedUniversities[university.id] ? props.selectedUniversities[university.id] : false}
                />})}
            </TableBody>
        </Table>
    </TableContainer>
}

export default ListUniversities;