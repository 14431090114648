import ZendeskForm from "../components/zendesk/ZendeskForm";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import {Fragment} from "react";

const Zendesk = () => {
    return <Fragment>
        <SubHeader title="Helpdesk"/>
        <Container fixed>
            <ZendeskForm/>
        </Container>
    </Fragment>
}

export default Zendesk;
