import {Fragment} from "react";
import SubHeader from "../components/common/SubHeader";
import {Container} from "@mui/material";
import RevenueInfo from "../components/bi/RevenueInfo";

const BiRatio = () => {
    return <Fragment>
        <SubHeader parentUrl="/bi" parentTitle="Business Intelligence" title="Revenues"/>
        <Container fixed>
            <RevenueInfo/>
        </Container>
    </Fragment>
}

export default BiRatio;