import {
    Button,
    Card, CardActions,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    LinearProgress,
    MenuItem,
    Snackbar,
    TextField, Typography
} from "@mui/material";
import {useState} from "react";
import {makeStyles} from "@mui/styles";
import firebaseApp from '../../firebase/firebaseApp';
import {useSelector} from "react-redux";
import MuiAlert from '@mui/material/Alert';
import { getFunctions, httpsCallable } from 'firebase/functions';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: theme.spacing(3)
    },
    button: {
        marginBottom: theme.spacing(1)
    }
}));

const dataSelectTheme = {
    0: 'Soporte Legal/Contable',
    1: 'Logística/Transporte',
    2: 'Conocer Compradores/Proveedores',
    3: 'Conocer Compradores/Proveedores',
    4: 'Comprar/Vender',
    5: 'Network: oficinas y contactos',
    6: 'Ir a Evento(s)',
    7: 'Uso de la Plataforma'
}

const dataSelectSubject = {
    0: 'Consultas',
    1: 'Sugerencia',
    2: 'Queja',
    3: 'Pedido'
}

const functions = getFunctions(firebaseApp)

const ZendeskForm = () => {
    const [theme, setTheme] = useState(0);
    const [subject, setSubject] = useState(0);
    const [themeText, setThemeText] = useState('Soporte Legal/Contable');
    const [subjectText, setSubjectText] = useState('Consultas');
    const [description, setDescription] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [open, setOpen] = useState(false);

    const name = useSelector(state => state.user.name);
    const email = useSelector(state => state.user.email);
    const companyName = useSelector(state => state.user.companyName);

    const classes = useStyles();

    const handleThemeChange = e => {
        const value = e.target.value
        setTheme(value);
        setThemeText(dataSelectTheme[value]);
    }

    const handleSubjectChange = e => {
        const value = e.target.value
        setSubject(value);
        setSubjectText(dataSelectSubject[value]);
    }

    const handleDescription = e => {
        setDescription(e.target.value);
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const sendCommentHandler = e => {
        setIsSending(true);
        e.preventDefault();
        const sendMessage = httpsCallable(functions, 'sendMessage');
        sendMessage({
            to: ['support@alaxs.zendesk.com', 'rmx@alaxs.com'],
            cc: email,
            subject: 'Mensaje de Cliente desde MyALAXS',
            replyTo: email,
            html: `<p>Empresa: ${companyName}</p>
                <p>Usuario: ${name}</p>
                <p>Tema: ${themeText}</p>
                <p>Asunto: ${subjectText}</p>
                <p>Descripción:</p>
                <p>${description}</p>
                `
        }).then(() => {
            setIsSending(false);
            setOpen(true);
            setDescription('');
        });
    }
    
    return <div><form onSubmit={sendCommentHandler}>
        <Card>
        <CardHeader title="Déjanos tu mensaje" />
        <Divider/>
        <CardContent>
               <FormControl fullWidth className={classes.formControl}>
                   <TextField select label="Elige un tema" sx={{margin: '20px 0'}} value={theme} onChange={handleThemeChange} required>
                       <MenuItem value={0}>Soporte Legal/Contable</MenuItem>
                       <MenuItem value={1}>Logística/Transporte</MenuItem>
                       <MenuItem value={2}>Conocer Compradores/Proveedores</MenuItem>
                       <MenuItem value={3}>Evaluar Proveedor(es)</MenuItem>
                       <MenuItem value={4}>Comprar/Vender</MenuItem>
                       <MenuItem value={5}>Network: oficinas y contactos</MenuItem>
                       <MenuItem value={6}>Ir a Evento(s)</MenuItem>
                       <MenuItem value={7}>Uso de la Plataforma</MenuItem>
                   </TextField>
               </FormControl>
               <FormControl fullWidth className={classes.formControl}>
                   <TextField sx={{margin: '20px 0'}} select label="Elige un motivo" value={subject} onChange={handleSubjectChange} required>
                       <MenuItem value={0}>Consulta</MenuItem>
                       <MenuItem value={1}>Sugerencia</MenuItem>
                       <MenuItem value={2}>Queja</MenuItem>
                       <MenuItem value={3}>Pedido</MenuItem>
                   </TextField>
               </FormControl>
               <FormControl fullWidth className={classes.formControl}>
                   <TextField multiline variant="outlined" label="Comentario"
                              placeholder="Detalle su comentario" rows={7} required onChange={handleDescription}
                              value={description}
                   />
               </FormControl>

        </CardContent>
        <CardActions>
            <FormControl fullWidth>
                <Button type="submit" variant="contained" color="primary" className={classes.button}>Enviar</Button>
                {isSending && <LinearProgress/>}
                <Typography align="center">Powered by Zendesk®</Typography>
            </FormControl>
        </CardActions>
    </Card>
    </form><Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
            Mensaje Enviado Satisfactoriamente
        </Alert>
    </Snackbar></div>
}

export default ZendeskForm;
